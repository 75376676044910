<template lang='pug'>
	.test(:class='{ "use-margin": useMargin, "gutter": useMargin, "spun": doneIt }' @click='goTo')
		.overlay(:class='{ "spun": doneIt }')
		p(@click='openModal') Take a test
		img.icon(src='@/assets/icons/test.svg')
</template>

<script>
export default {
	name: 'Signs',
	props: {
		useMargin: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			doneIt: false
		};
	},
	methods: {
		openModal: function () {
			this.$modal.show('quiz-modal');
			window.FB.ui({
				display: 'popup',
				method: 'share',
				href: 'https://safe.is/quiz-result-one'
			});
		},
		goTo: function () {
			this.doneIt = true;
			setTimeout(() => {
				this.$router.push('/');
			}, 300);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.test {
	max-width: 370px;
	position: relative;
	transition: .3s;
	display: grid;
	grid-template-columns: 1.9fr .1fr;
	height: 48px;
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
	padding-left: 28px;
	padding-right: 12px;
	font-weight: bold;
	border-left: 4px solid #AF7595;
	.overlay {
		transition: .3s;
		transition-timing-function: ease-in;
		width: 4px;
		left: -4px;
		z-index: 200;
		position: absolute;
		height: 100%;
		background-color: rgba(175, 117, 149, .5);
		&.spun {
			width: calc(~'100% + 4px');
		}
	}
	&.use-margin {
		margin-top: 90px;
	}
	img {
		position: relative;
		top: 50%;
		transform: translate3d(-50px, -50%, 0px);
		max-height: 48px;
	}
	@media screen and (min-width: 1240px) {
		width: 244px;
	}
}
</style>
