<template lang='pug'>
	.footer
		.inner.constrainer
			.item.main
				img(src='@/assets/footer/logo.svg')
			.inform
				a.item(href='mailto:safe@safe.is') safe@safe.is
				.item.kt Safe ehf., kt. 6405181700
				router-link.item.prv(to='/privacy-policy') Privacy Policy
				//- router-link.item.prv(to='/terms-and-conditions') Terms and conditions
			.logos
				img(src='@/assets/footer/footer-logo-2.png')
</template>

<script>
export default {
	name: 'Footer',
	data: function () {
		return {
		};
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
	margin-top: 96px;
	height: 240px;
  background-color: #00476e;
  @media screen and (max-width: 460px) {
    height: 100%;
  }
	.inner {
		display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
		@media screen and (max-width: 1220px) {
			grid-template-columns: 1fr;
    }
    @media screen and (max-width: 460px) {
      top: 0;
      transform: translate3d(0px, 0px, 0px);
      padding: 40px 0;
    }
		position: relative;
		top: 50%;
		transform: translate3d(0px, -50%, 0px);
		img {
			float: left;
			height: 48px;
			@media screen and (max-width: 1220px) {
				margin-bottom: 48px;
				height: 24px;
			}
		}
		.item {
			text-align: center;
			line-height: 30px;
			color: white;
			font-family: Montserrat;
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 1.8px;
			color: #ffffff;
			&.kt {
				text-align: right;
      }
      &.main {
        align-items: center;
        display: flex;
      }
			@media screen and (max-width: 1220px) {
				text-align: left;
				font-size: 12px;
				letter-spacing: 0.9px;
				line-height: 16px;
				&.kt {
					margin-top: 16px;
					margin-bottom: 36px;
					text-align: left;
				}
			}
    }
    .inform {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    .logos {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: 1220px) {
        position: absolute;
        right: 0;
        translate: 0 50%;
      }
      @media screen and (max-width: 460px) {
        justify-content: flex-start;
        margin-top: 36px;
        position: relative;
        translate: 0 0;
        img {
          margin-bottom: 0;
        }
      }
      img {
        height: 120px;
      }
    }
	}
}
</style>
