<template lang='pug'>
	.road-conditions
		.left
			img(src='@/assets/jpg/drivinginiceland.jpg')
		.right
			h1.title Driving in Iceland.
			.dashes
				.dash(v-for='n in 5')
			.para.fs Get to know general information about driving in Iceland, such as the speed limit, signs, fines and information about highland driving.
			router-link.button.desktop(to='/driving-in-iceland')
				.text Learn more
				img.arrow(src='@/assets/icons/arrow-blue.svg')
			router-link.mobile-link.mobile(to='/driving-in-iceland')
				.text Learn more
				img.arrow(src='@/assets/icons/caret_blue.svg')
</template>

<script>
export default {
	name: 'DrivingInIceland',
	data: function () {
		return {
		};
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.road-conditions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media screen and (max-width: 1220px) {
		grid-template-columns: 1fr;
	}
	grid-gap: 42px;
	.left {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media screen and (max-width: 1220px) {
				max-height: 400px;
				object-position: bottom;
			}
		}
	}
	.right {
		padding-top: 43px;
		@media screen and (max-width: 768px) {
			margin-top: -18px;
			padding-top: 0px;
		}
		.title {
			text-align: left;
			max-width: 478px;
		}
		.dashes {
			margin-top: 38px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
		.para {
			margin-top: 38px;
			text-align: left;
			max-width: 488px;
			height: 177px;
			@media screen and (max-width: 1220px) {
				height: auto;
			}
		}
		.button {
			right: calc(~'100% + 42px');
			transform: translate3d(-50%, -47px, 0px);
			display: grid;
			grid-template-columns: 80% 20%;
			height: 48px;
			width: 260px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			border-left: 4px solid #AF7595;
			margin-top: 58px;
			transition: .3s;
			.text {
				color: black;
				text-align: left;
				height: 30px;
				width: 161px;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1.5px;
				line-height: 48px;
				padding-left: 32px;
			}
			.arrow {
				position: relative;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
				transition: .3s;
			}
			&:hover {
				transform: translate3d(-50%, -47px, 0px) scale(1.03);
				border-left: 16px solid #AF7595;
				width: 248px;
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
				.arrow {
					transform: translate3d(5px, -50%, 0px);
				}
			}
		}
	}
	.mobile-link {
		position: relative;
		margin-top: 48px;
		display: grid;
		grid-template-columns: auto auto;
		width: 160px;
		height: 18px;
		float: right;
		.arrow {
			position: relative;
			top: 2px;
			right: 0px;
			height: 14px;
			transform: rotate(-90deg);
		}
		.text {
			width: 96px;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.9px;
			color: #00476e;
		}
	}
}
</style>
