	<template lang='pug'>
	.listen.constrainer
		.left
			.audio-player
				.language-selection.gutter
					.lang-item(v-for='lang in audioLang' :class='{ active: lang.title === $store.state.audioLanguage }')
						img.clickable(:src='lang.img' @click='setLang(lang.title)')
						.underline
				.player.gutter
					.controls
						.item
							img.back.clickable(src='@/assets/player/back-track.svg' @click='playPrev')
						.item
							img.back-fifteen.clickable(src='@/assets/player/15-back.svg' @click='jumpBackwards')
						.item.play-pause
							img.play.clickable(src='@/assets/player/play-circle.svg' @click='play' :class='{ active: (!sound || !sound.playing()) }')
							img.play.clickable(src='@/assets/player/pause-circle.svg' @click='play' :class='{ active: (sound && sound.playing()) }')
						.item
							img.forward-fifteen.clickable(src='@/assets/player/15-forward.svg' @click='jumpForwards')
						.item
							img.forward.clickable(src='@/assets/player/skip.svg' @click='playNext')
				.divider
				.list
					.item.clickable(v-for='file, index in files[$store.state.audioLanguage]' @click='setPlay(index)')
						.content(:class='{ even: index % 2 === 0 }')
							.title.gutter(:class='{ bold: currentFile === index }') {{ file.title }}
							.duration.gutter {{ file.duration }}
						.overlay(:style='progress' v-if='currentFile === index')
		.right
			h1.title Listen here.
			.para.fs You can listen to the rules of safe driving in Iceland here.  You can find even more information and answers to your questions on the website.
			router-link.button.desktop(to='/listen')
				.text Read the transcript
				img.arrow(src='@/assets/icons/arrow-blue.svg')
			router-link.mobile-link.mobile(to='/listen')
				.text Read the transcript
				img.arrow(src='@/assets/icons/caret_blue.svg')
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'Listen',
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			audioLang: [
				{
					title: 'English',
					img: '/flags/english.svg'
				},
				{
					title: 'Italiano',
					img: '/flags/italian.svg'
				},
				{
					title: 'Deutsche',
					img: '/flags/german.svg'
				},
				{
					title: 'Nederlands',
					img: '/flags/dutch.svg'
				},
				{
					title: 'Española',
					img: '/flags/spanish.svg'
				},
				{
					title: 'Française',
					img: '/flags/french.svg'
				},
				{
					title: 'Polskie',
					img: '/flags/polish.svg'
				},
				{
					title: 'Norsk',
					img: '/flags/norwegian.svg'
				},
				{
					title: '中文',
					img: '/flags/chinese.svg'
				},
				{
					title: '日本人',
					img: '/flags/japanese.svg'
				}
			],
			blockClose: false,
			swap: false,
			showLess: false,
			sound: null,
			currentFile: 0,
			currentPosition: 0,
			files: {
				Española: [
					{
						duration: '06:22',
						title: 'General',
						url: '/audio/spanish/Spaenska-General.mp4'
					},
					{
						duration: '05:26',
						title: 'Highland Driving',
						url: '/audio/spanish/Spaenska-Highlands.mp4'
					},
					{
						duration: '01:31',
						title: 'Winter Driving',
						url: '/audio/spanish/Spaenska_vetur.mp4'
					}
				],
				Polskie: [
					{
						duration: '04:41',
						title: 'General',
						url: '/audio/polish/Polska-_General.mp4'
					},
					{
						duration: '03:58',
						title: 'Highland Driving',
						url: '/audio/polish/Polska-_Highland.mp4'
					},
					{
						duration: '01:02',
						title: 'Winter Driving',
						url: '/audio/polish/Polska-vetur_4.mp4'
					}
				],
				Norsk: [
					{
						duration: '05:37',
						title: 'General',
						url: '/audio/norwegian/Norska-General.mp4'
					},
					{
						duration: '04:55',
						title: 'Highland Driving',
						url: '/audio/norwegian/Norska-Highland.mp4'
					},
					{
						duration: '01:10',
						title: 'Winter Driving',
						url: '/audio/norwegian/Norska-vetur_6.mp4'
					}
				],
				日本人: [
					{
						duration: '05:48',
						title: 'General',
						url: '/audio/japanese/Japan-_general.mp4'
					},
					{
						duration: '05:32',
						title: 'Highland Driving',
						url: '/audio/japanese/Japan-_highlands.mp4'
					},
					{
						duration: '01:29',
						title: 'Winter Driving',
						url: '/audio/japanese/Japanska-vetur_3.mp4'
					}
				],
				Deutsche: [
					{
						duration: '05:10',
						title: 'General',
						url: '/audio/german/Thyska-_General.mp4'
					},
					{
						duration: '04:31',
						title: 'Highland Driving',
						url: '/audio/german/Thyska-highland.mp4'
					},
					{
						duration: '01:03',
						title: 'Winter Driving',
						url: '/audio/german/Thyska-vetur_1.mp4'
					}
				],
				Française: [
					{
						duration: '04:31',
						title: 'General',
						url: '/audio/french/franska-__General.mp4'
					},
					{
						duration: '03:30',
						title: 'Highland Driving',
						url: '/audio/french/franska-highland.mp4'
					},
					{
						duration: '00:51',
						title: 'Winter Driving',
						url: '/audio/french/Franska-vetur_2.mp4'
					}
				],
				Nederlands: [
					{
						duration: '05:03',
						title: 'General',
						url: '/audio/dutch/Hollenska_General.mp4'
					},
					{
						duration: '04:11',
						title: 'Highland Driving',
						url: '/audio/dutch/Hollenska-highland.mp4'
					},
					{
						duration: '01:04',
						title: 'Winter Driving',
						url: '/audio/dutch/Hollenska-vetur.mp4'
					}
				],
				中文: [
					{
						duration: '07:51',
						title: 'General',
						url: '/audio/chinese/Kinverska-General.mp4'
					},
					{
						duration: '06:04',
						title: 'Highland Driving',
						url: '/audio/chinese/Kinverska-Highland.mp4'
					},
					{
						duration: '01:34',
						title: 'Winter Driving',
						url: '/audio/chinese/Kinverska-vetur_5.mp4'
					}
				],
				English: [
					{
						duration: '01:52',
						title: 'Driving in Iceland - P1',
						url: '/audio/english/1 - Driving in Iceland - Part 1.mp4'
					},
					{
						duration: '01:38',
						title: 'Driving in Iceland - P2',
						url: '/audio/english/2 - Driving in Iceland - Part 2.mp4'
					},
					{
						duration: '00:50',
						title: 'Driving in Iceland - P3',
						url: '/audio/english/3 - Driving in Iceland - Part 3.mp4'
					},
					{
						duration: '03:42',
						title: 'Highland Driving',
						url: '/audio/english/4 - Highland driving in Iceland.mp4'
					},
					{
						duration: '01:29',
						title: 'Winter Driving',
						url: '/audio/english/Winter Driving in Iceland.mp3'
					}
				],
				Italiano: [
					{
						duration: '02:04',
						title: 'Driving in Iceland - P1',
						url: '/audio/italian/italska-01.mp4'
					},
					{
						duration: '01:46',
						title: 'Driving in Iceland - P2',
						url: '/audio/italian/italska-02.mp4'
					},
					{
						duration: '00:53',
						title: 'Driving in Iceland - P3',
						url: '/audio/italian/italska-03.mp4'
					},
					{
						duration: '03:43',
						title: 'Highland Driving',
						url: '/audio/italian/italska-Highland.mp4'
					},
					{
						duration: '01:04',
						title: 'Winter Driving',
						url: '/audio/italian/italska-Winter.mp4'
					}
				]
			}
		};
	},
	computed: {
		progress: function () {
			let position = 0;
			if (this.sound) {
				position = this.currentPosition;
			}
			return { width: `${position}%` };
		}
	},
	beforeDestroy: function () {
		this.clearSound();
	},
	methods: {
		setLang: function (lang) {
			this.$store.state.audioLanguage = lang;
			this.clearSound();
			setTimeout(() => {
				this.swap = false;
			}, 200);
		},
		closeSwap: function () {
			if (!this.blockClose) {
				this.swap = false;
			}
		},
		showLanguageSwap: function () {
			this.blockClose = true;
			this.swap = true;
			setTimeout(() => {
				this.blockClose = false;
			}, 300);
		},
		setPlay: function (index) {
			this.currentFile = index;
			if (this.sound) {
				this.clearSound();
			}
			this.play();
		},
		clearSound: function () {
			if (this.sound) {
				this.sound.stop();
				this.sound.unload();
				this.currentPosition = 0;
			}
			this.sound = null;
		},
		playNext: function () {
			if (this.currentFile + 1 > this.files[this.$store.state.audioLanguage].length - 1) {
				this.clearSound();
				this.currentFile = 0;
			} else {
				this.clearSound();
				this.currentFile++;
				this.play();
			}
		},
		playPrev: function () {
			if (this.currentFile - 1 < 0) {
				this.currentPosition = 0;
				this.sound.seek(0);
			} else {
				this.clearSound();
				this.currentFile--;
				this.play();
			}
		},
		jumpForwards: function () {
			if (this.sound) {
				if (this.sound.seek() + 15 > this.sound.duration()) {
					this.playNext();
				} else {
					this.sound.seek(this.sound.seek() + 15);
					this.currentPosition = this.sound.seek();
				}
			}
		},
		jumpBackwards: function () {
			if (this.sound) {
				if (this.sound.seek() < 15) {
					this.sound.seek(0);
				} else {
					this.sound.seek(this.sound.seek() - 15);
					this.currentPosition = this.sound.seek();
				}
			}
		},
		play: function () {
			// Documentation: https://github.com/goldfire/howler.js#examples
			if (!this.sound) {
				this.sound = new window.Howl({
					src: [this.files[this.$store.state.audioLanguage][this.currentFile].url]
				});
				this.sound.play();
			} else if (!this.sound.playing()) {
				this.sound.play();
			} else {
				this.sound.pause();
			}
			this.sound.on('end', () => {
				this.playNext();
			});
			setInterval(() => {
				if (this.sound) {
					this.currentPosition = 100 * (this.sound.seek() / this.sound.duration());
				}
			}, 1);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.listen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 158px;
	@media screen and (max-width: 1220px) {
		grid-template-columns: initial;
		grid-template-areas:
		'top'
		'bottom';
	}
	.left {
		@media screen and (max-width: 1220px) {
			grid-area: bottom;
			margin-top: 48px;
		}
		.audio-player {
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			padding-top: 2px;
			padding-bottom: 38px;
			width: 504px;
			position: relative;
			background-color: white;
			@media screen and (max-width: 1220px) {
				top: 0px;
				left: 0px;
			}
			@media screen and (max-width: 650px) {
				box-shadow: 0px 3px 5px 1px rgba(177, 131, 131, 0.00);
				width: calc(~'100% + 48px');
				left: -24px;
				margin-left: auto;
				margin-right: auto;
			}
			.list {
				width: 375px;
				margin-left: auto;
				margin-right: auto;
				@media screen and (max-width: 650px) {
					width: 100%;
				}
				.item {
					height: 48px;
					position: relative;
					width: 100%;
					border-left: 4px solid rgba(0,0,0,0);
					transition: .3s;
					.overlay {
						transition-timing-function: linear;
						position: absolute;
						top: 0px;
						left: 0px;
						height: 100%;
						width: 100%;
						background-color: rgba(140, 249, 227, .3);
					}
					.content {
						display: grid;
						grid-template-columns: 1fr 110px;
						&.even {
							background-color: #FAFAFA;
						}
						.title {
							line-height: 48px;
							text-align: left;
							font-size: 12px;
							letter-spacing: 1px;
						transition: .3s;
							&.bold {
								font-weight: 600;
							}
						}
						&.last {
							.title {
								color: #CCCCCC;
								font-size: 14px;
								font-weight: 600;
								letter-spacing: 1.17px;
								line-height: 48px;
								text-align: right;
							}
							width: 100%;
							background-color: #FAFAFA;
							display: block;
						}
						.duration {
							color: #CCCCCC;
							font-size: 12px;
							font-weight: 500;
							letter-spacing: 1px;
							line-height: 48px;
							text-align: right;
						}
					}
					&:hover {
						border-left: 4px solid #00476e;
						.content {
							.title {
								transform: translate3d(3px, 0px, 0px);
							}
						}
					}
				}
			}
			.divider {
				position: relative;
				width: 375px;
				height: 2px;
				background-color: #CBCBCB;
				margin-top: 37px;
				margin-left: auto;
				margin-right: auto;
				@media screen and (max-width: 650px) {
					width: 100%;
				}
			}
			.player {
				margin-top: 40px;
				position: relative;
				.controls {
					position: relative;
					left: 50%;
					transform: translate3d(-50%, 0px, 0px);
					width: 256px;
					display: grid;
					grid-template-columns: 26px 26px 72px 26px 26px;
					grid-column-gap: 20px;
					@media screen and (max-width: 650px) {
						width: 208px;
						grid-template-columns: 14px 14px 72px 14px 14px;
					}
					.item {
						position: relative;
						img {
							width: 100%;
							position: relative;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0px);
							transition: .3s;
							&:hover {
								transform: translate3d(-50%, -50%, 0px) scale(1.03);
								&.forward-fifteen {
									transform: translate3d(-50%, -50%, 0px) scale(1.03) rotate3d(0, 0, 1, 5deg);
								}
								&.back-fifteen {
									transform: translate3d(-50%, -50%, 0px) scale(1.03) rotate3d(0, 0, 1, -5deg);
								}
							}
						}
						&.play-pause {
							img {
								visibility: hidden;
								&.active {
									visibility: initial;
								}
								position: absolute;
							}
						}
					}
				}
			}
			.language-selection {
				margin-top: 30px;
				display: grid;
				grid-template-columns: repeat(10, auto);
				grid-column-gap: 18px;
				padding-bottom: 16px;
				width: 80px;
				position: relative;
				width: 375px;
				margin-left: auto;
				margin-right: auto;
				@media screen and (max-width: 650px) {
					width: 100%;
					box-sizing: border-box;
					padding-left: 16px;
					padding-right: 16px;
					grid-template-columns: repeat(5, auto);
					grid-row-gap: 18px;
				}
				.lang-item {
					.underline {
						height: 2px;
						width: 80%;
						margin-left: auto;
						margin-right: auto;
						background-color: rgba(0, 0, 0, 0);
						transition: .3s;
					}
					img {
						margin-bottom: 2px;
						height: auto;
						width: 100%;
						transition: .3s;
						max-width: 36px;
					}
					&.active {
						.underline {
							background-color: #95f9e3;
						}
						img {
							transform: scale(1.4);
						}
					}
				}
			}
		}
	}
	.right {
		@media screen and (max-width: 1220px) {
			margin-top: -100px;
			grid-area: top;
		}
		.title {
			max-width: 632px;
			height: 38px;
			color: #00476e !important;
		}
		.para {
			margin-top: 48px;
			max-width: 504px;
			height: 207px;
			text-align: left;
			@media screen and (max-width: 1220px) {
				height: auto;
			}
		}
		.button {
			display: grid;
			grid-template-columns: 80% 20%;
			height: 48px;
			width: 333px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			border-left: 4px solid #AF7595;
			transition: .3s;
			.text {
				text-align: left;
				color: black;
				height: 30px;
				width: 223px;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1.5px;
				line-height: 48px;
				padding-left: 32px;
			}
			.arrow {
				left: 100%;
				position: relative;
				top: 50%;
				transform: translate3d(calc(~'-100% - 20px'), -50%, 0px);
				transition: .3s;
			}
			&:hover {
				transform: scale(1.03);
				border-left: 16px solid #AF7595;
				width: 317px;
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
				.arrow {
					transform: translate3d(calc(~'-100% - 15px'), -50%, 0px);
				}
			}
		}
		.mobile-link {
			position: relative;
			margin-top: 48px;
			display: grid;
			grid-template-columns: auto auto;
			width: 198px;
			height: 18px;
			float: right;
			.arrow {
				position: relative;
				top: 2px;
				right: 0px;
				height: 14px;
				transform: rotate(-90deg);
			}
			.text {
				width: 186px;
				font-family: Montserrat;
				font-size: 14px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: 0.9px;
				color: #00476e;
			}
		}
	}
	.custom-constrainer {
		@media screen and (min-width: 1221px) {
			margin-left: auto;
			margin-right: auto;
			max-width: 1125px;
		}
		@media screen and (max-width: 1220px) {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
}
</style>
