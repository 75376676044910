<template>
  <a
    :class="className"
    href="https://www.bluecarrental.is/?affiliate=Safe.is"
  >
    <div class="blue-car-rental-banner__image-wrapper">
      <img
        v-if="image.url"
        class="blue-car-rental-banner__image"
        :src="image.url"
      >
    </div>
    <div class="blue-car-rental-banner__primary">
      <span class="blue-car-rental-banner__eyebrow">
        bluecarrental.is
      </span>
      <p class="blue-car-rental-banner__title">
        Renting a car in iceland
      </p>
      <div class="blue-car-rental-banner__dashes">
        <div class="blue-car-rental-banner__dash" />
        <div class="blue-car-rental-banner__dash" />
        <div class="blue-car-rental-banner__dash" />
        <div class="blue-car-rental-banner__dash" />
        <div class="blue-car-rental-banner__dash" />
      </div>
      <p
        v-show="text"
        class="blue-car-rental-banner__text"
      >
        {{ text }}
      </p>
      <button class="blue-car-rental-banner__cta">
        Check availability
      </button>
    </div>
    <div class="blue-car-rental-banner__badge">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable -->
        <path
					d="M12.7288 1.19989L16.0748 7.82889L22.5148 8.46689C22.6681 8.47963 22.8146 8.53604 22.9368 8.62943C23.0591 8.72282 23.152 8.84928 23.2046 8.99385C23.2572 9.13842 23.2673 9.29503 23.2337 9.44515C23.2 9.59526 23.1241 9.7326 23.0148 9.84089L17.7148 15.0939L19.6798 22.2319C19.72 22.3833 19.7156 22.5431 19.6672 22.6921C19.6187 22.841 19.5282 22.9728 19.4066 23.0716C19.2851 23.1704 19.1375 23.2319 18.9818 23.2488C18.8261 23.2657 18.6688 23.2373 18.5288 23.1669L11.9998 19.9339L5.4798 23.1629C5.33985 23.2333 5.18255 23.2617 5.02681 23.2448C4.87108 23.2279 4.72354 23.1664 4.60196 23.0676C4.48037 22.9688 4.38991 22.837 4.34145 22.6881C4.29299 22.5391 4.2886 22.3793 4.3288 22.2279L6.2938 15.0899L0.9898 9.83689C0.880532 9.7286 0.804582 9.59126 0.770944 9.44115C0.737306 9.29103 0.747388 9.13442 0.799996 8.98985C0.852603 8.84528 0.945534 8.71882 1.06779 8.62543C1.19004 8.53204 1.33649 8.47564 1.4898 8.46289L7.9298 7.82489L11.2708 1.19989C11.3395 1.06569 11.4439 0.953066 11.5726 0.874417C11.7012 0.795767 11.849 0.75415 11.9998 0.75415C12.1506 0.75415 12.2984 0.795767 12.427 0.874417C12.5557 0.953066 12.6601 1.06569 12.7288 1.19989V1.19989Z"
          stroke="#00476E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
				/>
      </svg>
      <span>We recommend</span>
    </div>
  </a>
</template>

<script>
export default {
	name: 'BlueCarRentalBanner',
	props: {
		image: {
			type: Object,
			default () {
				return {};
			}
		},
		text: {
			type: String,
			default: ''
		},
		textAlign: {
			type: String,
			default: 'right'
		}
	},
	computed: {
		className () {
			const defaultClassName = 'blue-car-rental-banner';
			return [
				defaultClassName,
				this.text ? `${defaultClassName}--is-text` : `${defaultClassName}--no-text`,
				`blue-car-rental-banner--text-align-${this.textAlign}`
			];
		}
	}
};
</script>

<style lang="less">
.blue-car-rental-banner {
  display: grid;
  position: relative;
  font-family: 'Hind Vadodara', sans-serif;
  background-color: #2857A6;
  color: white;
  text-align: left;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0px 0px 50px rgba(42, 71, 130, 0.5);
    .blue-car-rental-banner__cta {
      background-color: #2A4782;
      color: white;
    }
  }
  &--is-text {
    .blue-car-rental-banner__cta {
      @media screen and (min-width: 992px) {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &--no-text {
    .blue-car-rental-banner__primary {
      padding-bottom: 45px;
    }
    .blue-car-rental-banner__dashes {
      display: none;
    }
  }
  &--text-align-right {
    @media screen and (min-width: 992px) {
      grid-template-columns: 12fr 13fr;
    }
    .blue-car-rental-banner__badge {
      position: absolute;
      top: 30px;
      @media screen and (min-width: 1282px) {
        left: -70px;
      }
      @media screen and (min-width: 992px) and (max-width: 1281px) {
        left: 0;
      }
      @media screen and (max-width: 991px) {
        left: 0;
      }
    }
  }
  &--text-align-left {
    @media screen and (min-width: 992px) {
      grid-template-columns: 13fr 12fr;
    }
    .blue-car-rental-banner__primary {
      @media screen and (min-width: 992px) {
        order: -1;
      }
    }
    .blue-car-rental-banner__badge {
      position: absolute;
      top: 30px;
      @media screen and (min-width: 1282px) {
        right: -70px;
      }
      @media screen and (min-width: 992px) and (max-width: 1281px) {
        right: 0;
      }
      @media screen and (max-width: 991px) {
        left: 0;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (min-width: 992px) {
      position: absolute;
      top: 0;
      left: 0;
    }
    &-wrapper {
      @media screen and (min-width: 992px) {
        position: relative;
      }
    }
  }
  &__primary {
    display: grid;
    gap: 40px;
    @media screen and (min-width: 992px) {
      padding-top: 42px;
      padding-right: 98px;
      padding-bottom: 135px;
      padding-left: 42px;
    }
    @media screen and (max-width: 991px) {
      padding: 40px 24px;
    }
    @media screen and (min-width: 576px) {
      justify-items: start;
    }
  }
  &__eyebrow {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
  }
  &__title {
    font-weight: 700;
    font-size: 50px;
    line-height: 100%;
    text-transform: uppercase;
    margin: 0;
  }
  &__dashes {
    display: flex;
  }
  &__dash {
    display: inline-block;
    width: 8px;
    height: 2px;
    background: #F5DB64;
    & + & {
      margin-left: 4px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 170%;
    margin: 0;
  }
  &__cta {
    padding: 13px 28px;
    font-family: inherit;
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2857A6;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border: none;
    transition:
      background-color 0.3s,
      color 0.3s,
    ;
    cursor: pointer;
  }
  &__badge {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 32px;
    padding-top: 13px;
    padding-right: 28px;
    padding-bottom: 13px;
    padding-left: 25px;
    background: white;
    border-left: 4px solid #AF7595;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 1.75px;
    color: #000000;
  }
}
</style>
