<template lang="pug">
a.banner-wrapper(
	:class="bannerClassModifier"
	:href="bannerProps.externalUrl"
	target="_blank"
)
	img.image(
		:src="bannerProps.imageSrc"
	)
	.main-text {{ bannerProps.mainText }}
	//- router-link.button(:to="bannerProps.pageUrl")
	//- 	.text {{ bannerProps.buttonText }}
	//- 	img.arrow(src='@/assets/icons/arrow-blue.svg')
	.button
		.text {{ bannerProps.buttonText }}
		img.arrow(src='@/assets/icons/arrow-blue.svg')
</template>

<script>
export default {
	name: 'BookBanner',

	props: {
		bannerNumber: {
			default: 1,
			type: Number
		}
	},

	data: function () {
		return {
			bannerProps: {
				imageSrc: '',
				mainText: '',
				pageUrl: '',
				buttonText: '',
				externalUrl: ''
			}
		};
	},

	computed: {
		bannerClassModifier: function () {
			const defaultClass = 'banner-number';
			return `${defaultClass}--${this.bannerNumber}`;
		}
	},

	watch: {
		banner:	{
			immediate: true,

			handler () {
				let iframeId = 9;
				const scroll = true;
				switch (this.bannerNumber) {
				case 1:
					this.bannerProps.imageSrc = require('@/assets/banners/1.png');
					this.bannerProps.mainText = 'The safest way to travel is with professionals';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book amazing tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 2:
					this.bannerProps.imageSrc = require('@/assets/banners/2.png');
					this.bannerProps.mainText = 'You can’t go everywhere in your car';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book amazing tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 3:
					this.bannerProps.imageSrc = require('@/assets/banners/3.png');
					this.bannerProps.mainText = 'Experience Iceland';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book amazing tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 4:
					this.bannerProps.imageSrc = require('@/assets/banners/4.png');
					this.bannerProps.mainText = 'Let the locals share their secrets';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 5:
					iframeId = 14;
					this.bannerProps.imageSrc = require('@/assets/banners/5.png');
					this.bannerProps.mainText = 'Get the most out of your trip';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book whale watching tours now';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour?tag=34&tags=Whale%20Watching&attraction=&attractions=&sort=0&pt=undefined&dt=undefined&vendor=&vendors=&onlyFavorite=false&travelmethod=&travelmethods=&privateFilter=false&searchParameter=&region=0&durationType=undefined';
					break;
				case 6:
					this.bannerProps.imageSrc = require('@/assets/banners/6.png');
					this.bannerProps.mainText = 'Join the search for the northern lights';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book northern lights tour now!';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 7:
					this.bannerProps.imageSrc = require('@/assets/banners/7.png');
					this.bannerProps.mainText = 'Don’t miss out on everything Iceland has to offer';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book unique tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 8:
					iframeId = 11;
					this.bannerProps.imageSrc = require('@/assets/banners/8.png');
					this.bannerProps.mainText = 'See Iceland from a different perspective';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book helicopter tours now';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 9:
					iframeId = 10;
					this.bannerProps.imageSrc = require('@/assets/banners/9.png');
					this.bannerProps.mainText = 'Please don’t bring your car here!';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book your diving tour';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour?tag=30&tags=Snorkeling%20&%20Diving&attraction=&attractions=&sort=0&pt=undefined&dt=undefined&vendor=&vendors=&onlyFavorite=false&travelmethod=&travelmethods=&privateFilter=false&searchParameter=&region=0&durationType=undefined';
					break;
				case 10:
					iframeId = 13;
					this.bannerProps.imageSrc = require('@/assets/banners/10.png');
					this.bannerProps.mainText = 'Ditch the car!';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book riding tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour';
					break;
				case 11:
					iframeId = 13;
					this.bannerProps.imageSrc = require('@/assets/banners/11.png');
					this.bannerProps.mainText = 'Ditch the car!';
					this.bannerProps.pageUrl = { name: 'ToursAndActive', params: { scroll, iframeId } };
					this.bannerProps.buttonText = 'Book riding tours here';
					this.bannerProps.externalUrl = 'https://safe.tourdesk.is/Tour?tag=17&tags=Horseback%20Riding&attraction=&attractions=&sort=0&pt=undefined&dt=undefined&vendor=&vendors=&onlyFavorite=false&travelmethod=&travelmethods=&privateFilter=false&searchParameter=&region=0&durationType=undefined';
				}
			}
		}
	}
};
</script>

<style scoped lang="less">
	.banner-wrapper {
		display: flex;
    position: relative;
		width: fit-content;
		margin: 96px auto;
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:hover {

			.button {
				border-left: 15px solid #AF7595;
				transform: translate(10px, 0);
			}
			transform: scale(0.97);
		}

		.image {
			width: 100%;
		}

		.main-text {
			position: absolute;
			font-family: 'Montserrat', Helvetica, Arial, sans-serif;
			font-size: 48px;
			font-weight: 900;
			color: #fff;
			left: 35px;
			top: 30px;
			width: 65%;
			line-height: 1.1;
			text-align: initial;

			@media screen and (max-width: 1220px) {
				font-size: 32px;
			}
			@media screen and (max-width: 834px) {
				width: 90%;
			}
		}

		.button {
			position: absolute;
			bottom: 40px;
			right: 40px;
			display: grid;
			grid-template-columns: auto auto;
			height: 48px;
			width: max-content;
			background-color: #fff;
			box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.07);
			border-left: 4px solid #AF7595;
			margin-top: 58px;
			transition: 0.3s;
			cursor: pointer;

			.text {
				color: #000;
				text-align: left;
				height: 30px;
				width: max-content;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1.5px;
				line-height: 48px;
				padding-left: 32px;
			}

			.arrow {
				position: relative;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
				transition: 0.3s;
				margin-left: 38px;
				margin-right: 5px;
			}
		}

		&.banner-number--4 {
			.main-text {
				width: 60%;
			}
		}

		&.banner-number--5 {
			.main-text {
				width: 49%;
				// @media screen and (max-width: 834px) {
				// 	width: 100%;
				// }
			}
		}

		&.banner-number--6,
		&.banner-number--9 {
			.main-text {
				width: 57%;
				@media screen and (max-width: 834px) {
					width: 100%;
				}
			}
		}

		&.banner-number--7 {
			.main-text {
				width: 75%;
				@media screen and (max-width: 834px) {
					width: 100%;
				}
			}
		}

		&.banner-number--9,
		&.banner-number--10 {
			.main-text {
				top: 50%;
				transform: translate(0, -50%);
				@media screen and (max-width: 834px) {
					top: 20%;
					transform: translate(0, -20%);
				}
			}
		}

		&.banner-number--8 {
			.main-text {
				color: #1c1c65;
				width: 80%;
				top: 70%;
				transform: translate(0, -70%);
				@media screen and (max-width: 834px) {
					width: 100%;
					top: 40%;
					transform: translate(0, -40%);
				}
			}
		}

		&.banner-number--10 {
			.main-text {
				width: 20%;
			}
		}
		@media (min-width: 320px) and (max-width: 1220px) {
			flex-direction: column;

			.main-text {
				font-size: 16px;
				top: 10px;
				left: 10px;
			}

			.button {
				bottom: 0;
				left: 0;
				margin-top: 0;
				position: relative;
				border-left: 5px solid #AF7595;
				display: flex;
				width: auto;

				.text {
					font-size: 13px;
					padding-left: 0;
					width: 100%;
					height: 100%;
					line-height: normal;
					display: flex;
					align-items: center;
					margin-left: 2px;
				}

				.arrow {
					margin-left: 3px;
					margin-right: 2px;
				}
			}
		}
		@media (min-width: 414px) and (max-width: 1220px) {
			.main-text {
				font-size: 20px;
			}
			.button {
				.text {
					font-size: 16px;
				}
			}
		}
		@media (min-width: 768px) and (max-width: 1220px) {
			.main-text {
				font-size: 34px;
			}
		}
	}
</style>
