<template lang='pug'>
	.listen-transcript
		.listen.constrainer
			.left
				.audio-player
					.language-selection.gutter
						.lang-item(v-for='lang in audioLang' :class='{ active: lang.title === $store.state.audioLanguage }')
							img.clickable(:src='lang.img' @click='setLang(lang.title)')
							.underline
					.player.gutter
						.controls
							.item
								img.back.clickable(src='@/assets/player/back-track.svg' @click='playPrev')
							.item
								img.back-fifteen.clickable(src='@/assets/player/15-back.svg' @click='jumpBackwards')
							.item.play-pause
								img.play.clickable(src='@/assets/player/play-circle.svg' @click='play' :class='{ active: (!sound || !sound.playing()) }')
								img.play.clickable(src='@/assets/player/pause-circle.svg' @click='play' :class='{ active: (sound && sound.playing()) }')
							.item
								img.forward-fifteen.clickable(src='@/assets/player/15-forward.svg' @click='jumpForwards')
							.item
								img.forward.clickable(src='@/assets/player/skip.svg' @click='playNext')
					.divider
					.list
						.item.clickable(v-for='file, index in files[$store.state.audioLanguage]' @click='setPlay(index)')
							.content(:class='{ even: index % 2 === 0 }')
								.title.gutter(:class='{ bold: currentFile === index }') {{ file.title }}
								.duration.gutter {{ file.duration }}
							.overlay(:style='progress' v-if='currentFile === index')
			.right
				h1.title Listen here.
				.para.fs You can listen to the rules of safe driving in Iceland here.  You can find even more information and answers to your questions on the website.

		//- English
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "English"')
			.transcript-title Read the transcript.
			p.para.fs.bold Here are important messages regarding your safety on Icelandic roads. Conditions in Iceland are unusual and unlike what foreign drivers are accustomed to. Please stay safe and take the time to familiarize yourself with special Icelandic conditions by listening to this message, specially made for rental cars.  We recommend that all passengers traveling together listen to this message.
			p.para.fs When planning your trip please look up weather and road conditions on <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. If a road is marked closed, then you cannot pass it under any circumstances. Don’t risk your life and safety by driving on a closed road.
			p.para.fs Drivers and all passengers must wear seatbelts – it is required by law. It’s the best safety equipment that you have to protect yourself in a car crash. Children are also required to wear seatbelts or be placed in car seats, depending on their height and weight.
			p.para.fs It is strictly forbidden to stop in the middle of the road or at the roadside you should always find a safe place or outlet to stop at.
			p.para.fs All use of mobile phones is strictly prohibited while driving. In order to reach your destination safely, you must keep full attention on driving.
			p.para.fs Pay close attention to speed limits. In populated areas the speed limit is usually 50km/h, in residential areas it‘s 30km/h and on throughways in Reykjavik it varies between 60-80km/h. In rural areas and on the highway the speed limit is 90km/h, but on gravel roads it‘s 80km/h. These are the maximum speed limits for good driving conditions, however speed should be reduced if driving conditions are poor.
			p.para.fs According to Icelandic law both head- and taillights are required to be ON at all times, night and day, all year round.
			p.para.fs When approaching a gravel roads it’s important to reduce speed so you won’t lose control of the vehicle. Show caution when approaching a car from the opposite direction by staying as far to the right as possible. There are special traffic sign in Iceland that indicate that you are approaching a gravel road and must slow down.
			p.para.fs During summer in Iceland there is daylight both day and night. This can be very dangerous and risks drivers falling asleep while driving. Therefore it’s important to take frequent breaks from driving and take a rest if you become tired.
			p.para.fs When approaching a single lane bridge - reduce speed. The first car that approaches has the right to cross. However, it’s wise to stop and assess the situation every time. In Iceland we have a special traffic sign that indicates that a single lane bridge is ahead.
			p.para.fs Blind crest, hills and curves, where lanes are not separated, should be approached with caution. Please keep to the far right side of the road and reduce speed. A white arrow on a blue sign refers to the lane you should drive on. In Iceland we have a special traffic sign that indicates that you are approaching a blind crest and should keep to the far right side of the road.
			p.para.fs It’s common to see livestock at roadsides when driving on the highway. In such circumstances speed should be reduced as the livestock tends to act unexpectedly and might run on to the road.
			p.para.fs In roundabouts, right-of-way goes to the driver on the inside lane. Always use the turn signal when exiting.  Be careful not to switch lanes inside the roundabout or when exiting.
			p.para.fs It’s illegal to drive off-road in Iceland and will lead to fines. You should only drive on roads and marked trails. Whilst traveling around the country you must show great respect for the environment.
			p.para.fs It’s against the law to operate a vehicle in Iceland after having consumed alcohol or other intoxicants and the penalties for violating these laws are severe.
			p.para.fs.bold You will find the following useful links below the sound player in this message.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (for weather and road conditions)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (for travel plans and other useful information)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (For special traffic signs and videos)
			p.para.fs.bold Thank you for listening - we wish you a safe and pleasant trip. If you are driving a 4x4 vehicle and plan to travel to the Icelandic highlands, please listen to the important highland travelers message following:
			p.para.fs Whilst traveling around the country you must show great respect for the environment. Icelandic nature is extremely fragile as the seasons change quite dramatically and changes in temperatures are common! It’s good to remember to take nothing except photographs and leave nothing behind except footprints.
			p.para.fs It is illegal to drive off-road in Iceland. Only stay on roads and marked trails. High fines or imprisonment can be expected for such violations!
			p.para.fs Preparation – check out the road map and see where the roads and trails are. (www.road.is for road conditions)
			p.para.fs Get information about the appropriate routes at visitor centers or from rangers or staff in the area.
			p.para.fs By uprooting or driving on moss, you can cause serious damage that can take nature decades to recover from!
			p.para.fs If you come to a point where you can’t go any further by driving – turn around or continue a short way on foot while leaving a note in your vehicle where you state the time of your intended return.
			p.para.fs Highland driving is very popular during the summertime. However it requires a whole different set of skills than normal driving. Conditions can change even more rapidly than on lowlands and roads often require you to cross unbridged rivers. Roads can be rough and you may not reach speeds much over 40 km/h so make sure you plan for enough time. Because of this it’s extremely important to gather information about the area you are travelling over and leave a detailed Travel Plan at <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>.
			.points
				.point(v-for='point in points.English')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Thank you for listening
			p.bold.heading - we wish you a safe and pleasant trip.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "English"')
			h1.title Icy roads - winter.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Icy road has caused a great number of accidents. Roads in Iceland are often very icy in wintertime, even if you don´t see it. Please lower speed and drive accordingly.
			p.para.fs Conditions during the winter time are entirely weather dependent. Along with the Road Conditions make sure to check the Weather Forecast .  Look out for winds that exceed 15 m/s as mountainous areas could produce very strong wind gusts! The forecast must be checked every day as even though the forecast reaches up to 5 days in advance, rapid changes are very common!
			p.para.fs Even though the speed limit says 90 km/h – it doesn’t mean that it’s appropriate during winter conditions. Make sure you reduce your speed accordingly.
			p.para.fs Not all parts of Iceland are accessible during the winter time. Most of the highlands are only accessible during the summer so keep in mind that you can not  pass under any circumstances  if the road is closed.

		//- Italian
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Italiano"')
			.transcript-title Read the transcript.
			p.para.fs.bold Qui sono riportati alcuni messaggi importanti riguardanti la tua sicurezza sulle strade islandesi. Le condizioni in Islanda sono particolari e diverse da quelle a cui sono abituati i conducenti degli altri paesi. Assicura la tua sicurezza e dedica alcuni minuti per conoscere le particolari condizioni presenti in Islanda, ascoltando questo messaggio sviluppato appositamente per che noleggia le autovetture.  Raccomandiamo l’ascolto di questo messaggio anche a tutti i passeggeri che viaggiano nello stesso veicolo.
			p.para.fs Quando programmi il tuo viaggio, dai un’occhiata alle condizioni meteorologiche e stradali sul sito <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Se una strada è indicata come chiusa, significa che è assolutamente vietato l’accesso. Non rischiare mai la tua vita e la tua sicurezza guidando attraverso una strada chiusa.
			p.para.fs La legge impone che sia il guidatore che tutti i passeggeri debbano allacciare le cinture di sicurezza. In caso d’incidente automobilistico, le cinture rappresentano la migliore attrezzatura di sicurezza possibile. Ricorda che anche i bambini devono allacciare le cinture di sicurezza e, a seconda della loro età e peso, devono sedere negli appositi seggiolini di sicurezza.
			p.para.fs È tassativamente vietato fermarsi in mezzo alla strada o lateralmente. Devi trovare sempre uno slargo sicuro dove fermarti.
			p.para.fs Durante la guida è tassativamente vietato l’utilizzo dei telefoni cellulari. Per raggiungere la tua meta in tutta sicurezza devi prestare sempre la massima attenzione alla guida.
			p.para.fs Rispetta sempre i limiti di velocità. Nei centri abitati il limite di velocità è normalmente 50 km/h, nelle aree residenziali 30 km/h e lungo le arterie di passaggio di Reykjavik varia da 60 a 80 km/h. Lungo le strade provinciali e in autostrada il limite di velocità è 90 km/h, ma sulle strade sterrate il limite è 80 km/h. Questi sono i limiti di velocità massimi consentiti in presenza di condizioni stradali ottimali, e devono essere ridotti in caso di peggioramento delle condizioni.
			p.para.fs La legge islandese impone l’accensione dei fari anabbaglianti anteriori e posteriori sia di giorno che di notte.
			p.para.fs Quando ti avvicini alle strade sterrate, ti raccomandiamo di ridurre la velocità per evitare di perdere il controllo del veicolo. Fai attenzione e mantieni sempre la destra soprattutto quando incroci le autovetture che procedono in senso opposto alla tua. <span class='italic'>In Islanda esiste una segnaletica stradale che indica quando ti avvicini a una strada sterrata e ti invita a ridurre la velocità.</span>
			p.para.fs Durante la stagione estiva in Islanda c’è sempre la luce del giorno 24 ore su 24. Questa situazione potrebbe essere molto pericolosa perché i conducenti potrebbero addormentarsi durante la guida. È quindi importante fare soste frequenti durante la guida in modo da riposare se ci si sente stanchi. Quando ti avvicini ad un ponte a corsia singola,  riduci la velocità. La prima vettura che arriva al margine del ponte ha la precedenza. Tuttavia, ti raccomandiamo di fermarti sempre e valutare la situazione di volta in volta. <span class='italic'>In Islanda esiste una segnaletica stradale che indica la presenza di un ponte a corsia singola.</span>
			p.para.fs Avvicinati alle cunette, alle salite e alle curve cieche, dove le corsie non sono separate, facendo la massima attenzione. Mantieni sempre la destra della corsia e riduci la velocità. Una freccia bianca su uno sfondo blu evidenzia la tua corsia di marcia. <span class='italic'>In Islanda esiste una segnaletica stradale che indica che ti stai avvicinando a una cunetta cieca e che devi mantenere sempre la destra della corsia di marcia.</span>
			p.para.fs Quando sei in autostrada è normale incontrare del bestiame lungo i suoi margini. In questi casi ti invitiamo a ridurre la velocità perché il bestiame tende ad avere reazioni inaspettate e potrebbe attraversare la strada.
			p.para.fs Nelle rotatorie la precedenza è della vettura che si trova nella corsia interna. Quando esci dalla rotatoria metti sempre la freccia.  Fai attenzione a non cambiare corsia quando sei in una rotatoria e quando esci da questa.
			p.para.fs In Islanda è vietato guidare off-road e la violazione è sanzionata con multe. Devi guidare sempre lungi le strade e i percorsi segnati. Durante la tua visita in Islanda ti invitiamo cortesemente a rispettare sempre l’ambiente.
			p.para.fs In Islanda è vietato guidare un veicolo dopo aver assunto bevande alcoliche o altre sostanze intossicanti: <span class='italic'>le sanzioni previste per la violazione delle legge sono estremamente severe.</span>
			p.para.fs.bold Sotto al lettore per questo messaggio troverai i seguenti link utili.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (per le condizioni meteorologiche e stradali)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (per i piani di viaggio e altre informazioni utili)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (per la segnaletica stradale e i video speciali)
			p.para.fs.bold Grazie per aver ascoltato questi messaggi e ti auguriamo un viaggio piacevole e sicuro. Se sei alla guida di un veicolo 4 x 4 e decidi di visitare gli altopiani dell’Islanda, ascolta attentamente il seguente messaggio:
			p.para.fs Durante la tua visita in Islanda ti invitiamo cortesemente a rispettare sempre l’ambiente. La natura islandese è estremamente fragile, le stagioni la cambiano in modo radicale e gli sbalzi di temperatura sono normali! Ricorda di non portar via mai nulla. Scatta le tue fotografie e lascia solo le tue impronte sul suolo.
			p.para.fs.bold È vietato guidare off-road in Islanda. Resta sempre sulle strade e i percorsi segnati. Le violazioni vengono sanzionate con multe elevate o addirittura la detenzione.
			p.para.fs Preparativi - consulta la cartina stradale per vedere dove si trovano le strade e i percorsi (www.road.is per le condizioni stradali)
			p.para.fs Richiedi tutte le informazioni necessarie sugli itinerari, consultando i servizi turistici i ranger o gli addetti locali.
			p.para.fs Tagliando attraverso o guidando sui muschi potresti causare danni esternamente gravi che la natura potrebbe riparare solo dopo decenni!
			p.para.fs Se arrivi ad un punto dove finisce la strada, torna indietro o continua a piedi lasciando un messaggio sull’autovettura in cui indichi dove ti trovi e quando pensi di tornare indietro.
			p.para.fs Guidare lungo l’altopiano è molto popolare durante la stagione estiva. Tuttavia, richiede capacità diverse rispetto alla guida convenzionale. Le condizioni possono cambiare rapidamente rispetto al resto del paese e le strade spesso richiedono il guado di fiumi senza ponti. Le strade possono essere accidentate e quindi il limite massimo di velocità non supera i 40 km/h. Assicurati di avere tempo a sufficienza per la tua gita. È quindi molto importante raccogliere tutte le informazioni necessarie sull’area che intendi visitare e lasciare un piano di viaggio dettagliato su safetravel.is.
			.points
				.point(v-for='point in points.Italian')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Grazie per aver ascoltato questi messaggi e ti auguriamo un viaggio piacevole e sicuro.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Italiano"')
			h1.title Strade ghiacciate - inverno.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Le strade ghiacciate causano numerosi incidenti. Durante la stagione invernale le strade in Islanda sono spesso gelate anche se non lo sembrano all’apparenza. Riduci la velocità e guida di conseguenza.
			p.para.fs Le condizioni durante la stagione invernale dipendono completamente dal tempo. Oltre alle condizioni stradali, verifica anche le previsioni meteorologiche.  Fai attenzione ai venti con velocità superiori ai 15 m/s perché nelle aree montuose possono causare correnti particolarmente forti. Controlla le condizioni meteorologiche ogni giorno anche se le previsioni coprono 5 giorni, perché possono cambiare rapidamente!
			p.para.fs Anche se il limite di velocità permette di guidare a 90 km/h, non significa che questo sia possibile d’inverno. Ricorda di ridurre la tua velocità in base alle condizioni.
			p.para.fs Durante la stagione invernale non tutte le zone dell’Islanda sono raggiungibili. Gran parte degli altopiani sono accessibili solo durante la stagione estiva, quindi ricorda di non tentare mai di entrare se la strada è chiusa.

		//- German
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Deutsche"')
			.transcript-title Read the transcript.
			p.para.fs.bold Hier erhalten Sie wichtige Nachrichten zu Ihrer Sicherheit auf isländischen Straßen. Die Bedingungen in Island sind speziell und unterscheiden sich von dem, was ausländische Fahrer gewohnt sind. Bitte nehmen Sie sich die Zeit für Ihre Sicherheit, um die besonderen isländischen Bedingungen kennenzulernen, indem Sie sich diese, speziell für Mietwagen angefertigte, Nachricht anhören. Wir empfehlen allen mitreisenden Passagieren, diese Nachricht anzuhören.
			p.para.fs Informieren Sie sich bei der Reiseplanung über die Wetter- und Straßenbedingungen unter <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Eine gesperrte Straße kann niemals befahren werden. Riskieren Sie nicht Ihr Leben und Ihre Sicherheit, indem Sie auf einer gesperrten Straße fahren.
			p.para.fs Der Fahrer und alle Passagiere müssen Sicherheitsgurte tragen - das ist gesetzlich vorgeschrieben. Dies ist die wichtigste Sicherheitsausrüstung für den Unfallschutz. Auch Kinder sind verpflichtet, Sicherheitsgurte zu tragen oder - abhängig von Größe und Gewicht - in Autositzen platziert zu werden.
			p.para.fs Es ist strengstens verboten, mitten auf der Straße oder am Straßenrand anzuhalten. Suchen Sie sich zum Anhalten immer einen sicheren Ort oder nehmen Sie eine Ausfahrt.
			p.para.fs Jegliche Nutzung von Mobiltelefonen während der Fahrt ist streng verboten. Um sicher ans Ziel zu kommen, müssen Sie die volle Aufmerksamkeit auf das Fahren richten.
			p.para.fs Achten Sie besonders auf Geschwindigkeitsbegrenzungen. In bewohnten Gebieten beträgt die Geschwindigkeitsbegrenzung normalerweise 50 km/h, in Wohngebieten 30 km/h und auf den Schnellstraßen in Reykjavik 60-80 km/h. In ländlichen Gebieten und auf der Autobahn beträgt die Höchstgeschwindigkeit 90 km/h, auf Schotterstraßen 80 km/h. Diese Höchstgeschwindigkeiten gelten nur für gute Fahrbedingungen, die Geschwindigkeit sollte jedoch bei schlechten Fahrbedingungen reduziert werden.
			p.para.fs Nach isländischem Gesetz müssen sowohl die Frontscheinwerfer als auch die Rücklichter das ganze Jahr über, Tag und Nacht, eingeschaltet sein.
			p.para.fs Wenn Sie sich Schotterstraßen nähern, ist es wichtig, die Geschwindigkeit zu reduzieren, damit Sie die Kontrolle über das Fahrzeug nicht verlieren. Verhalten Sie sich bei Gegenverkehr vorsichtig, indem Sie so weit wie möglich rechts bleiben. <span class='italic'>In Island gibt es spezielle Verkehrszeichen, die anzeigen, dass Sie sich einer Schotterstraße nähern und langsamer fahren müssen.</span>
			p.para.fs Während des Sommers in Island gibt es Tag und Nacht Tageslicht. Dies kann sehr gefährlich sein und birgt die Gefahr, dass Fahrer während der Fahrt einschlafen. Daher ist es wichtig, häufige Erholungspausen einzulegen und eine Rast zu machen, wenn Sie müde werden.
			p.para.fs Bei Annäherung an eine einspurige Brücke - Geschwindigkeit reduzieren. Das Auto, welches zuerst an der Brücke eintrifft, hat das Recht diese zuerst zu überqueren. Es ist dennoch ratsam, zum Einschätzen der Situation grundsätzlich zunächst anzuhalten. <span class='italic'> In Island gibt es ein spezielles Verkehrszeichen, dass eine vorausliegende einspurige Brücke anzeigt.</span>
			p.para.fs Nähern Sie sich nicht einsehbaren Bergkämmen, Hügeln und Kurven auf Straßen ohne getrennte Fahrspuren mit Vorsicht. Bitte halten Sie sich auf der rechten Seite der Straße und reduzieren Sie die Geschwindigkeit. Ein weißer Pfeil auf einem blauen Schild zeigt Ihnen die Fahrspur, auf der Sie fahren sollten. <span class='italic'>In Island haben wir ein spezielles Verkehrszeichen, das anzeigt, dass Sie sich einem nicht einsehbaren Kamm nähern und Sie möglichst weit rechts fahren sollten.</span>
			p.para.fs Häufig sieht man Vieh an den Straßenrändern der Autobahnen. In diesen Fall sollte die Geschwindigkeit verringert werden, da das Vieh oft unerwartet auf die Straße rennt.
			p.para.fs In Kreisverkehren hat das Fahrzeug auf der Kreisfahrbahn Vorfahrt vor dem einfahrenden Fahrzeug. Blinken Sie beim Verlassen des Kreisverkehrs. Unterlassen Sie Spurwechsel innerhalb und beim Verlassen des Kreisverkehrs.
			p.para.fs Abseits von Straßen und Wegen (Off-Road) zu fahren, ist in Island illegal und wird mit Geldbußen bestraft. Sie sollten ausschließlich Straßen und markierte Wege benutzen. Zeigen Sie großen Respekt für die Umwelt bei Ihrer Reise durch das Land.
			p.para.fs Das Fahren unter Einfluss von Alkohol oder anderen Rauschmitteln verstößt in Island gegen das Gesetz und <span class='italic'>wird sehr streng bestraft.</span>
			p.para.fs.bold Sie finden die folgenden nützlichen Links unterhalb des Soundplayers in dieser Nachricht.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (für Wetter- und Straßenverhältnisse)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (für Reisepläne und andere nützliche Informationen)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (für spezielle Verkehrszeichen und Videos)
			p.para.fs.bold Danke fürs Zuhören - wir wünschen Ihnen eine gute und angenehme Reise. Wenn Sie ein Allradfahrzeug fahren und eine Reise ins isländische Hochland planen, hören Sie sich bitte die folgende wichtige Mitteilung für Hochland-Reisende an:
			p.para.fs Zeigen Sie großen Respekt für die Umwelt bei Ihrer Reise durch das Land. Die isländische Natur ist aufgrund der starken Jahreszeitenwechsel und der häufigen Temperaturschwankungen extrem verletzlich! Achten Sie darauf, nur Fotos mitzunehmen und nur Fußspuren zu hinterlassen.
			p.para.fs.bold Es ist illegal in Island, abseits von Straßen und Wegen (Off-road) zu fahren. Bleiben Sie ausschließlich auf Straßen und markierten Wegen. Bei Verstößen sind hohe Bußgelder oder Gefängnisstrafen zu erwarten!
			p.para.fs Vorbereitung - Machen sie sich auf der Straßenkarte mit dem Verlauf der Straßen und Wege vertraut. (www.road.is für Straßenverhältnisse)
			p.para.fs Informieren Sie sich über die geeigneten Routen in Besucherzentren oder bei Rangern oder Mitarbeitern in der Umgebung.
			p.para.fs Durch das Entwurzeln oder Befahren von Moos können Sie schwere Schäden verursachen, von denen sich die Natur erst nach Jahrzehnten wieder erholen kann!
			p.para.fs Falls Sie an einem Punkt angekommen sind, an dem Sie nicht weiterfahren können, drehen Sie um oder gehen Sie ein kurzes Stück zu Fuß weiter. Bitte hinterlassen Sie dann eine Notiz mit Ihrer beabsichtigten Rückkehrzeit in Ihrem Fahrzeug.
			p.para.fs Fahrten ins Hochland sind im Sommer sehr beliebt. Die dazu notwendigen Fahrkünste unterscheiden sich jedoch grundlegend vom fahrerischen Können auf normalen Straßen. Die Bedingungen können sich noch schneller ändern als im Tiefland, und das Befahren der Straßen erfordert oft die Durchquerung von Flüssen ohne Brücke. Die Straßen können sehr uneben sein und daher nicht viel mehr als 40 km/h ermöglichen. Planen Sie also genügend Zeit ein. Daher ist es sehr wichtig, Informationen über das zu bereisende Gebiet zu sammeln und einen detaillierten Reiseplan bei safetravel.is zu hinterlassen.
			.points
				.point(v-for='point in points.German')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Danke fürs Zuhören - wir wünschen Ihnen eine gute und angenehme Reise.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Deutsche"')
			h1.title Vereiste Straßen - Winter
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Vereiste Straßen haben bereits zu sehr vielen Unfällen geführt. Straßen in Island sind im Winter oft sehr vereist, selbst wenn man es nicht sieht. Bitte Geschwindigkeit verringern und entsprechend fahren.
			p.para.fs Die Bedingungen im Winter sind komplett wetterabhängig. Überprüfen Sie zusammen mit den Straßenbedingungen die Wettervorhersage .  Achten Sie auf Winde, die 15 m/s übersteigen, da gebirgige Gebiete sehr starke Windböen erzeugen können! Die Vorhersage muss täglich überprüft werden. Obwohl die Prognose bis zu 5 Tage im Voraus gegeben wird, sind schnelle Änderungen sehr häufig!
			p.para.fs Auch wenn die Geschwindigkeitsbegrenzung bei 90 km/h liegt, handelt es sich dabei bei winterlichen Bedingungen nicht um eine geeignete Geschwindigkeit. Achten Sie darauf, Ihre Geschwindigkeit entsprechend zu reduzieren.
			p.para.fs Nicht alle Teile Islands sind während der Winterzeit zugänglich. Die meisten Hochebenen sind nur im Sommer erreichbar. Bitte beachten Sie, dass gesperrte Straßen unpassierbar sind.

		//- Nederlands
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Nederlands"')
			.transcript-title Read the transcript.
			p.para.fs.bold Hier volgt een belangrijk bericht met betrekking tot uw veiligheid op de IJslandse wegen. De omstandigheden in IJsland zijn ongebruikelijk en anders dan waar buitenlandse chauffeurs aan gewend zijn. Zorg voor uw veiligheid en neem de tijd om uzelf te informeren over de speciale IJslandse omstandigheden door naar dit bericht te luisteren, speciaal samengesteld voor huurauto's. We moedigen alle passagiers die samen reizen aan om naar dit bericht te luisteren.
			p.para.fs Raadpleeg tijdens het plannen van uw reis het weerbericht en informatie over de wegomstandigheden op <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Als een weg aangegeven staat als “gesloten” mag u deze onder geen enkele voorwaarde gebruiken. Neem geen risico's met uw leven en veiligheid door op een afgesloten weg te rijden.
			p.para.fs De chauffeur en alle passagiers dienen veiligheidsgordels te dragen, dit is wettelijk verplicht. Dit is de veiligste manier om uzelf te beschermen in het geval van een ongeluk. Ook kinderen moeten een gordel dragen, of dienen, afhankelijk van hun hoogte en gewicht, in een kinderzitje voor in de auto geplaatst te worden.
			p.para.fs Het is ten strengste verboden om midden op of aan de kant van de weg te stoppen. Zoek altijd een veilige (parkeer)plaats op om te stoppen.
			p.para.fs Het gebruik van een mobiele telefoon is ten strengste verboden tijdens het rijden. Om uw bestemming veilig te bereiken dient u uw volledige aandacht bij het rijden te houden.
			p.para.fs Let goed op de snelheidsbeperkingen. In bewoonde gebieden is deze meestal 50 km/u, in woonwijken 30 km/u, en op doorgaande wegen rond Reykjavik varieert het van 60 tot 80 km/u. In het buitengebied en op de snelwegen is de snelheidslimiet 90 km/u, maar op grindwegen 80 km/u. Dit zijn de maximum snelheden tijdens goede omstandigheden. Tijdens slechte omstandigheden dient de snelheid aangepast te worden.
			p.para.fs Volgens IJslandse wetgeving dienen zowel uw voor- als achterlichten altijd AAN te staan, zowel overdag als 's nachts, het hele jaar door.
			p.para.fs Indien u een grindweg nadert is het belangrijk om snelheid te verminderen zodat u de controle over uw voertuig niet verliest. Wees voorzichtig bij het naderen van een tegemoetkomend voertuig <span class='italic'>blijf zo goed mogelijk rechts rijden. Er zijn speciale verkeerslichten in IJsland die aangeven dat u een grindweg nadert en dat u vaart dient te minderen.</span>
			p.para.fs In de zomer is het in IJsland dag en nacht licht. Dit kan gevaarlijk zijn omdat chauffeurs het risico lopen achter het stuur in slaap te vallen. Neem daarom voldoende pauzes tijdens het rijden en rust voldoende uit als u moe wordt. Verminder vaart bij het naderen van een brug met maar één rijbaan. De auto die er het eerste aankomt heeft voorrang. Het is echter een goed idee om altijd gewoon even te stoppen en de situatie goed te bekijken. In IJsland zijn er speciale verkeerslichten om aan te geven dat u een brug met één rijbaan nadert.
			p.para.fs Onzichtbare bergtoppen, heuvels en bochten waar de rijbanen niet gescheiden zijn dienen voorzichtig benaderd te worden. Blijf goed aan de rechterkant van de weg en verminder snelheid. Een witte pijl of een blauw bord geeft de baan aan waarop u dient te rijden. <span class='italic'>In IJsland zijn er speciale verkeerslichten om aan te geven dat u een onzichtbare bergtop nadert en goed aan de rechterkant van de weg dient te blijven.</span>
			p.para.fs U ziet vaak vee langs de weg tijdens het rijden over de snelwegen. Als dit het geval is, verminder dan vaart, want vee kan plotseling de weg oplopen.
			p.para.fs Op rotondes hebben chauffeurs op de binnenste weghelft voorrang. Gebruik altijd uw richtingaanwijzers als u afslaat. Verander niet van rijstrook op de rotonde of tijdens het afslaan.
			p.para.fs U mag in IJsland nergens rijden als er geen weg ligt, hierop staat een boete. U mag alleen op wegen en gemarkeerde paden rijden. Tijdens uw reizen door het land dient u altijd respect te tonen voor de omgeving.
			p.para.fs Het is verboden in IJsland om te rijden met alcohol op (of na gebruik van andere roesmiddelen), <span class='italic'>de boetes bij een overtreding zijn hoog. </span>
			p.para.fs.bold U vindt de volgende handige links onder de geluidsspeler in dit bericht.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (voor het weerbericht en de wegcondities)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (voor reisplannen en andere bruikbare informatie)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (voor speciale verkeersborden en video's)
			p.para.fs.bold Bedankt voor het luisteren. We wensen u een veilige en plezierige reis. Rijdt u een wagen met vierwielaandrijving en bent u van plan om naar de IJslandse hooglanden te reizen, luister dan naar het volgende belangrijke bericht voor reizigers naar de hooglanden:
			p.para.fs Tijdens uw reizen door het land dient u altijd respect te tonen voor de omgeving. De IJslandse natuur is uiterst teer doordat de seizoenen nogal dramatisch veranderen en veranderingen in de temperatuur normaal zijn! Onthoud: neem niets mee dan foto's en laat niets achter dan voetsporen.
			p.para.fs.bold U mag in IJsland nergens rijden als er geen weg ligt. Blijf op de wegen en gemarkeerde paden. Op overtredingen staan hoge boetes of gevangenisstraffen!
			p.para.fs Voorbereiding: bekijk de kaart en waar de wegen en paden zijn. (www.road.is is voor wegomstandigheden)
			p.para.fs Vraag om informatie over welke routes geschikt zijn bij bezoekerscentra of vraag het aan parkwachters of personeel in het gebied.
			p.para.fs Door over mos te rijden of het te verwijderen kan er serieuze schade aan de natuur toegebracht worden die tientallen jaren nodig heeft om te herstellen!
			p.para.fs Komt u op een punt waar u niet verder kunt rijden, draai dan om of loop een stukje te voet. Laat een briefje achter in uw auto met daarop het tijdstip waarop u van plan bent terug te keren.
			p.para.fs Door de hooglanden rijden is zeer populair tijdens de zomer. Daarvoor dient u echter over hele andere vaardigheden te beschikken dan voor gewoon rijden. De omstandigheden veranderen hier nog sneller dan in het laagland, en vaak worden wegen onderbroken door rivieren zonder brug. De wegen gaan door ruig terrein waardoor u vaak niet sneller kunt gaan dan 40 km/u, dus zorg ervoor dat u voldoende tijd neemt. Daarom is het uiterst belangrijk om informatie te verzamelen over het gebied waar u doorheen wilt reizen en om een gedetailleerd reisplan achter te laten op safetravel.is.
			.points
				.point(v-for='point in points.Nederlands')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Bedankt voor het luisteren. We wensen u een veilige en plezierige reis.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Nederlands"')
			h1.title Gladde wegen – winter.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Gladde wegen hebben al veel ongelukken veroorzaakt. De wegen zijn vaak erg glad in de winter in IJsland, zelfs als het niet opvalt. Verminder uw snelheid en pas uw rijgedrag aan.
			p.para.fs De omstandigheden tijdens de winter zijn volledig afhankelijk van het weer. Bekijk dus naast de wegomstandigheden ook altijd het weerbericht.  Let op bij windstoten harder dan 15 m/s, want in de berggebieden treden vaak erg sterke windvlagen op! Bekijk het weerbericht iedere dag, want ook al kijken de voorspellingen vijf dagen vooruit, er is vaak sprake van snelle veranderingen.
			p.para.fs Ook al is de snelheidslimiet 90 km/u, dit wil niet zeggen dat dit tijdens winterse omstandigheden ook een passend snelheid is. Pas uw snelheid aan de omstandigheden aan.
			p.para.fs Niet alle delen van IJsland zijn toegankelijk tijdens de winter. Het grootste deel van de hooglanden is alleen in de zomer toegankelijk, dus vergeet niet dat u onder geen enkele voorwaarde gebruik kunt maken van afgesloten wegen.

		//- Española
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Española"')
			.transcript-title Read the transcript.
			p.para.fs.bold A continuación encontrará información importante relacionada con su seguridad en las carreteras islandesas. Las condiciones climáticas en Islandia son atípicas y diferentes a lo que están acostumbrados los conductores extranjeros. Conduzca de manera segura y familiarícese con las condiciones climáticas de Islandia escuchando este mensaje, creado especialmente para coches de alquiler.  Recomendamos que todos los pasajeros que viajen juntos escuchen este mensaje.
			p.para.fs Cuando planee su viaje consulte el tiempo y las condiciones de las carreteras <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Si se indica que una carretera está cerrada, no puede usarla bajo ninguna circunstancia. No arriesgue su vida y su seguridad conduciendo en una carretera cerrada.
			p.para.fs Los conductores y los pasajeros tienen que llevar puesto el cinturón de seguridad. Es obligatorio por ley. Es el mejor equipamiento de seguridad con el que cuenta para protegerse en caso de un accidente. También es obligatorio que los niños lleven el cinturón puesto o vayan sentados en una silla de bebé, en función de su peso y altura.
			p.para.fs Está totalmente prohibido parar en el medio de la carretera o en el arcén. Es obligatorio buscar siempre un sitio seguro o una salida.
			p.para.fs Está totalmente prohibido utilizar teléfonos móviles durante la conducción. Para llegar a su destino de manera segura, tiene que prestar mucha atención durante la conducción.
			p.para.fs Preste mucha atención a los límites de velocidad. Es las zonas pobladas, el límite de velocidad suele ser de 50 km/h. En zonas residenciales es de 30 km/h y en las autopistas de Reykjavik está entre los 60 y los 80 km/h. En las zonas rurales y en las autovías, el límite de velocidad es de 90 km/h, pero en caminos de grava es de 80 km/h. Estos son los límites máximos de velocidad si las condiciones para conducir son buenas, pero hay que reducir la velocidad si las condiciones de conducción son malas.
			p.para.fs De acuerdo con la ley islandesa, las luces delanteras y traseras tienen que estar siempre encendidas, tanto de día como de noche, durante todo el año.
			p.para.fs Cuando se aproxime a un camino de grava es importante reducir la velocidad para no perder el control del vehículo. Sea prudente cuando se acerque a un coche en la dirección opuesta y manténgase lo más alejado posible del lado derecho. <span class='italic'>En Islandia hay señales de tráfico especiales que indican que se está aproximando a un camino de grava y que tiene que reducir la velocidad.</span>
			p.para.fs En Islandia, durante el verano hay luz solar tanto de día como de noche. Esto puede ser muy peligroso y los conductores corren el riesgo de quedarse dormidos mientras conducen. Por eso es importante hacer muchas pausas mientras conduce y descansar si fuera necesario.
			p.para.fs Cuando se aproxime a un puente de un solo carril, reduzca la velocidad. El coche que llegue en primer lugar tiene derecho a cruzar. Pero es recomendable parar siempre y evaluar la situación. <span class='italic'>En Islandia tenemos una señal de tráfico especial que indica que se aproxima a un puente de un solo carril.</span>
			p.para.fs Hay que aproximarse con mucha precaución a las cimas con poca visibilidad, y las colinas y las curvas en las que los carriles no están delimitados. Manténgase en el lado derecho de la carretera y reduzca la velocidad. Una flecha blanca en una señal azul indica el carril por el que debe circular. <span class='italic'>En Islandia tenemos una señal de tráfico especial que indica que se aproxima a una cima con poca visibilidad y que tiene que mantenerse en el lado derecho de la carretera.</span>
			p.para.fs Es muy habitual ver ganado a los lados de las carreteras mientras conduce por la autopista. En estos casos se recomienda reducir la velocidad porque el comportamiento del ganado es impredecible y es posible que entren en la vía.
			p.para.fs En las rotondas el conductor situado en el carril interior tiene prioridad. Si esta existiera, utilice siempre la señal de giro.  Preste atención y no cambie de carril dentro de una rotonda o al salir.
			p.para.fs En Islandia está prohibido circular fuera de la carretera y hacerlo está sujeto a multas. Solo debe conducir en carreteras y caminos señalizados. Cuando conduzca por el campo tiene que ser muy respetuoso con el medio ambiente.
			p.para.fs Es ilegal manejar un vehículo en Islandia después de consumir alcohol u otros estupefacientes <span class='italic'>y las penas por violar estas leyes son muy severas.</span>
			p.para.fs.bold Los enlaces que encontrará a continuación debajo del reproductor de sonido en este mensaje le resultarán muy útiles.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (para condiciones climáticas y de las carreteras)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (para planes de viaje y otra información útil)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (para señales de tráfico especiales y vídeos)
			p.para.fs.bold Gracias por escuchar este mensaje. Esperamos que tenga un viaje seguro y agradable. Si conduce un vehículo 4x4 y planea viajar a las Tierras Altas de Islandia, escuche este mensaje que es muy importante para aquellos que viajan a las Tierras Altas:
			p.para.fs Cuando conduzca por el campo tiene que ser muy respetuoso con el medio ambiente. La naturaleza en Islandia es muy frágil porque el cambio de estaciones es muy brusco y los cambios de temperatura son muy comunes. Recuerde que puede sacar fotografías pero no puede llevarse nada de los sitios que visite, del mismo modo que lo máximo que puede dejar tras de sí son sus huellas en el suelo.
			p.para.fs.bold En Islandia es ilegal circular fuera de la carretera. Manténgase siempre en carreteras y caminos señalizados. Este tipo de violaciones están sujetas a multas muy elevadas y penas de cárcel.
			p.para.fs Preparación. Consulte el mapa de carreteras y busque las carreteras y caminos. (www.road.is para ver las condiciones de las carreteras)
			p.para.fs Obtenga información sobre las rutas más apropiadas en los centros de visitantes o preguntando a los guardas forestales o al personal que trabaja en la zona.
			p.para.fs Arrancar plantas del suelo o conducir sobre el musgo puede causar daños de los que la naturaleza tardará décadas en recuperarse.
			p.para.fs Si llega a un punto en el que no puede seguir conduciendo, dé la vuelta o siga avanzando a pie, dejando siempre una nota en su vehículo en la que indicará la hora a la que volverá al mismo.
			p.para.fs Durante el verano, una de las actividades más populares es conducir por las Tierras Altas. Pero para esto son necesarias habilidades muy diferentes a las que se utilizan durante la conducción normal. Las condiciones climáticas pueden cambiar con mucha más rapidez que en las zonas de llanura y es habitual tener que cruzar ríos que carecen de puentes. Las carreteras pueden ser accidentadas y es posible que no alcance velocidades superiores a 40 km/h, por eso asegúrese de planificar de manera que tenga suficiente tiempo. Esto hace que sea muy importante reunir información sobre la zona por la que va a viajar y dejar un plan de viaje detallado en safetravel.is.
			.points
				.point(v-for='point in points.Española')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Gracias por escuchar este mensaje. Esperamos que tenga un viaje seguro y agradable.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Española"')
			h1.title Carreteras congeladas. Invierno.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Las carreteras congeladas pueden causar muchos accidentes. Aunque a veces no lo parezca a simple vista, en invierno las carreteras en Islandia suelen congelarse. Reduzca la velocidad y conduzca con cuidado.
			p.para.fs Las condiciones climáticas durante el invierno dependen totalmente del tiempo. Además de comprobar las condiciones de la carretera, consulte la previsión meteorológica.  Tenga cuidado con los vientos que superen los 15 m/s porque en las zonas montañosas pueden darse ráfagas de viento muy fuertes. Consulte la previsión meteorológica todos los días, aunque la previsión englobe hasta 5 días. Los cambios bruscos de tiempo son muy habituales.
			p.para.fs Aunque el límite de velocidad sea de 90 km/h, esto no significa que sea una velocidad adecuada para conducir en condiciones climáticas invernales. Asegúrese de que reduce su velocidad en función de las condiciones climáticas.
			p.para.fs No se puede acceder a todas las zonas de Islandia durante el invierno. Solo se puede acceder a la mayor parte de las Tierras Altas durante el verano, por eso tenga en cuenta que no puede entrar si las carreteras están cerradas.

		//- Française
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Française"')
			.transcript-title Read the transcript.
			p.para.fs.bold Voici quelques remarques importantes afin de conduire en toute sécurité sur les routes islandaises. En Islande, les conditions sont inhabituelles et diffèrent de celles auxquelles les conducteurs étrangers sont habitués. Restez prudent et prenez le temps de vous familiariser avec la particularité des conditions islandaises en écoutant ce message, élaboré spécialement pour les voitures de location.  Nous recommandons à tous les passagers qui voyagent ensemble d’écouter ce message.
			p.para.fs Quand vous planifiez votre itinéraire, vérifiez la météo et l’état de la route sur <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Si une route est indiquée fermée, vous ne pouvez en aucun cas l’emprunter. Ne risquez pas votre vie et votre sécurité en conduisant sur une route fermée.
			p.para.fs Les conducteurs et tous les passagers doivent attacher leur ceinture de sécurité. Cela relève d’une obligation légale. Il s’agit du meilleur équipement à votre disposition pour vous protéger en cas de collision. Les enfants sont également dans l’obligation de porter une ceinture de sécurité ou d’être placés dans un siège auto, en fonction de leur taille et de leur poids.
			p.para.fs Il est strictement interdit de s’arrêter au milieu de la route ou sur l’accotement. Vous devez toujours trouver un endroit sûr ou une sortie.
			p.para.fs Tout usage du téléphone mobile est strictement interdit au volant. Pour arriver à bon port, vous devez consacrer toute votre attention à la conduite.
			p.para.fs Respectez scrupuleusement les limitations de vitesse. La vitesse maximale est habituellement limitée à 50 km/h en agglomération et à 30 km/h dans les zones résidentielles. Elle est comprise entre 60 et 80 km/h sur les vois rapides à Reykjavik. La vitesse est limitée à 90 km/h à la campagne et sur autoroute, mais à 80 km/h sur route en graviers. Ces limitations de vitesse sont en vigueur si les conditions sont bonnes, mais la vitesse doit être réduite si les conditions de conduite sont mauvaises.
			p.para.fs En Islande, la loi exige de conduire avec les feux ALLUMÉS en permanence, de nuit comme de jour, en toute période de l’année.
			p.para.fs À l’approche d’une route en graviers, il est important de réduire la vitesse de manière à ne pas perdre le contrôle de votre véhicule. Soyez prudents à l’approche d’une voiture en sens inverse et tenez-vous le plus à droite possible. <span class='italic'>En Islande, un panneau routier spécifique signale que vous approchez d’une route en graviers et que vous devez ralentir.</span>
			p.para.fs En Islande, le jour est permanent en été. Cela peut être très dangereux et provoquer des états de somnolence au volant. Il est donc important de faire des haltes fréquentes et de faire une pause en cas de fatigue.
			p.para.fs À l’approche d’un pont à voie unique, ralentissez. La première voiture engagée a le droit de traverser. Il est cependant judicieux de s’arrêter et d’évaluer la situation à chaque occasion. <span class='italic'>En Islande, il existe un panneau routier spécifique qui indique l’approche d’un pont à voie unique.</span>
			p.para.fs Les buttes, collines et virages sans visibilité et où les voies ne sont pas séparées doivent être négociés avec prudence. Tenez-vous le plus à droite possible de la route et ralentissez. Une flèche blanche sur fond bleu indique la voie sur laquelle vous devez circuler. <span class='italic'>En Islande, il existe un panneau routier spécifique qui indique l’approche d’une butte sans visibilité et que vous devez vous tenir le plus à droite possible de la route.</span>
			p.para.fs Il est courant de croiser du bétail en bordure d’autoroute. Dans ces circonstances, il faut réduire sa vitesse car les animaux ont tendance à agir de manière imprévisible et peuvent courir sur la route.
			p.para.fs Sur les ronds-points, la priorité revient au conducteur situé à l’intérieur. Utilisez toujours votre clignotant pour sortir.  Veillez à rester sur votre voie dans le rond-point ou quand vous en sortez.
			p.para.fs En Islande, la conduite tout-terrain est interdite sous peine d’amende. Vous ne devez conduire que sur les routes et les sentiers balisés. Lors de vos voyages à travers le pays, vous devez faire preuve du plus grand respect pour l’environnement.
			p.para.fs En Islande, il est contraire à la loi de conduire un véhicule après avoir consommé de l’alcool ou d’autres stupéfiants <span class='italic'>et les sanctions en cas de violation de cette législation sont lourdes.</span>
			p.para.fs.bold Vous trouverez les liens utiles suivants sous le lecteur dans ce message.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (pour les conditions météorologiques et l’état de la route)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (pour les itinéraires et autres informations utiles)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (pour la signalisation routière spécifique et les vidéos)
			p.para.fs.bold Merci pour votre attention. Nous vous souhaitons un agréable voyage en toute sécurité. Si vous conduisez un véhicule 4x4 et que vous prévoyez de vous rendre dans les hautes terres d’Islande, veuillez écouter le message important suivant à l’attention des voyageurs :
			p.para.fs Lors de vos voyages à travers le pays, vous devez faire preuve du plus grand respect pour l’environnement. La nature islandaise est extrêmement fragile car les saisons changent de manière très spectaculaire et les variations de température sont courantes ! N’oubliez pas : ne rapportez rien d’autre que des photos et ne laissez que des empreintes derrière vous.
			p.para.fs.bold En Islande, la conduite tout-terrain est illégale. Restez exclusivement sur les routes et les sentiers balisés. De telles infractions sont passibles de lourdes amendes ou d’emprisonnement !
			p.para.fs Préparation : consultez une carte routière et voyez où les routes et pistes se trouvent. (www.road.is pour l’état de la route)
			p.para.fs Renseignez-vous sur les itinéraires à emprunter auprès des centres d’information, des garde-forestiers ou du personnel sur place.
			p.para.fs En cas de déracinement ou si vous conduisez sur de la mousse, vous risquez de provoquer de graves dommages dont la nature peut mettre des dizaines d’années à se remettre !
			p.para.fs Si vous arrivez à un endroit au-delà duquel vous ne pouvez plus conduire, faites demi-tour ou poursuivez à pied en laissant dans votre véhicule une note indiquant l’heure à laquelle vous comptez revenir.
			p.para.fs Conduire dans les hautes terres est très populaire en été. Cependant, cela requiert des aptitudes différentes de celles de la conduite normale. Les conditions peuvent évoluer bien plus rapidement que dans les basses terres et les routes imposent souvent de franchir des rivières sans pont. Les routes peuvent être difficiles et il n’est guère possible de dépasser les 40 km/h ; prévoyez par conséquent assez de temps. Il est donc très important de vous renseigner sur l’endroit que vous allez parcourir et de charger un Plan de voyage détaillé sur safetravel.is.
			.points
				.point(v-for='point in points.Française')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Merci pour votre attention. Nous vous souhaitons un agréable voyage en toute sécurité.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Française"')
			h1.title Routes verglacées et conditions hivernales
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Les routes verglacées provoquent de nombreux accidents. En Islande, les routes sont souvent très verglacées en hiver, même si vous ne le voyez pas. Veuillez ralentir et conduire en conséquence.
			p.para.fs Les conditions de conduite en hiver dépendent complètement du temps. Outre l’état des routes, veillez également à vérifier les prévisions météo.  Faites attention aux vents qui dépassent 15 m/s car les zones montagneuses peuvent produire de fortes rafales ! Même si elles sont disponibles jusqu’à 5 jours à l’avance, les prévisions doivent être consultées chaque jour car les changements rapides sont très courants !
			p.para.fs Même si la vitesse est limitée à 90 km/h, cela ne signifie pas qu’une telle vitesse soit appropriée par conditions hivernales. Veillez à réduire votre vitesse en conséquence.
			p.para.fs Toute l’Islande n’est pas accessible en hiver. La majeure partie des hautes terres est accessible uniquement en été ; gardez par conséquent à l’esprit que vous ne devez en aucun cas emprunter une route fermée.

		//- Polskie
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Polskie"')
			.transcript-title Read the transcript.
			p.para.fs.bold Oto kilka ważnych informacji dotyczących bezpieczeństwa na islandzkich drogach. Warunki na Islandii są nietypowe i inne od tych, do których przywykli kierowcy z zagranicy. Prosimy zachować ostrożność i zapoznać się z niniejszym komunikatem dotyczącym specyficznych islandzkich warunków. Komunikat został przygotowany specjalnie dla osób wynajmujących samochody.  Zalecamy, aby komunikatu wysłuchały wszystkie podróżujące razem osoby.
			p.para.fs Podczas planowania wycieczki warto sprawdzić prognozę pogody i warunki na drodze na stronie <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Jeśli droga jest oznaczona jako zamknięta, przejazd nią jest wykluczony. Jazda po zamkniętej drodze stwarza ryzyko utraty życia lub zdrowia.
			p.para.fs Kierowcy i wszyscy pasażerowie muszą mieć zapięte pasy bezpieczeństwa – jest to wymóg prawny. To najlepsze zabezpieczenie w razie wypadku samochodowego. W zależności od wzrostu i masy ciała dzieci również muszą mieć zapięte pasy bezpieczeństwa lub podróżować w fotelikach samochodowych.
			p.para.fs Zatrzymywanie się na środku drogi lub na poboczu jest zabronione – postój jest dozwolony wyłącznie w bezpiecznych i przeznaczonych do tego miejscach.
			p.para.fs Korzystanie z telefonu komórkowego podczas prowadzenia pojazdu jest całkowicie zabronione. Aby bezpiecznie dotrzeć na miejsce, całkowicie skoncentruj się na prowadzeniu pojazdu.
			p.para.fs Przestrzegaj ograniczeń prędkości. W obszarach zaludnionych maksymalna prędkość wynosi zwykle 50 km/h, w obszarach zabudowanych – 30 km/h, a na drogach przelotowych w Reykjavíku od 60 do 80 km/h. Na obszarach wiejskich na autostradzie maksymalna prędkość wynosi 90 km/h, ale na drogach żwirowych jest obniżona do 80 km/h. To maksymalne prędkości dozwolone przy dobrych warunkach jazdy; w przypadku niekorzystnych warunków wskazane jest dostosowanie prędkości jazdy.
			p.para.fs Islandzkie prawo wymaga, aby przednie i tylne światła były WŁĄCZONE przez cały czas, zarówno dniem, jak i nocą i każdego dnia w roku.
			p.para.fs Podczas zbliżania się do drogi żwirowej zmniejsz prędkość, aby uniknąć utraty kontroli nad pojazdem. Zachowaj ostrożność podczas przejeżdżania obok pojazdów jadących w przeciwną stronę i zjedź możliwie najbardziej na prawą stronę. <span class='italic'>Na Islandii używany jest specjalny znak informujący o konieczności ograniczenia prędkości jazdy z powodu zbliżania się do drogi żwirowej.</span>
			p.para.fs Latem na Islandii słońce świeci zarówno w dzień, jak i w nocy. Może się to wiązać z ryzykiem zaśnięcia kierowcy za kierownicą. Dlatego warto często robić przerwy w czasie jazdy i odpoczywać, gdy pojawia się uczucie zmęczenia. Przed wjazdem na most o szerokości jednego pasa zmniejsz prędkość. Pierwszeństwo przejazdu przez most ma pojazd, który dojedzie do niego pierwszy. Mimo to za każdym razem warto zatrzymać się i dokładnie ocenić sytuację. <span class='italic'>Na Islandii stosowany jest specjalny znak informujący kierowcę o znajdującym się w pobliżu moście o szerokości jednego pasa ruchu.</span>
			p.para.fs Zachowaj ostrożność podczas jazdy pod górę, po pagórkowatym terenie, na zakrętach i po drogach, na których pasy ruchu nie są od siebie oddzielone. W takich sytuacjach zjedź możliwie najbardziej na prawo i zmniejsz prędkość. Biała strzałka na niebieskim znaku odnosi się do pasa, po którym masz się poruszać. Na Islandii wykorzystywany jest specjalny znak informujący <span class='italic'>o ograniczonej widoczności wynikającej z jazdy po pochyłości w górę; wskazane jest zjechanie możliwie najbardziej na prawą stronę drogi.</span>
			p.para.fs Podczas jazdy po autostradzie na poboczach często można zobaczyć zwierzęta hodowlane. W takich sytuacjach ogranicz prędkość, ponieważ zwierzęta zachowują się w nieprzewidywalny sposób i mogą wbiec na drogę.
			p.para.fs Na rondach pierwszeństwo ma kierowca jadący po wewnętrznym pasie. Zjazd z ronda zawsze sygnalizuj kierunkowskazem.  Podczas jazdy po rondzie i zjeżdżania z niego uważaj, aby nie zmienić pasa ruchu.
			p.para.fs Jazda poza drogami jest na Islandii zakazana i skutkuje nałożeniem grzywny. Jazda jest dozwolona wyłącznie na drogach i wyznaczonych trasach. Podczas podróżowania po kraju szanuj środowisko naturalne.
			p.para.fs Na Islandii zabronione jest prowadzenie pojazdu pod wpływem alkoholu lub innych środków odurzających, <span class='italic'>a za złamanie tego zakazu grożą surowe kary.</span>
			p.para.fs.bold Pod odtwarzaczem znajdują się przydatne łącza internetowe.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (prognoza pogody i informacje o warunkach na drodze)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (planowanie podróży i inne przydatne informacje)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (specjalne znaki drogowe i filmy)
			p.para.fs.bold Dziękujemy za uwagę – życzymy bezpiecznej i przyjemnej podróży. Jeśli prowadzisz pojazd z napędem 4x4 i planujesz wyjazd w islandzkie góry, wysłuchaj ważnego komunikatu:
			p.para.fs Podczas podróżowania po kraju szanuj środowisko naturalne. Islandzka przyroda jest bardzo delikatna, a zmiany pór roku i temperatury gwałtowne i częste. Jako pamiątkę z podróży weź ze sobą tylko zdjęcia i nie pozostawiaj po sobie nic oprócz śladów stóp.
			p.para.fs.bold Opuszczanie wytyczonych dróg jest na Islandii zabronione. Poruszaj się tylko po drogach i oznakowanych szlakach. Złamanie tej zasady grozi wysokimi grzywnami i pozbawieniem wolności!
			p.para.fs Przygotowanie – obejrzyj mapę drogową i dowiedz się, którędy biegną drogi i szlaki. (Na stronie www.road.is możesz dowiedzieć się, jakie warunki panują na drogach)
			p.para.fs Uzyskaj informacje na temat odpowiednich tras w informacji turystycznej, od pracowników parków narodowych lub innych osób pracujących w okolicy.
			p.para.fs Wyrywając rośliny i jeżdżąc po mchu, możesz wyrządzić poważne szkody, których naprawienie może zająć naturze nawet kilkadziesiąt lat!
			p.para.fs Jeśli dotrzesz do miejsca, w którym dalsza jazda jest niemożliwa, zawróć lub idź dalej pieszo (pozostaw w pojeździe notatkę z przewidywanym terminem powrotu).
			p.para.fs Jazda po terenach górskich jest bardzo popularna latem. Wymaga ona jednak innych umiejętności niż jazda w zwykłym terenie. Zmiany warunków mogą być jeszcze gwałtowniejsze niż na nizinach i często wymagany jest przejazd przez rzeki w bród. Drogi mogą być w złym stanie i niekiedy rozwinięcie prędkości powyżej 40 km/h może być niemożliwe, więc zaplanuj odpowiednio dużo czasu na podróż. W związku z tym bardzo ważne jest, aby zebrać informacje na temat obszaru, po którym będziesz podróżować i zamieścić szczegółowy plan podróży na stronie safetravel.is.
			.points
				.point(v-for='point in points.Polskie')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Dziękujemy za uwagę – życzymy bezpiecznej i przyjemnej podróży.
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Polskie"')
			h1.title Oblodzone drogi – zima.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Oblodzone drogi są przyczyną wielu wypadków. Zimą drogi na Islandii są często bardzo oblodzone, choć czasem lód nie jest widoczny. W takich przypadkach zmniejsz prędkość i dostosuj sposób jazdy do warunków.
			p.para.fs W zimie warunki na drodze całkowicie zależą od pogody. Oprócz informacji o warunkach na drodze sprawdzaj też prognozę pogody.  Uważaj na wiatr wiejący z prędkością przekraczającą 15 m/s, ponieważ w górzystym terenie może powodować bardzo silne podmuchy. Mimo że prognoza pogody jest podawana na 5 dni, sprawdzaj ją codziennie, ponieważ sytuacja pogodowa może się gwałtownie zmieniać.
			p.para.fs Nawet jeśli maksymalna prędkość na drodze wynosi 90 km/h, zimą taka wartość może być nieodpowiednia. Dostosuj prędkość jazdy do panujących warunków.
			p.para.fs Nie wszystkie części Islandii są dostępne zimą. Do większości terenów górskich można dojechać wyłącznie latem. Pamiętaj, że jeśli droga jest zamknięta, nie można na nią wjeżdżać pod żadnym pozorem.

		//- Norsk
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Norsk"')
			.transcript-title Read the transcript.
			p.para.fs.bold Her er viktig informasjon om sikkerhet på islandske veier. Forholdene på Island er uvanlige og ligner ikke på det utenlandske sjåfører er vant til. Vær forsiktig og ta deg tid til å bli kjent med de særegne islandske forholdene ved å lytte til denne informasjonen; særlig relevant for leiebiler. Vi anbefaler at alle passasjerer som reiser sammen, lytter til denne informasjonen.
			p.para.fs Når du planlegger reisen din, bør du sjekke vær- og veiforholdene på <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. Hvis en vei er markert som stengt, kan du ikke kjøre der under noen omstendigheter. Risiker ikke livet og sikkerheten din ved å kjøre på en stengt vei.
			p.para.fs Sjåfører og alle passasjerene må bruke setebelter – det er påbudt. Det er det beste sikkerhetsutstyret du har for å beskytte deg ved bilulykker. Barn må også bruke setebelter eller sitte i bilseter, avhengig av barnets høyde og vekt.
			p.para.fs Det er strengt forbudt å stoppe midt i veien eller på veikanten, du må alltid finne et trygt sted eller en avkjøring du kan stoppe ved.
			p.para.fs All bruk av mobiltelefoner er strengt forbudt mens du kjører. Du må fokusere på kjøringen for å komme frem i god behold.
			p.para.fs Vær særlig oppmerksom på fartsgrenser. I bebodde områder er fartsgrensen vanligvis 50 km/t, i boligstrøk er den ofte 30 km/t og på motorveier i Reykjavik varierer det mellom 60–80 km/t. I utkantstrøk og på motorveien er fartsgrensen 90 km/t, men på grusvei er den 80 km/t. Disse maksimale fartsgrensene gjelder ved gode kjøreforhold, men farten bør senkes hvis kjøreforholdene er dårlige.
			p.para.fs Ifølge islandsk lov skal både frontlysene og baklysene alltid være slått PÅ, natt og dag, hele året.
			p.para.fs Når du nærmer deg grusveier, er det viktig å redusere hastigheten slik at du ikke mister kontrollen over kjøretøyet. Utvis forsiktighet når du nærmer deg en bil som kommer fra motsatt kjøreretning ved å holde deg så langt til høyre som mulig. <span class='italic'>Det finnes spesielle trafikkskilt på Island som indikerer at du nærmer deg en grusvei og må senke farten.</span>
			p.para.fs På sommeren på Island er det dagslys både natt og dag. Dette kan være svært farlig og øker risikoen for at sjåfører sovner mens de kjører. Derfor er det viktig å ta hyppige pauser, og hvile hvis du blir trøtt eller sliten.
			p.para.fs Når du nærmer deg en bro med én kjørefil, skal du redusere hastigheten. Den første bilen som ankommer broen har rett til å krysse den. Men det kan være smart å stoppe og vurdere situasjonen hver gang. <span class='italic'>På Island har vi spesielle trafikkskilt som indikerer at du nærmer deg en bro med én fil.</span>
			p.para.fs Utvis forsiktighet ved bakketopper og svinger med dårlig sikt uten delte kjørefiler. Hold deg helt ut på høyre side av veien og senk hastigheten. En hvit pil på et blått skilt viser filen du skal kjøre på. <span class='italic'>På Island har vi spesielle trafikkskilt som indikerer at du nærmer deg en bakketopp med dårlig sikt og bør holde deg helt ut på høyre side av veien.</span>
			p.para.fs Det er vanlig å se buskap ved veikanten når du kjører på motorveien. I så tilfelle bør du senke hastigheten, da buskapen kan reagere uventet og kan løpe ut i veien.
			p.para.fs Sjåføren i innerste fil har forkjørsrett i rundkjøringer. Bruk alltid blinklys når du kjører ut av rundkjøringen. Pass på å ikke bytte fil inne i eller når du kjører ut av rundkjøringen.
			p.para.fs Det er ulovlig å kjøre i terrenget på Island. Dette vil bøtelegges. Du skal kun kjøre på veier og oppmerkede stier. Når du reiser rundt i landet, må du utvise stor respekt for miljøet.
			p.para.fs Det er forbudt å kjøre et kjøretøy på Island etter å ha drukket alkohol eller å ha tatt andre rusmidler, <span class='italic'>og straffene for å bryte disse lovene er svært strenge.</span>
			p.para.fs.bold Du finner følgende nyttige lenker under lydavspilleren i denne meldingen.
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (for vær- og veiforhold)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (for reiseplaner og annen nyttig informasjon)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (for spesielle trafikkskilt og videoer)
			p.para.fs.bold Takk for at du lyttet – vi håper du får en trygg og hyggelig tur. Hvis du kjører et kjøretøy med firehjulsdrift og planlegger å reise til høylandet på Island, bør du lytte til den viktige reiseinformasjonen for høylandet her:
			p.para.fs Når du reiser rundt i landet, må du utvise stor respekt for miljøet. Den islandske naturen er ekstremt sårbar, da sesongene endrer seg svært dramatisk og det er vanlig med temperaturendringer! En god huskeregel er å ikke ta noe annet enn bilder, og ikke etterlate noe annet enn fotavtrykk.
			p.para.fs.bold Det er ulovlig å kjøre i terrenget på Island. Hold deg på veier og oppmerkede stier. Du kan vente deg høye bøter eller fengsel for slike lovbrudd!
			p.para.fs Forberedelse – sjekk veikartet og se hvor veiene og stiene er. (www.road.is for veiforhold)
			p.para.fs Få informasjon om passende ruter på besøkssentre eller fra skogvoktere eller personale i området.
			p.para.fs Ved å rykke opp eller kjøre på mosen kan du forårsake alvorlig skade som det kan ta naturen flere tiår å gjenopprette!
			p.para.fs Hvis du kommer til et punkt hvor du ikke kan kjøre lenger – må du snu, eller fortsette en liten strekning til fots etter å ha lagt igjen en lapp i kjøretøyet ditt med informasjon om når du forventer å være tilbake.
			p.para.fs Det er svært populært å kjøre i høylandet på sommeren. Men det krever helt andre ferdigheter enn for normal kjøring. Forholdene kan endre seg enda raskere enn i lavlandet, og veiene krever ofte at du krysser elver uten bro. Veiene kan være vanskelige, og du kan kanskje ikke kjøre i så mye mer enn 40 km/t, så sørg for å ha god tid. Derfor er det ekstremt viktig å finne informasjon om området du reiser i og å legge igjen en detaljert reiseplan på safetravel.is.
			.points
				.point(v-for='point in points.Norsk')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading Takk for at du lyttet – vi håper du får en trygg og hyggelig tur.

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "Norsk"')
			h1.title Glatte veier – vinter.
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Glatte veier har forårsaket mange ulykker. Veiene på Island er ofte dekket av is på vinteren, selv om du ikke ser den. Senk hastigheten og kjør forsiktig.
			p.para.fs Forholdene på vinteren er avhenger helt og holdent av været. I tillegg til veiforholdene må du sørge for å sjekke værmeldingen. Pass opp for vinder som overstiger 15 m/s, da fjellrike områder kan forårsake svært sterke vindkast! Værmeldingen må sjekkes hver dag, for selv om værmeldingen strekker seg 5 dager fremover, er det helt vanlig at været endrer seg raskt!
			p.para.fs Selv om fartsgrensen sier 90 km/t, betyr det ikke at det er passende på vinterføre. Sørg for at du tilpasser farten til føret.
			p.para.fs Ikke alle deler av Island er tilgjengelig på vinteren. De meste av høylandet er kun tilgjengelig på sommeren, så husk at du ikke under noen omstendigheter kan kjøre inn hvis veien er stengt.

		//- 中文
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "中文"')
			.transcript-title Read the transcript.
			p.para.fs.bold 以下是有关冰岛道路安全的重要消息。冰岛的气候不同于其他国家和地区，可能与外国驾驶员所习惯的环境不太一样。请务必注意安全，并花一些时间收听这则专为汽车租用编写的消息，以了解冰岛的特殊环境。我们建议所有同行的乘客都应注意收听此消息。
			p.para.fs 在规划行程时，您可以访问 <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. 查询天气和路况信息。如果道路标记为已封闭，那么您在任何情况下都不得通行。切勿冒生命危险驶入已封闭的道路。
			p.para.fs 根据法律规定，驾驶员和所有乘客均须系好座椅安全带。这是发生交通意外时能为您提供保护的最佳安全设备。儿童也需要根据身高和体重系好座椅安全带或者使用安全座椅。
			p.para.fs 严禁在道路中间或路边泊车，请始终寻找安全场所或商场泊车。
			p.para.fs 严禁在驾驶过程中使用手机。为安全到达目的地，您须小心翼翼、集中精力驾驶车辆。
			p.para.fs 密切关注限速标志。人口稠密地区限速通常为 50km/h，居民区限速为 30km/h，雷克雅未克市区各主要干道限速通常为 60-80km/h。郊区和主干道限速为 90km/h，但沙石路限速为 80km/h。这些均是良好路况时的最大限速，如果路况不佳，请务必降低车速。
			p.para.fs 根据冰岛法律规定，无论是白天或是夜间，车辆在行驶期间必须始终打开头灯和尾灯。
			p.para.fs 即将驶上沙石路时，请务必降低车速，以免车辆失控。对向会车时请小心谨慎，尽量靠右行驶。 <span class='italic'>冰岛各地设有特殊交通标志，提醒您前方是沙石路，必须降低车速。</span>
			p.para.fs 在夏季时分，冰岛无论白天或是夜间都有日照。这是一种非常危险的现象，可能导致驾驶员在驾车过程中打瞌睡。因此，驾驶期间请务必注意多休息，如果感到疲倦，就要休息一会儿。
			p.para.fs 接近单行道桥梁时， 请降低车速。首先接近的车辆拥有优先通行权。然而，建议您每次都停车观察交通状况。 <span class='italic'>冰岛各地设有特殊的交通标志，提醒前方有单行道桥梁。</span>
			p.para.fs 接近没有划分车道的坡顶、山路和弯道时，请务必小心驾驶。请尽量靠右行驶并降低车速。蓝底白箭头标志用于指示您应该行驶的车道。 <span class='italic'>冰岛各地设有特殊交通标志，提醒前方即将到达坡顶，应该尽量靠右行驶。</span>
			p.para.fs 在主干道上行驶时，您经常会看到有牲畜在路边活动。此时请尽量降低车速，以免惊扰牲畜，导致其逃窜至公路上。
			p.para.fs 在环岛中，内侧车道的驾驶员拥有优先通行权。驶出环岛时请始终打开转向信号灯。注意切勿在环岛内或出环岛时变换车道。
			p.para.fs 冰岛法律禁止在非公路地区驱车，违反规定将面临罚款。您仅应在公路和有标志的小径上行驶。在冰岛各地游历时，请务必注意保护环境。
			p.para.fs 在冰岛，饮酒或饮用其他酒精饮料后驾车属违法行为，可能会面临非常严厉的处罚。
			p.para.fs.bold 您可在此消息的音频播放器下方找到以下实用链接。
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (查询天气和路况)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (查询旅行计划和其他实用信息)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (查询特殊交通标志和视频)
			p.para.fs.bold 感谢您的收听！祝您享受一段安全、愉快的旅程。如果您计划驾驶四驱车辆前往冰岛的高原地区，请注意收听以下专为高原旅行者编写的消息：
			p.para.fs 在冰岛各地游历时，请务必注意保护环境。由于季节性变化明显、气温变化频繁，冰岛的自然环境非常脆弱！请务必做到：除了照片，不带走任何东西；除了脚印，不留下任何东西。
			p.para.fs.bold 冰岛法律禁止在非公路地区驱车。仅在公路和有标志的小径上行驶。违反此规定会面临高额罚款甚至监禁！
			p.para.fs 准备工作：查看地图，了解道路和小径的分布。（访问 www.road.is 查询路况）
			p.para.fs 向游客中心、护林员或者当地人员了解游览路线的信息。
			p.para.fs 拔除或碾压苔藓将对自然环境造成严重伤害，可能需要几十年才能恢复！
			p.para.fs 当到达无法继续驱车前进的位置时，请调转车头或者继续步行前进一段距离，并在车中留下字条，说明您计划返回的时间。
			p.para.fs 夏季时分，高原自驾游颇受游客的青睐。然而这要求您须掌握一项完全不同于普通驾驶的另一项技能。高原地区的气候变化远比低海拔地区更为剧烈，而且道路往往会被没有桥梁的河流截断。高原地区的路况非常崎岖，车速通常难以达到 40 km/h 以上，因此请务必安排足够的时间。正因如此，您务必要事先了解游览沿途的相关信息，并在 safetravel.is 上填写详细的旅行计划。
			.points
				.point(v-for='point in points.中文')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading 感谢您的收听！祝您享受一段安全、愉快的旅程。

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "中文"')
			h1.title 道路结冰 – 冬季。
			.dashes
				.dash(v-for='n in 5')
			p.para.fs 道路结冰已导致许多交通事故。冰岛各地的道路在冬季往往都会结冰，即便有时您肉眼并未察觉到。请降低车速，小心驾驶。
			p.para.fs 冬季的路况完全取决于天气。在查询路况的同时，还请查询天气预报。注意风速超过 15 m/s 的大风。在这种天气下，山地可能会形成极强的阵风。请务必每天查询天气预报，虽然目前可查询未来 5 天的天气预报，但实际天气可能会发生骤变！
			p.para.fs 即便限速标志为 90 km/h，也并不表示该限速在冬季条件下也适用。请务必相应地降低车速。
			p.para.fs 在冬季，您可能无法抵达冰岛的某些地方。大部分高原地区仅适合夏季前往，因此请注意，当道路封闭时，您无论如何都不得通行。

		//- 日本人
		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "日本人"')
			.transcript-title Read the transcript.
			p.para.fs.bold アイスランドの道路における安全面に関する重要なメッセージです。アイスランドの道路状況は海外から来るドライバーの皆さんが慣れ親しんでいるものとは異なり、独特なものです。安全面に気を付けて、このレンタカー用に作成されたメッセージを聞いて時間をかけて特殊なアイスランドの状況をよく理解してください。一緒に旅をする同乗者の方もこのメッセージを聞くことをお勧めします。
			p.para.fs 旅行を計画するときには天候と道路状況を <a class='para-link bold' href='http://www.road.is' target='_blank'>www.road.is</a>. で調べてください。道路に閉鎖の印が出ている場合は、いかなる状況下であっても通行不可能です。安全をないがしろにして閉鎖されている道路を走行し、生命を危険にさらさないでください。
			p.para.fs ドライバーおよび同乗者は全員シートベルトを着用してください。法律で義務付けられています。シートベルトは自動車事故の際にあなたを守る最高の安全装置です。子供は身長や体重に応じてシートベルトの着用するか、チャイルドシートを使用することが義務づけられています。
			p.para.fs 道路の真ん中や路傍で停車することは厳禁です。常に安全な場所や出口を探して停車してください。
			p.para.fs 運転中の携帯電話の使用はいかなる形も固く禁じられています。安全に目的地に到着するには、全意識を運転に集中させなければなりません。
			p.para.fs 制限速度によく注意してください。制限速度は人口密集エリアは通常時速50km、住宅地では時速30km、Reykjavik（レイキャビク）の高速道路では時速60～80km です。農村地域および幹線道路の制限速度は時速90km となっていますが、砂利道では時速80km です。これらの数字は道路状況が良好な場合の最高制限速度であり、状況がよくない場合は減速する必要があります。
			p.para.fs アイスランドの法律では、日中も夜間も一年を通してヘッドライトやテールライトを常時点灯することが義務づけられています。
			p.para.fs 砂利道に入るときは、車が制御不能にならないように減速してください。あなたの反対方向から車が来ている場合はできる限り右に寄り、十分に注意してください。 <span class='italic'>これらはあなたが砂利道に近づいていて、減速しなければならないことを示すアイスランド特有の道路標識です。</span>
			p.para.fs 夏のアイスランドは日照時間が長く、日中も夜間も明るいです。この期間は運転中にドライバーが眠ってしまうリスクがあるため、非常に危険です。そのため、運転中にたびたび休憩をとったり、疲れてきたら睡眠をとることが重要です。
			p.para.fs 1車線の橋に入るときは 減速してください。最初に橋に到着した車に通行する権利があります。しかし、そうした場所では毎回一時停止して状況を確認するのが賢明です。アイスランドには1車線の橋が前方にあることを示す特有の道路標識があります。
			p.para.fs 見通しがきかない上り坂の頂上、山やカーブといった車線が分かれていないところは注意して走行してください。道路の一番右端に寄った状態を保ち、減速してください。青地に白い矢印が書かれている標識はあなたが走行する車線を表しています。 <span class='italic'>アイスランドには前方に見通しがきかない上り坂の頂上があり、道路の右端に寄る必要があることを示す特有の道路標識があります。</span>
			p.para.fs 幹線道路を走っていると、道路沿いに家畜がいるのをよく見かけます。その場合は減速してください。家畜の動きは予想できないものであり、道路に走って出てくる可能性があります。
			p.para.fs 環状交差点では、優先通行権は内側の車線にいるドライバーにあります。環状交差点を出る際には、ウィンカーを使用してください。環状交差点内や交差点を出る際には車線変更をしないように気を付けてください。
			p.para.fs アイスランドでは一般道路外を走行することは違法であり、罰金を科せられます。道路および走行可能なトレイルのみを走行してください。アイスランド国内を旅するときは環境に大いに配慮しなければなりません。
			p.para.fs アイスランドではアルコールやその他の酔った状態を引き起こす物質を摂取後の運転は違法行為です。 <span class='italic'>こうした法律を犯した際の罰は厳しいものとなっています。</span>
			p.para.fs.bold このメッセージ内のサウンドプレイヤーの下に役立つ情報のリンクがあります。
			p.para.fs <a class='para-link bold italic' href='http://www.road.is' target='_blank'>www.road.is</a>
			p.para.fs (天候および道路状況)
			p.para.fs <a class='para-link bold italic' href='https://safetravel.is/' target='_blank'>www.safetravel.is</a>
			p.para.fs (旅行計画およびその他の役立つ情報)
			p.para.fs <a class='para-link bold italic' href='http://www.drive.is' target='_blank'>www.drive.is</a>
			p.para.fs (特殊な道路標識や各種映像)
			p.para.fs.bold お聞きいただきありがとうございました。安全で快適なご旅行をお楽しみください。四輪駆動車を使用してアイスランドの高地を旅する予定であれば、以下の高地旅行者向けの重要メッセージをお聞きください。
			p.para.fs アイスランド国内を旅するときは環境に大いに配慮しなければなりません。劇的な季節の移り変わりや、急激な温度変化がごく普通に起きるため、アイスランドの自然は極端にもろいのです！写真を撮る以外のことはせず、また、足跡以外のものを残さないようにして自然を楽しむのがいいでしょう。
			p.para.fs.bold アイスランドでは一般道路外を走行することは違法です。道路および走行可能なトレイルのみを走行してください。これに違反すると高額な罰金を科されたり投獄去れる可能性があります！
			p.para.fs 準備 - ロードマップを確認して、道路とトレイルの場所をチェックしてください。（www.road.is は道路状況を確認するサイトです）
			p.para.fs ビジターセンター、訪問地域の森林警備隊員やスタッフから適切なルートの情報を得ましょう。
			p.para.fs 植物を引っこ抜いたり、苔の上を走行したりすると、自然に対して回復に数十年かかる深刻な損傷を与える原因となります！
			p.para.fs これ以上進むことができない場所に突き当たった時は – U ターンするか、戻り時間を明記したメモを車内に残した上で徒歩で少し先へ進んでください。
			p.para.fs 夏季では高地での運転が非常に人気です。しかし、通常の運転とは異なるスキルが必要となります。低地では急速な状況変化が起こりますが、高地ではさらに早く状況が変化しますし、橋が架かっていない川を通らなければならないことがよくあります。道路は起伏があり、時速40km を超える速度を出してはいけません。十分な時間があるように計画を立ててください。こうした条件から、旅するエリアの情報収集を行い、詳細な 旅行プラン を safetravel.is に残してください。
			.points
				.point(v-for='point in points.日本人')
					.left
						.dash
						.dash
						.dash
					.right.fs {{ point }}
			p.bold.heading お聞きいただきありがとうございました。安全で快適なご旅行をお楽しみください。

		.transcript.gutter.constrainer(v-if='this.$store.state.audioLanguage === "日本人"')
			h1.title 冬の凍結した道路。
			.dashes
				.dash(v-for='n in 5')
			p.para.fs 凍結した道路は極めて多くの事故の原因となっています。アイスランドの道路は冬季はよく凍結します。凍っているように見えなくても凍結していることがあります。減速して、状況に応じた運転をしてください。
			p.para.fs 冬の道路状況は完全に天候次第です。道路状況と併せて天気予報もチェックしてください。山岳地帯では非常に強い突風が吹く可能性がありますので、風速が15 m/秒を超える場合は注意してください。天気予報が5日間分の予報をしていたとしても、必ず毎日確認してください。急な天候の変化はとてもよくあることです！
			p.para.fs 制限速度が時速90km であったとしても、冬の間はそれが適切な数値であるということではありません。必ず状況に応じて減速してください。
			p.para.fs 冬のアイスランドでは全ての場所に行くことができるわけではありません。高地の多くは夏の間だけ行くことができる場所です。道路が閉鎖されている場合はいかなる状況下でも通ることはできません。
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'Listen',
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			points: {
				English: [
					'Start by checking if roads leading to the area are open or not. Check the road conditions.',
					'Make sure to check the weather forecast for the area while keeping in mind that it can change rapidly so prepare for all kinds of weather.',
					'For detailed information check information centers in the area, or check with rangers and wardens.',
					'Make sure that you have the proper knowledge and experience for driving in the highlands.',
					'A small vehicle has no business into the highlands – a 4×4 (4WD) with good ground clearance is required at all times!',
					'Driving in the highlands will inevitably lead you to a river crossing. Keep the following things in mind:',
					'There’s no insurance that covers any damage that can be lead to crossing rivers or any body of water. The risk is yours and damages can cost thousands of euros/dollars.',
					'Do not cross a river unless you are a 100% sure you will be be able to get to the other side – if you’re unsure, wait for the next car to come by and discuss if you can go or not.',
					'The current and depth of the rivers changes rapidly according to glacial melt and precipitation. Keep in mind that rivers can become impassable after a heavy rainfall.',
					'Be sure of the route you are going to take before entering the water.',
					'Put your car in a low-gear and cross the river slowly (5 km/h) and steady – do not switch gears while in the water!',
					'Always try to go down stream as going against the current will increase the risk of water going into your engine.',
					'Even the smallest of rivers have lead to some costly damages simply because of the drivers choosing the wrong routes or going too fast.',
					'Do NOT enter the river where it seems calm – this is a sign of depth and is not the ideal spot to cross.',
					'Do not be fooled by big modified vehicles. They can cross the rivers at higher speeds because they are equipped with a snorkel – allowing the air intake of the engine to be at a higher point than on normal vehicles.'
				],
				Italiano: [
					'Inizia controllando se la strada è o meno aperta. Controlla le condizioni stradali.',
					'Controlla le previsioni meteorologiche dell’area e ricorsa che le condizioni possono cambiare rapidamente. Sii preparato a tutti i tipi di condizioni meteo.',
					'Per informazioni dettagliate, rivolgiti ai centri informazioni dell’area, ai ranger e ai guardiani.',
					'Assicurati di avere tutte le competenze e capacità per guidare lungo gli altopiani.',
					'Non puoi attraversare gl altopiani su una piccola utilitaria - è necessario sempre un veicolo 4 x 4 (4WD)!',
					'Quando attraversi gli altopiani dovrai necessariamente guadare almeno un fiume. Ricordati sempre che:',
					'nessuna polizza assicurativa copre i danni causati dal guado dei fiumi o dall’acqua in generale. Il rischio è solo tuo e i danni potrebbero costarti migliaia di euro.',
					'Non guadare un fiume se non sei sicuro al 100% di essere in grado di raggiungere l’altra sponda - se non sei sicuro aspetta l’arrivo della prossima autovettura e cerca di capire se sei in grado o meno.',
					'La corrente e la profondità dei fiumi cambia rapidamente in base allo scioglimento del ghiaccio e delle precipitazioni. Ricorda che dopo una pioggia copiosa i fiumi possono diventare inguadabili.',
					'Prima di entrare in acqua controlla il percorso da seguire.',
					'Innesta una marcia bassa e guada il fiume lentamente (5 km/h) - non cambiare marcia quando sei in acqua!',
					'Segui sempre il corso della corrente perché se guidi contro la corrente rischieresti di far finire l’acqua nel motore.',
					'Anche i fiumi più piccoli sono stati in grado di causare danni costosi semplicemente perché il conducente ha scelto il percorso sbagliato o la velocità era eccessiva.',
					'NON entrare nel parti del fiume che sembrano più calme perché sono le più profonde e le meno indicate per il guado.',
					'Non lasciarti ingannare dai grandi veicoli modificati. Possono guadare i fiumi a velocità elevata perché dispongono di un così detto periscopio che permette di aspirare l’aria nel motore da un punto più alto rispetto ai modelli convenzionali.'
				],
				Deutsche: [
					'Beginnen Sie mit der Überprüfung, ob die Zufahrtsstraßen zum gewünschten Gebiet geöffnet sind. Überprüfen Sie die Straßenbedingungen.',
					'Überprüfen Sie die Wettervorhersage für das Gebiet. Denken Sie daran, dass sich das Wetter schnell ändern kann. Bereiten Sie sich auf alle möglichen Wetterarten vor.',
					'Für detaillierte Informationen besuchen Sie die Informationszentren in der Umgebung oder wenden Sie sich an Ranger und Aufseher.',
					'Stellen Sie sicher, dass Sie das richtige Wissen und die notwendige Erfahrung für das Fahren im Hochland haben.',
					'Ein kleines Fahrzeug hat im Hochland nichts zu suchen - ein 4 × 4 (4WD) mit guter Bodenfreiheit ist immer erforderlich!',
					'Fahren im Hochland führt Sie unweigerlich zu einer Flussdurchquerung. Beachten Sie Folgendes:',
					'Es existiert keine Versicherung zur Deckung von Schäden, die bei Durchquerungen von Flüssen und Gewässern jeglicher Art entstehen. Das Risiko liegt bei Ihnen und die Schadenshöhe kann Tausende Euro/Dollar betragen.',
					'Durchqueren Sie einen Fluss nur, wenn Sie sich 100% sicher sind, die andere Seite gefahrlos zu erreichen - wenn Sie nicht sicher sind, warten Sie auf das nächste Auto. Sie können dann gemeinsam besprechen, ob eine Durchfahrung möglich ist.',
					'Die Strömungsverhältnisse und die Flusstiefe verändern sich je nach Gletscherschmelze und Niederschlag schnell. Bedenken Sie, dass Flüsse nach Starkregen unpassierbar werden können.',
					'Legen Sie sich eine genaue Streckenführung der Durchquerung zurecht und vergewissern Sie sich über deren Passierbarkeit, bevor Sie ins Wasser fahren.',
					'Wählen Sie im Auto einen niedrigen Gang und durchqueren Sie den Fluss langsam (5 km/h) und gleichmäßig - Nicht im Wasser schalten!',
					'Versuchen Sie immer das Gewässer in Flussrichtung zu durchqueren, da sich das Risiko eines Wassereinbruchs in den Motor bei Fahrtrichtung gegen den Strom erhöht.',
					'Die Durchquerung kleinster Flüsse hat bereits zu einigen kostspieligen Schäden geführt, nur weil die Fahrer eine falsche Streckenführung gewählt haben oder zu schnell fuhren.',
					'Durchqueren Sie den Fluss NICHT an der Stelle, wo das Wasser ruhig zu sein scheint - das ist ein Zeichen von Tiefe und somit nicht der ideale Ort zur Durchquerung.',
					'Lassen Sie sich nicht von den Fähigkeiten anderer großer modifizierter Fahrzeuge täuschen. Diese können die Flüsse mit höheren Geschwindigkeiten überqueren, weil sie mit einem Schnorchel ausgestattet sind - der Lufteinlass des Motors befindet sich an einem höheren Punkt als bei normalen Fahrzeugen.'
				],
				Nederlands: [
					'Begin met het controleren of de wegen naar het gebied open zijn of niet. Controleer de wegomstandigheden.',
					'Bekijk ook zeker het weerbericht voor het gebied en houd daarbij rekening met het feit dat dit snel kan veranderen. Bereid u dus voor op elk type weer.',
					'Voor meer informatie kunt u terecht bij de informatiecentra in de omgeving of bij parkwachters of bewakers.',
					'Zorg ervoor dat u voldoende kennis en vaardigheid hebt om in de hooglanden te rijden.',
					'Ga niet met een kleine auto naar de hooglanden. Een vierwieler die hoog op zijn wielen staat is te allen tijde verplicht!',
					'Tijdens het rijden door de hooglanden komt u onherroepelijk rivieren tegen die u moet oversteken. Houd het volgende in gedachte:',
					'Geen enkele verzekering dekt schade opgelopen door het oversteken van een rivier of wat voor type water dan ook. Het risico ligt geheel bij u, de schade kan oplopen tot in de duizenden euro\'s.',
					'Steek geen rivier over als u niet 100% zeker bent dat u de overkant kunt bereiken. Bent u niet zeker, wacht dan op de volgende auto en vraag of uw auto geschikt is of niet.',
					'De stroming en diepte van de rivier kan snel veranderen door het smelten van ijs en neerslag. Vergeet niet dat een rivier onbegaanbaar kan zijn na zware regenval.',
					'Zorg ervoor dat u weet welke route u neemt voor het oversteken van de rivier.',
					'Zet uw wagen in een lage versnelling en steek de rivier langzaam over (5 km/u). Schakel niet tijdens het oversteken!',
					'Tracht altijd stroomafwaarts de rivier over te steken, want door tegen de stroming in rijden kan er water in uw motor komen.',
					'Zelfs de kleinste riviertjes hebben tot kostbare schade geleid doordat chauffeurs simpelweg de verkeerde route namen of te snel gingen.',
					'Steek de rivier NIET over waar deze kalm lijkt, dit is een teken van diepte en is niet de ideale plaats om over te steken.',
					'Laat u niet verrassen door grote aangepaste voertuigen. Deze kunnen rivieren met een hogere snelheid oversteken omdat ze uitgerust zijn met een snorkel, waardoor de luchtinlaat van de motor op een hogere plek zit dan bij gewone voertuigen.'
				],
				Española: [
					'Empiece comprobando si las carreteras que llegan hasta la zona están abiertas. Compruebe las condiciones de la carretera.',
					'Asegúrese de consultar la previsión meteorológica de la zona que va a visitar, teniendo en cuenta al mismo tiempo que esta puede cambiar en cualquier momento. Prepárese para todo tipo de climas.',
					'Si quiere información más detallada, acuda a los centros de información de la zona o consulte a los guardas forestales o vigilantes.',
					'Asegúrese de que cuenta con los conocimientos y la experiencia necesarios para conducir por las Tierras Altas.',
					'No puede entrar en las Tierras Altas con un vehículo pequeño. Es obligatorio conducir un 4x4 (tracción a las 4 ruedas) que esté a una altura adecuada del suelo.',
					'Cuando se conduce por las Tierras Altas es inevitable tener que cruzar algún río. Tenga en cuenta lo siguiente:',
					'No hay ningún seguro que cubra cualquier tipo de daño resultante de cruzar ríos o masas de agua. Usted asume el riesgo y la reparación de los daños puede ascender a miles de euros / dólares.',
					'No cruce un río a no ser que esté 100 % seguro de que será capaz de llegar al otro lado. Si no está seguro, espere hasta que llegue otro coche e infórmese de si puede o no atravesarlo.',
					'La corriente y la profundidad de los ríos pueden cambiar con mucha rapidez, en función del deshielo glaciar y las precipitaciones. Recuerde que después de lluvias muy fuertes puede ser imposible atravesar los ríos.',
					'Tenga clara qué ruta va a tomar antes de introducirse en el agua.',
					'Ponga su coche en una marcha baja y atraviese el río de manera lenta (5 km/h) y firme. No cambie de marcha mientras está en el agua.',
					'Intente ir siempre en la dirección de la corriente. Ir contra la corriente aumentará el riesgo de que entre agua en el motor.',
					'Hasta los ríos más pequeños pueden causar daños que salen muy caros, simplemente porque el conductor eligió la ruta incorrecta o iba demasiado rápido.',
					'NO entre en un río cuyas aguas parezcan estar muy tranquilas. Esto es una señal de profundidad por lo que no es el mejor punto para cruzar.',
					'No se deje engañar por vehículos de grandes dimensiones que han sido modificados. Estos pueden atravesar los ríos a más velocidad porque están equipados con un esnórquel, que permite que el aire que entra en el motor esté en un punto más alto que en los vehículos normales.'
				],
				Française: [
					'Commencez par vérifier si les routes qui mènent à la zone sont ouvertes ou non. Vérifiez l’état des routes.',
					'Veillez à consulter les prévisions météo pour la région tout en ayant à l’esprit que le temps peut changer rapidement, et préparez-vous à tous types de conditions météo.',
					'Pour obtenir des informations détaillées, adressez-vous aux centres d’information locaux ou consultez les gardes-forestiers et les gardes-chasses.',
					'Assurez-vous de disposer de connaissances et de l’expérience suffisantes pour conduire dans les hautes terres.',
					'Un petit véhicule n’a rien à faire dans les hautes terres : un 4×4 (4 roues motrices) doté d’une bonne garde au sol est impératif partout !',
					'Conduire dans les hautes terres vous mènera inévitablement à franchir une rivière. Gardez ce qui suit à l’esprit :',
					'Aucune assurance ne couvre les dommages qui pourraient survenir en cas de franchissement de rivières ou d’étendues d’eau. Le risque est à votre charge et les dommages peuvent s’élever à plusieurs milliers d’euros ou de dollars.',
					'Ne traversez pas une rivière à moins d’être absolument sûrs de pouvoir atteindre l’autre rive. En cas de doute, attendez l’arrivée d’une autre voiture et discutez des possibilités.',
					'Le courant et la profondeur des rivières varient rapidement selon la fonte des glaces et les précipitations. Gardez à l’esprit que les rivières peuvent devenir infranchissables après de fortes précipitations.',
					'Soyez sûrs de la trajectoire que vous allez suivre avant d’entrer dans l’eau.',
					'Passez une petite vitesse et traversez la rivière au pas (5 km/h) ; ne changez pas de vitesse dans l’eau !',
					'Essayez toujours d’aller en aval car aller en amont augmente le risque de submerger votre moteur.',
					'Même la plus petite des rivières provoque de graves dommages, simplement en raison d’une mauvaise trajectoire ou d’une vitesse trop élevée.',
					'NE VOUS ENGAGEZ PAS dans un passage calme : c’est signe que la rivière y est profonde et que ce n’est pas l’endroit idéal pour traverser.',
					'Ne vous fiez pas aux gros véhicules modifiés. Ils peuvent franchir les rivières à vitesse plus importante parce qu’ils sont équipés d’un snorkel qui permet de surélever l’arrivée d’air par rapport aux véhicules normaux.'
				],
				Polskie: [
					'Na początek sprawdź, czy drogi prowadzące do wybranego obszaru są otwarte. Sprawdź warunki na drodze.',
					'Sprawdź prognozę pogody dla danego obszaru, ale pamiętaj, że pogoda może się szybko zmienić, dlatego przygotuj się na różne warunki.',
					'Uzyskaj szczegółowe informacje, kontaktując się z informacją turystyczną, leśniczymi lub przedstawicielami straży.',
					'Upewnij się, że posiadasz wiedzę i doświadczenie potrzebne podczas jazdy po górach.',
					'Mały pojazd nie sprawdzi się w górach – wybierz samochód z napędem 4x4 (4WD) i dużym prześwitem.',
					'Podczas jazdy po terenach górskich z pewnością natrafisz na konieczność pokonania rzeki. Oto kilka kwestii, o których należy pamiętać w takich sytuacjach:',
					'Żadne ubezpieczenie nie pokrywa szkód powstałych w wyniku przejazdu przez rzekę lub inny akwen. Naprawa uszkodzeń może kosztować tysiące euro/dolarów. Ryzyko w tym zakresie podejmujesz na własną odpowiedzialność.',
					'Nie przejeżdżaj przez rzekę w bród, jeśli nie masz całkowitej pewności, że jesteś w stanie dotrzeć na drugi brzeg – w razie wątpliwości poczekaj na przyjazd innego pojazdu i porozmawiaj z jego kierowcą.',
					'Prędkość wody i głębokość rzek zmienia się szybko w zależności od prędkości topnienia lodu i ilości opadów. Pamiętaj, że po obfitych opadach przejazd przez rzekę może być niemożliwy.',
					'Zanim wjedziesz do wody wyznacz trasę, którą będziesz jechać.',
					'Wrzuć niski bieg i przejeżdżaj przez rzekę powoli (5 km/h) i w równym tempie – nie zmieniaj biegu, gdy pojazd znajduje się w wodzie!',
					'Staraj się zawsze podążać z prądem; jazda pod prąd zwiększa ryzyko przedostania się wody do silnika.',
					'Nawet niewielkie rzeki mogą spowodować kosztowne szkody, jeśli kierowca wybierze nieodpowiednią trasę lub będzie jechać zbyt szybko.',
					'NIE wjeżdżaj do rzeki w miejscu, w którym woda wydaje się płynąć spokojnie – oznacza to, że w tym miejscu rzeka ma dużą głębokość i przejazd nie jest wskazany.',
					'Nie daj się zwieść dużym, zmodyfikowanym pojazdom. Mogą przejeżdżać przez rzekę z dużą prędkością, ponieważ wlot powietrza do silnika znajduje się w nich wyżej niż w standardowych pojazdach.'
				],
				Norsk: [
					'Begynn med å sjekke om veiene til området er åpne eller ikke. Sjekk veiforholdene.',
					'Ikke glem å sjekke værmeldingen for området og husk at det kan endre seg raskt, så vær forberedt på all slags vær.',
					'For detaljert informasjon, kan du sjekke informasjonssentrene i området, eller høre med lokale skogvoktere og oppsynsmenn.',
					'Sørg for å ha tilstrekkelig med kunnskap og erfaring før du legger ut på en kjøretur i høylandet.',
					'Et lite kjøretøy har ingenting i høylandet å gjøre – det er alltid nødvendig med et firehjulsdrevet kjøretøy med god bakkeklaring!',
					'Hvis du kjører i høylandet, må du før eller siden krysse en elv. Husk følgende:',
					'Ingen forsikringer dekker skade som kommer av å krysse elver eller vannmasser. Du tar hele risikoen, og skadene kan koste tusenvis av euro/dollar.',
					'Kryss ikke en elv med mindre du er 100 % sikker på at du klarer å komme deg over på den andre siden – hvis du er usikker, kan du vente på neste bil og diskutere om det lar seg gjøre.',
					'Strømmen og dybden i elven endrer seg raskt avhengig av smeltende isbreer og nedbør. Husk at elver kan bli uoverkommelige etter mye nedbør.',
					'Bestem deg for hvilken rute du vil ta før du kjører ned i vannet.',
					'Sett bilen i et lavt gir og kryss elven sakte (5 km/t) og jevnt – bytt ikke gir mens du er i vannet!',
					'Prøv alltid å kjøre nedstrøms, siden det å kjøre mot strømmen øker risikoen for at du får vann i motoren.',
					'Selv de minste elvene kan forårsake kostbare skader simpelthen fordi sjåføren velger feil rute eller kjører for fort.',
					'Kjør IKKE ned i elven der den virker rolig – dette er et tegn på at elven er dyp og er ikke et ideelt sted å krysse elven på.',
					'La deg ikke lure av store, modifiserte kjøretøy. De kan krysse elven ved høyere hastigheter fordi de er utstyrt med en snorkel – som hever luftinntaket på motoren i forhold til vanlige kjøretøy.'
				],
				中文: [
					'首先查询前往目的地的道路是否正常开放。查询路况。',
					'确保查询目的地的天气预报，并注意天气可能发生骤变，因此要为各种天气条件做好准备。',
					'如需了解详细信息，请访问当地的信息中心，或者向护林员和管理员咨询。',
					'确保自己具备在高原地区驾驶车辆所需的适当知识和经验。',
					'小型车辆看不到高原风光 – 您始终需要驾驶底盘较高的四驱 (4WD) 车辆！',
					'在高原地区驾车无可避免地要穿越河流。请牢记以下信息：',
					'没有任何险种会承保因穿越河流或水体而导致的车损。您需要自担风险，且此类车损可能高达上千欧元/美元。',
					'切勿穿越您不能 100% 确信可以到达对岸的河流。如果不确定，请等待路过车辆，向其讨教是否可以穿越河流。',
					'河流的水流和深度可能因冰川融化以及降雨而发生大幅变化。请注意：河流在暴雨过后可能变得无法穿越。',
					'务必在涉水之前规划好前进路线。',
					'将车辆挂低档，并慢速 (5 km/h)、稳定地穿越河流，切勿在水中进行换档操作！',
					'始终尝试顺流前进，因为逆流前进会增大引擎进水的风险。',
					'当驾驶员路线选择不当或者车速过快时，即便是很窄的河流也可能导致严重车损。',
					'切勿进入看似平静的河段，风平浪静往往表示水很深，因此并非理想的穿越点。',
					'切勿被经过大量改装的车辆所迷惑。它们之所以可以高速穿越河流，是因为配备了潜水通气管，其引擎吸气点高于普通车辆。'
				],
				日本人: [
					'まずは行きたいエリアへと続く道路が閉鎖されていないかどうかを確認することから始めてください。道路状況 をチェックしてください。',
					'必ずエリアの 天気予報 を確認しつつ、急速な天候の変化があることも念頭に置いてすべての天候に備えてください。',
					'詳細情報については、当該エリアの情報センターでチェックするか、森林警備隊員や管理者に問い合わせてください。',
					'高地を運転するにあたって適切な知識および経験があることを必ず確認してください。',
					'高地では小型車両の出る幕はありません。良好な地上高の四輪駆動車（4WD）が常に必須です！',
					'高地を運転する場合は必然的に川を渡ることになります。以下のことに留意してください。',
					'川を渡ったり、水によって受ける損傷を保証する保険はありません。何千ユーロ/ドルかかる可能性がある損傷についてはあなた自身がリスクを負うこととなります。',
					'100％対岸に行けるという確信がない場合は川を渡らないでください - 確信がない場合は、他の車が来るのを待って先に進めるかどうかを相談してください。',
					'川の流れや深さは融氷や降雨量によって急激に変化します。豪雨の後は川が通り抜け不可能になる場合がありますのでご注意ください。',
					'水に入る前に、進むルートを確認してください。',
					'ギアをローに入れて、ゆっくりと（時速5km）一定の速度で川を渡ってください。水に入っている間はギアを変えないでください！',
					'常に川下に向かって進むようにしてください。流れに逆らうと水がエンジンに浸入するリスクが増加します。',
					'ドライバーが誤ったルートを選択したり、進むのが早すぎると、最も小さな川であっても高額な費用が掛かる損傷につながる可能性があります。',
					'川の静かに見える場所は水深があるために穏やかであることがあります。川を渡る場所としては適していません。',
					'大きい改造車両に惑わされてはいけません。こうした車両は早い速度で川を渡れますが、これはシュノーケルを搭載しているためです。エンジンの空気の取り込み口を通常の車両よりも高い位置にしています。'
				]
			},
			audioLang: [
				{
					title: 'English',
					img: '/flags/english.svg'
				},
				{
					title: 'Italiano',
					img: '/flags/italian.svg'
				},
				{
					title: 'Deutsche',
					img: '/flags/german.svg'
				},
				{
					title: 'Nederlands',
					img: '/flags/dutch.svg'
				},
				{
					title: 'Española',
					img: '/flags/spanish.svg'
				},
				{
					title: 'Française',
					img: '/flags/french.svg'
				},
				{
					title: 'Polskie',
					img: '/flags/polish.svg'
				},
				{
					title: 'Norsk',
					img: '/flags/norwegian.svg'
				},
				{
					title: '中文',
					img: '/flags/chinese.svg'
				},
				{
					title: '日本人',
					img: '/flags/japanese.svg'
				}
			],
			blockClose: false,
			swap: false,
			showLess: false,
			sound: null,
			currentFile: 0,
			currentPosition: 0,
			files: {
				Española: [
					{
						duration: '06:22',
						title: 'General',
						url: '/audio/spanish/Spaenska-General.mp4'
					},
					{
						duration: '05:26',
						title: 'Highland Driving',
						url: '/audio/spanish/Spaenska-Highlands.mp4'
					},
					{
						duration: '01:31',
						title: 'Winter Driving',
						url: '/audio/spanish/Spaenska_vetur.mp4'
					}
				],
				Polskie: [
					{
						duration: '04:41',
						title: 'General',
						url: '/audio/polish/Polska-_General.mp4'
					},
					{
						duration: '03:58',
						title: 'Highland Driving',
						url: '/audio/polish/Polska-_Highland.mp4'
					},
					{
						duration: '01:02',
						title: 'Winter Driving',
						url: '/audio/polish/Polska-vetur_4.mp4'
					}
				],
				Norsk: [
					{
						duration: '05:37',
						title: 'General',
						url: '/audio/norwegian/Norska-General.mp4'
					},
					{
						duration: '04:55',
						title: 'Highland Driving',
						url: '/audio/norwegian/Norska-Highland.mp4'
					},
					{
						duration: '01:10',
						title: 'Winter Driving',
						url: '/audio/norwegian/Norska-vetur_6.mp4'
					}
				],
				日本人: [
					{
						duration: '05:48',
						title: 'General',
						url: '/audio/japanese/Japan-_general.mp4'
					},
					{
						duration: '05:32',
						title: 'Highland Driving',
						url: '/audio/japanese/Japan-_highlands.mp4'
					},
					{
						duration: '01:29',
						title: 'Winter Driving',
						url: '/audio/japanese/Japanska-vetur_3.mp4'
					}
				],
				Deutsche: [
					{
						duration: '05:10',
						title: 'General',
						url: '/audio/german/Thyska-_General.mp4'
					},
					{
						duration: '04:31',
						title: 'Highland Driving',
						url: '/audio/german/Thyska-highland.mp4'
					},
					{
						duration: '01:03',
						title: 'Winter Driving',
						url: '/audio/german/Thyska-vetur_1.mp4'
					}
				],
				Française: [
					{
						duration: '04:31',
						title: 'General',
						url: '/audio/french/franska-__General.mp4'
					},
					{
						duration: '03:30',
						title: 'Highland Driving',
						url: '/audio/french/franska-highland.mp4'
					},
					{
						duration: '00:51',
						title: 'Winter Driving',
						url: '/audio/french/Franska-vetur_2.mp4'
					}
				],
				Nederlands: [
					{
						duration: '05:03',
						title: 'General',
						url: '/audio/dutch/Hollenska_General.mp4'
					},
					{
						duration: '04:11',
						title: 'Highland Driving',
						url: '/audio/dutch/Hollenska-highland.mp4'
					},
					{
						duration: '01:04',
						title: 'Winter Driving',
						url: '/audio/dutch/Hollenska-vetur.mp4'
					}
				],
				中文: [
					{
						duration: '07:51',
						title: 'General',
						url: '/audio/chinese/Kinverska-General.mp4'
					},
					{
						duration: '06:04',
						title: 'Highland Driving',
						url: '/audio/chinese/Kinverska-Highland.mp4'
					},
					{
						duration: '01:34',
						title: 'Winter Driving',
						url: '/audio/chinese/Kinverska-vetur_5.mp4'
					}
				],
				English: [
					{
						duration: '01:52',
						title: 'Driving in Iceland - P1',
						url: '/audio/english/1 - Driving in Iceland - Part 1.mp4'
					},
					{
						duration: '01:38',
						title: 'Driving in Iceland - P2',
						url: '/audio/english/2 - Driving in Iceland - Part 2.mp4'
					},
					{
						duration: '00:50',
						title: 'Driving in Iceland - P3',
						url: '/audio/english/3 - Driving in Iceland - Part 3.mp4'
					},
					{
						duration: '03:42',
						title: 'Highland Driving',
						url: '/audio/english/4 - Highland driving in Iceland.mp4'
					},
					{
						duration: '01:29',
						title: 'Winter Driving',
						url: '/audio/english/Winter Driving in Iceland.mp3'
					}
				],
				Italiano: [
					{
						duration: '02:04',
						title: 'Driving in Iceland - P1',
						url: '/audio/italian/italska-01.mp4'
					},
					{
						duration: '01:46',
						title: 'Driving in Iceland - P2',
						url: '/audio/italian/italska-02.mp4'
					},
					{
						duration: '00:53',
						title: 'Driving in Iceland - P3',
						url: '/audio/italian/italska-03.mp4'
					},
					{
						duration: '03:43',
						title: 'Highland Driving',
						url: '/audio/italian/italska-Highland.mp4'
					},
					{
						duration: '01:04',
						title: 'Winter Driving',
						url: '/audio/italian/italska-Winter.mp4'
					}
				]
			}
		};
	},
	computed: {
		progress: function () {
			let position = 0;
			if (this.sound) {
				position = this.currentPosition;
			}
			return { width: `${position}%` };
		}
	},
	beforeDestroy: function () {
		this.clearSound();
	},
	methods: {
		setLang: function (lang) {
			this.$store.state.audioLanguage = lang;
			this.clearSound();
			setTimeout(() => {
				this.swap = false;
			}, 200);
		},
		closeSwap: function () {
			if (!this.blockClose) {
				this.swap = false;
			}
		},
		showLanguageSwap: function () {
			this.blockClose = true;
			this.swap = true;
			setTimeout(() => {
				this.blockClose = false;
			}, 300);
		},
		setPlay: function (index) {
			this.currentFile = index;
			if (this.sound) {
				this.clearSound();
			}
			this.play();
		},
		clearSound: function () {
			if (this.sound) {
				this.sound.stop();
				this.sound.unload();
				this.currentPosition = 0;
			}
			this.sound = null;
		},
		playNext: function () {
			if (this.currentFile + 1 > this.files[this.$store.state.audioLanguage].length - 1) {
				this.clearSound();
				this.currentFile = 0;
			} else {
				this.clearSound();
				this.currentFile++;
				this.play();
			}
		},
		playPrev: function () {
			if (this.currentFile - 1 < 0) {
				this.currentPosition = 0;
				this.sound.seek(0);
			} else {
				this.clearSound();
				this.currentFile--;
				this.play();
			}
		},
		jumpForwards: function () {
			if (this.sound) {
				if (this.sound.seek() + 15 > this.sound.duration()) {
					this.playNext();
				} else {
					this.sound.seek(this.sound.seek() + 15);
					this.currentPosition = this.sound.seek();
				}
			}
		},
		jumpBackwards: function () {
			if (this.sound) {
				if (this.sound.seek() < 15) {
					this.sound.seek(0);
				} else {
					this.sound.seek(this.sound.seek() - 15);
					this.currentPosition = this.sound.seek();
				}
			}
		},
		play: function () {
			// Documentation: https://github.com/goldfire/howler.js#examples
			if (!this.sound) {
				this.sound = new window.Howl({
					src: [this.files[this.$store.state.audioLanguage][this.currentFile].url]
				});
				this.sound.play();
			} else if (!this.sound.playing()) {
				this.sound.play();
			} else {
				this.sound.pause();
			}
			this.sound.on('end', () => {
				this.playNext();
			});
			setInterval(() => {
				if (this.sound) {
					this.currentPosition = 100 * (this.sound.seek() / this.sound.duration());
				}
			}, 1);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.listen-transcript {
	.transcript {
		.transcript-title {
			text-align: left;
			font-family: Montserrat;
			font-size: 34px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: 2.8px;
			color: #00476e;
		}
		.points {
			margin-top: 60px;
			margin-bottom: 60px;
			.point {
				display: grid;
				grid-template-columns: 64px 1fr;
				grid-column-gap: 26px;
				margin-bottom: 16px;
				.left {
					transform: translate3d(0px, 15px, 0px);
					margin-bottom: 24px;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-column-gap: 4px;
					width: 32px;
					.dash {
						height: 3px;
						width: 13.25px;
						background-color: #95F9E3;
					}
				}
				.right {
					text-align: left;
					color: #000000;
					font-family: Montserrat;
					font-size: 18px;
					letter-spacing: 1.75px;
					line-height: 34px;
				}
			}
		}
		margin-top: 100px;
		.heading {
			margin: 0px;
			color: #000000;
			font-family: Montserrat;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 2px;
			line-height: 36px;
			@media screen and (max-width: 1220px) {
				font-size: 18px;
			}
		}
		.para-link {
			color: black;
			text-decoration: none;
		}
		.bold {
			font-weight: bold;
		}
		.italic {
			font-style: italic;
		}
	}
	.listen {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 158px;
		@media screen and (max-width: 1220px) {
			grid-template-columns: initial;
			grid-template-areas:
			'top'
			'bottom';
		}
		.left {
			@media screen and (max-width: 1220px) {
				grid-area: bottom;
				margin-top: 48px;
			}
			.audio-player {
				box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
				padding-top: 2px;
				padding-bottom: 38px;
				width: 504px;
				position: relative;
				background-color: white;
				@media screen and (max-width: 1220px) {
					top: 0px;
					left: 0px;
				}
				@media screen and (max-width: 650px) {
					box-shadow: 0px 3px 5px 1px rgba(177, 131, 131, 0.00);
					width: calc(~'100% + 48px');
					left: -24px;
					margin-left: auto;
					margin-right: auto;
				}
				.list {
					width: 375px;
					margin-left: auto;
					margin-right: auto;
					@media screen and (max-width: 650px) {
						width: 100%;
					}
					.item {
						height: 48px;
						position: relative;
						width: 100%;
						border-left: 4px solid rgba(0,0,0,0);
						transition: .3s;
						.overlay {
							transition-timing-function: linear;
							position: absolute;
							top: 0px;
							left: 0px;
							height: 100%;
							width: 100%;
							background-color: rgba(140, 249, 227, .3);
						}
						.content {
							display: grid;
							grid-template-columns: 1fr 110px;
							&.even {
								background-color: #FAFAFA;
							}
							.title {
								line-height: 48px;
								text-align: left;
								font-size: 12px;
								letter-spacing: 1px;
								transition: .3s;
								&.bold {
									font-weight: 600;
								}
							}
							&.last {
								.title {
									color: #CCCCCC;
									font-size: 14px;
									font-weight: 600;
									letter-spacing: 1.17px;
									line-height: 48px;
									text-align: right;
								}
								width: 100%;
								background-color: #FAFAFA;
								display: block;
							}
							.duration {
								color: #CCCCCC;
								font-size: 12px;
								font-weight: 500;
								letter-spacing: 1px;
								line-height: 48px;
								text-align: right;
							}
						}
						&:hover {
							border-left: 4px solid #00476e;
							.content {
								.title {
									transform: translate3d(3px, 0px, 0px);
								}
							}
						}
					}
				}
				.divider {
					position: relative;
					width: 375px;
					height: 2px;
					background-color: #CBCBCB;
					margin-top: 37px;
					margin-left: auto;
					margin-right: auto;
					@media screen and (max-width: 650px) {
						width: 100%;
					}
				}
				.player {
					margin-top: 40px;
					position: relative;
					.controls {
						position: relative;
						left: 50%;
						transform: translate3d(-50%, 0px, 0px);
						width: 256px;
						display: grid;
						grid-template-columns: 26px 26px 72px 26px 26px;
						grid-column-gap: 20px;
						@media screen and (max-width: 650px) {
							width: 208px;
							grid-template-columns: 14px 14px 72px 14px 14px;
						}
						.item {
							position: relative;
							img {
								width: 100%;
								position: relative;
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0px);
								transition: .3s;
								&:hover {
									transform: translate3d(-50%, -50%, 0px) scale(1.03);
									&.forward-fifteen {
										transform: translate3d(-50%, -50%, 0px) scale(1.03) rotate3d(0, 0, 1, 5deg);
									}
									&.back-fifteen {
										transform: translate3d(-50%, -50%, 0px) scale(1.03) rotate3d(0, 0, 1, -5deg);
									}
								}
							}
							&.play-pause {
								img {
									visibility: hidden;
									&.active {
										visibility: initial;
									}
									position: absolute;
								}
							}
						}
					}
				}
				.language-selection {
					margin-top: 47px;
					@media screen and (min-width: 1240px) {
						margin-top: 30px;
					}
					display: grid;
					grid-template-columns: repeat(10, auto);
					grid-column-gap: 18px;
					padding-bottom: 16px;
					width: 80px;
					position: relative;
					width: 375px;
					margin-left: auto;
					margin-right: auto;
					@media screen and (max-width: 650px) {
						width: 100%;
						box-sizing: border-box;
						padding-left: 16px;
						padding-right: 16px;
						grid-template-columns: repeat(5, auto);
						grid-row-gap: 18px;
					}
					.lang-item {
						.underline {
							height: 2px;
							width: 80%;
							margin-left: auto;
							margin-right: auto;
							background-color: rgba(0, 0, 0, 0);
							transition: .3s;
						}
						img {
							margin-bottom: 2px;
							height: auto;
							width: 100%;
							transition: .3s;
							max-width: 36px;
						}
						&.active {
							.underline {
								background-color: #95f9e3;
							}
							img {
								transform: scale(1.4);
							}
						}
					}
				}
			}
		}
		.right {
			@media screen and (max-width: 1220px) {
				margin-top: -100px;
				grid-area: top;
			}
			.title {
				max-width: 632px;
				height: 38px;
				color: #00476e !important;
			}
			.para {
				margin-top: 48px;
				max-width: 504px;
				height: 207px;
				text-align: left;
				@media screen and (max-width: 1220px) {
					height: auto;
				}
			}
			.button {
				display: grid;
				grid-template-columns: 80% 20%;
				height: 48px;
				width: 333px;
				background-color: #FFFFFF;
				box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
				border-left: 4px solid #AF7595;
				.text {
					text-align: left;
					color: black;
					height: 30px;
					width: 223px;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 1.5px;
					line-height: 48px;
					padding-left: 32px;
				}
				.arrow {
					left: 100%;
					position: relative;
					top: 50%;
					transform: translate3d(calc(~'-100% - 20px'), -50%, 0px);
				}
			}
		}
	}
}
</style>
