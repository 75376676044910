<template lang='pug'>
	.nav
		.inner
			.content.constrainer.gutter
				.logo
					img.clickable(src='@/assets/nav/logo.svg' @click='routeTo("/")')
				.alerts.clickable(@click='openAlerts')
					img.bell-icon(src='@/assets/bell-icon.svg')
					span.count(v-if='alerts.length > 0')
					span.alert Alerts
					span.caret
						img(src='@/assets/white-caret.png' :class='{ spin: showAlerts }')
				.burger
					.main-burger.clickable(:class='{ active: !showMenu }' @click='openMenu')
						.top
						.mid
							.mid-one
							.mid-two
							.mid-three
						.bot
					.octa.clickable(:class='{ active: showMenu }' @click='closeMenu')
						img.wrapper(src='@/assets/nav/close.png')
						.cross
							.criss
							.crass
		.question-thing(:class='{ active: $store.state.questionsActive }')
			.backdrop
				.modal-view
					.question-selection(v-show='!$store.state.showInterm && !$store.state.showResults' :class='{ fadeout: $store.state.fadeoutQuestions }')
						.top
							img.clickable.close(src='@/assets/icons/close-modal.svg' @click='$store.state.questionsActive = false;')
						.main
							.title-picture(:class='{ usepicture: questions[$store.state.activeQuestion].picture }')
								img.image(:src='questions[$store.state.activeQuestion].picture')
								.title {{ questions[$store.state.activeQuestion].question }}
							.options
								.item.clickable(v-for='option, index in questions[$store.state.activeQuestion].options' @click='selectQuestion(index)' :class='{ active: questions[$store.state.activeQuestion].answer === index }')
									.text {{ option }}
									.icon-box
										img.icon(src='@/assets/icons/arrow-blue.svg')
						.bottom
							.submit.clickable(@click='displayInterm') Submit
							.progress
								.dash(v-for='i, index in 10' :class='{ active: index <= $store.state.activeQuestion }')
					.interm(v-show='$store.state.showInterm && !$store.state.showResults' :class='{ active: $store.state.fadeinInterm }')
						.top
							img.clickable.close(src='@/assets/icons/close-modal.svg' @click='$store.state.questionsActive = false;')
						.result-interm.correct(v-if='questions[$store.state.activeQuestion].answer === questions[$store.state.activeQuestion].correct') Correct!
						.result-interm.wrong(v-else) Wrong!
						.para(v-if='questions[$store.state.activeQuestion].answer === questions[$store.state.activeQuestion].correct') {{ questions[$store.state.activeQuestion].correctMessage }}
						.para(v-else) {{ questions[$store.state.activeQuestion].wrongMessage }}
						.submit.clickable(@click='nextQuestion' v-if='$store.state.activeQuestion < 9') Next question
						.submit.clickable(@click='seeResults' v-else) See results
					.results(v-show='$store.state.showResults' :class='{ active: $store.state.fadeinResults }')
						.top
							img.clickable.close(src='@/assets/icons/close-modal.svg' @click='$store.state.questionsActive = false;')
						.main
							.result(v-show='answerPercentage < 50')
								.scoreResult Score: <span class='red'>{{ answerPercentage }}%</span>
								.para.fs Oh dear, You need more preparation, but not to worry, you can find everything you need to know about travelling safely in Iceland here on the webpage. After a thorough reading, retake the test.
							.result(v-show='answerPercentage >= 50 && answerPercentage < 70')
								.scoreResult Score: <span class='yellow'>{{ answerPercentage }}%</span>
								.para.fs A bit unsure? When it comes to driving, it is better to be sure that you are ready. We suggest that you retake the test after you have taken a bit closer look to our pages about Safe driving and Driving in Iceland.
							.result(v-show='answerPercentage >= 70 && answerPercentage < 90')
								.scoreResult Score: <span class='yellow'>{{ answerPercentage }}%</span>
								.para.fs Well done. You look quite ready to start driving in Iceland. However, we suggest that you retake the test after you have taken a bit closer look to our pages about Safe driving and Driving in Iceland.
							.result(v-show='answerPercentage >= 90 && answerPercentage < 100')
								.scoreResult Score: <span class='green'>{{ answerPercentage }}%</span>
								.para.fs Great score! Looks like you are ready to travel safely around Iceland! Have a nice drive!
							.result(v-show='answerPercentage === 100')
								.scoreResult Score: <span class='green'>{{ answerPercentage }}%</span>
								.para.fs Perfect score! Look at that! It seems like you are ready to travel safely around Iceland! Have a nice drive!
							.buttons
								.retake(@click='retake') Retake the test
								.share.clickable(v-show='answerPercentage < 50')
									a.fb-xfbml-parse-ignore(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsafe.is%2Fquiz-result-one&amp;src=sdkpreparse") Share results
								.share.clickable(v-show='answerPercentage >= 50 && answerPercentage < 70')
									a.fb-xfbml-parse-ignore(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsafe.is%2Fquiz-result-two&amp;src=sdkpreparse") Share results
								.share.clickable(v-show='answerPercentage >= 70 && answerPercentage < 90')
									a.fb-xfbml-parse-ignore(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsafe.is%2Fquiz-result-three&amp;src=sdkpreparse") Share results
								.share.clickable(v-show='answerPercentage >= 90 && answerPercentage < 100')
									a.fb-xfbml-parse-ignore(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsafe.is%2Fquiz-result-four&amp;src=sdkpreparse") Share results
								.share.clickable(v-show='answerPercentage === 100')
									a.fb-xfbml-parse-ignore(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsafe.is%2Fquiz-result-five&amp;src=sdkpreparse") Share results
		.alerts-menu.constrainer(:class='{ active: showAlerts }' v-click-outside='closeAlerts')
			.alert-items.gutter
				.item(v-for='alert in alerts')
					.top
						.color.red(v-if='alert.severity === "Red"')
						.color.yellow(v-if='alert.severity === "Yellow"')
						.title {{ alert.title }}
					.bottom
						.pad -
						.description {{ alert.description }}
						.pad(v-if='alert.from.length') -
						.description(v-if='alert.from.length') <span class='bold'>From:</span> {{ alert.from }}
						.pad(v-if='alert.from.length') -
						.description(v-if='alert.from.length') <span class='bold'>To:</span> {{ alert.to }}
		.menu(:class='{ active: showMenu }' v-click-outside='closeMenu')
			.menu-items.gutter
				.item.clickable(@click='routeTo("/")')
					img.icon(src='@/assets/navicons/home.svg')
					.description Home
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/drive-safely")')
					img.icon(src='@/assets/navicons/safe-driving.svg')
					.description Safe driving
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/driving-conditions")')
					img.icon(src='@/assets/navicons/driving-conditions.svg')
					.description Driving conditions
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/driving-in-iceland")')
					img.icon(src='@/assets/navicons/driving-in-iceland.svg')
					.description Driving in Iceland
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/car-information")')
					img.icon(src='@/assets/navicons/car-information.svg')
					.description Car information
					img.caret(src='@/assets/blue-caret.png')
				//- .item.clickable(@click='routeTo("/book-tours-and-activities")')
				//- 	img.icon(src='@/assets/navicons/tours-active.svg')
				//- 	.description Tours and activities
				//- 	img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/interesting-places")')
					img.icon(src='@/assets/navicons/interesting-places.svg')
					.description Interesting places
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='showMenu = false; $store.state.showInterm = false; $store.state.fadeinInterm = false; $store.state.fadeoutQuestions = false; $store.state.fadeinResults = false; $store.state.showResults = false; $store.state.activeQuestion = 0; $store.state.questionsActive = true;')
					img.icon(src='@/assets/navicons/driving-test.svg')
					.description Driving test
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/faq")')
					img.icon(src='@/assets/navicons/faq.svg')
					.description FAQ
					img.caret(src='@/assets/blue-caret.png')
				.item.clickable(@click='routeTo("/about")')
					img.icon(src='@/assets/navicons/about-us.svg')
					.description About us
					img.caret(src='@/assets/blue-caret.png')
			//- .sm-icons
				.facebook
					img.clickable(src='@/assets/facebook-icon.png')
				.twitter
					img.clickable(src='@/assets/twitter-icon.png')
				.insta
					img.clickable(src='@/assets/insta-icon.png')
		.cookies(:class='{ active: showCookie }')
			.cookie-header Cookie policy and disclaimer.
			.text Safe.is uses cookies to optimise your experience of our website. Safe.is does not guarantee the accuracy of the data posted on the site. By using this site you agree and acknowledge that Safe.is is in no way responsible for any inconveniences or issues that you might experience as a result of information posted on this site.
			.accept.clickable(@click='closeCookie') Accept
			router-link.read-more(to='/privacy-policy') Read more

</template>

<script>
import ClickOutside from 'vue-click-outside';
import { setTimeout } from 'timers';
export default {
	name: 'Nav',
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			blockClose: false,
			alerts: [],
			showCookie: false,
			showAlerts: false,
			showMenu: false,
			activeLanguage: 'English',
			count: 1,
			directions: {},
			conditions: {},
			questions: [
				{
					question: 'When can you drive off road?',
					options: [
						'During the summer.',
						'During winter.',
						'As long as I‘m driving a 4x4 vehicle.',
						'Never.'
					],
					// counting from zero
					answer: -1,
					correct: 3,
					correctMessage: 'You‘re right! Off road, driving is illegal and subject to fines up to 400.000 ISK.',
					wrongMessage: 'That is wrong! Off road, driving is illegal no matter the circumstances and the penalty is up to 400.000 ISK per passenger.'
				},
				{
					question: 'What is the maximum speed limit on gravel roads?',
					options: [
						'90 km/h. and lower.',
						'80 km/h. and lower.',
						'60 km/h. and lower.',
						'120 km/h. and lower.'
					],
					// counting from zero
					answer: -1,
					correct: 1,
					correctMessage: 'You are correct! Driving on gravel roads requires special care and attention and you cannot drive faster than announced speed limit, which can be anywhere from 30 km/h to 80 km/h.',
					wrongMessage: 'That is wrong! Driving on gravel roads requires special care and attention and you cannot drive faster than announced speed limit, which can be anywhere from 30 km/h to 80 km/h.'
				},
				{
					question: 'What should I do before I drive in Iceland?',
					options: [
						'Check the weather and road conditions.',
						'Plan my trip and see what roads are open.',
						'Check if my car is properly equipped for the trip.',
						'All of the above.'
					],
					// counting from zero
					answer: -1,
					correct: 3,
					correctMessage: 'We agree! You must be well prepared before you head out. Decide a location and the route you plan to take. Check the weather forecast and road conditions on that route and make sure your car is equipped for the task.',
					wrongMessage: 'That is wrong - better to be safe than sorry! All of the above answers are important part of being prepared to drive in Iceland. Decide a location and route, check the weather forecast and road conditions on that route and make sure your car is properly equipped.'
				},
				{
					question: 'What kind of car do I need to drive in the highlands in winter?',
					options: [
						'4X4 Vehicle.',
						'Jeep.',
						'A diesel car.',
						'You can’t drive in the Highlands in winter.'
					],
					// counting from zero
					answer: -1,
					correct: 3,
					correctMessage: 'Absolutely! It is forbidden to drive the highlands in winter. The road authority is responsible for checking the conditions of roads and only they can give the go ahead for highland roads.',
					wrongMessage: 'That is wrong! It is forbidden to drive the highlands in winter. The road authority is responsible for checking the conditions of roads and only they can give the go ahead for highland roads.'
				},
				{
					question: 'When should my headlights be on?',
					options: [
						'Always.',
						'During night in winter.',
						'When I need to see in the dark.',
						'Never.'
					],
					// counting from zero
					answer: -1,
					correct: 0,
					correctMessage: 'That is correct! In Iceland, headlights must always be on, even in summer.',
					wrongMessage: 'That is wrong! In Iceland, headlights must always be on, even in summer.'
				},
				{
					picture: '/test/narrow.gif',
					question: 'What does this sign mean?',
					options: [
						'Engage headlights.',
						'The road will narrow.',
						'Roads merging.',
						'Church ahead.'
					],
					// counting from zero
					answer: -1,
					correct: 1,
					correctMessage: 'You’re right! The road is going to narrow to a single line, either because of gravel roads or because of a single lane bridge.',
					wrongMessage: 'That is wrong! This sign means that the road is going to narrow to a single line, either because of gravel roads or because of a single lane bridge.'
				},
				{
					picture: '/test/slip.gif',
					question: 'What does this sign mean?',
					options: [
						'Road appropriate for small cars.',
						'Frequent turns ahead.',
						'Slippery road.',
						'Frequent earthquake zone.'
					],
					// counting from zero
					answer: -1,
					correct: 2,
					correctMessage: 'You´re right! The road ahead may be slippery because of icing, water or other factors.',
					wrongMessage: 'That is wrong! The sign means that the road ahead may be slippery because of icing, water or other factors.'
				},
				{
					question: 'Who must wear seatbelts?',
					options: [
						'Only the passengers.',
						'Only the driver.',
						'Everyone travelling in the vehicle.',
						'Only children and teenagers.'
					],
					// counting from zero
					answer: -1,
					correct: 2,
					correctMessage: 'Yes! Everyone must wear seatbelts, except for babies who must be in appropriate safety seat. Breaking this rule may lead to a fine of 20.000 ISK per passenger.',
					wrongMessage: 'Nope! Everyone must wear seatbelts, except for babies who must be in appropriate safety seat. Breaking this rule may lead to a fine of 20.000 ISK per passenger.'
				},
				{
					question: 'Two cars approach a single lane bridge at a similar time. Who crosses the single lane bridge first?',
					options: [
						'The bigger car.',
						'The car that arrived at the bridge first.',
						'They can both cross.',
						'There is no rule for this.'
					],
					// counting from zero
					answer: -1,
					correct: 1,
					correctMessage: 'Yes! The rule of thumb is that the car that arrived at the bridge before the other one has the right of way.',
					wrongMessage: 'No, the rule of thumb is that the car that arrived at the bridge before the other one has the right of way.'
				},
				{
					question: 'Where is the best place to cross a river?',
					options: [
						'Where the water is still.',
						'Where the water is shallow.',
						'Where you can see fish swimming by.',
						'Near a waterfall.'
					],
					// counting from zero
					answer: -1,
					correct: 1,
					correctMessage: 'Yes! The shallow water is the best place to cross while still waters can mean that the river is exceptionally deep. But be sure that you are going the proper way. If you are unsure, do not cross a river!',
					wrongMessage: 'That is incorrect! The shallow water is the best place to cross while still waters can mean that the river is exceptionally deep. But be sure that you are going the proper way. If you are unsure, do not cross a river!'
				}
			]
		};
	},
	computed: {
		answerPercentage: function () {
			return 100 * (this.correctAnswers / this.questions.length).toFixed(2);
		},
		correctAnswers: function () {
			let correct = 0;
			this.questions.forEach((question) => {
				if (question.answer === question.correct) {
					correct++;
				}
			});
			return correct;
		}
	},
	created: function () {
		if (!localStorage.getItem('cookie-accepted')) {
			this.showCookie = true;
		}

		/*
		East
		Southeast
		South
		Southwest
		West
		Northwest
		Northeast
		North
		*/
		/*
		this.axios.get('http://localhost:5000/safe-3ee55/us-central1/alerts').then((result) => {
			this.alerts = result.data;
		});
		*/
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/alerts').then((result) => {
			this.alerts = result.data.filter((item) => {
				if (item.published === undefined) {
					return true;
				}
				return item.published;
			});
		});
	},
	methods: {
		closeMenu: function () {
			if (!this.blockClose) {
				this.showMenu = false;
			}
		},
		openMenu: function () {
			if (!this.blockClose) {
				this.showMenu = true;
				this.showAlerts = false;
				this.blockClose = true;
				setTimeout(() => {
					this.blockClose = false;
				}, 300);
			}
		},
		openAlerts: function () {
			if (!this.blockClose) {
				this.showMenu = false;
				this.blockClose = true;
				this.showAlerts = !this.showAlerts;
				setTimeout(() => {
					this.blockClose = false;
				}, 300);
			}
		},
		closeAlerts: function () {
			if (!this.blockClose) {
				this.showAlerts = false;
			}
		},
		closeCookie: function () {
			localStorage.setItem('cookie-accepted', true);
			this.showCookie = false;
		},
		retake: function () {
			this.$store.state.fadeinResults = false;
			setTimeout(() => {
				this.questions.forEach((question) => {
					question.answer = -1;
				});
				this.$store.state.showResults = false;
				this.$store.state.showInterm = false;
				this.$store.state.fadeinInterm = false;
				this.$store.state.fadeoutQuestions = false;
				this.$store.state.activeQuestion = 0;
				this.$store.state.questionsActive = true;
			}, 300);
		},
		share: function () {
			if (this.answerPercentage < 50) {
				window.FB.ui({
					display: 'popup',
					method: 'share',
					href: 'https://safe.is/quiz-result-one'
				});
			} else if (this.answerPercentage >= 50 && this.answerPercentage < 70) {
				window.FB.ui({
					display: 'popup',
					method: 'share',
					href: 'https://safe.is/quiz-result-two'
				});
			} else if (this.answerPercentage >= 70 && this.answerPercentage < 90) {
				window.FB.ui({
					display: 'popup',
					method: 'share',
					href: 'https://safe.is/quiz-result-three'
				});
			} else if (this.answerPercentage >= 90 && this.answerPercentage < 100) {
				window.FB.ui({
					display: 'popup',
					method: 'share',
					href: 'https://safe.is/quiz-result-four'
				});
			} else if (this.answerPercentage === 100) {
				window.FB.ui({
					display: 'popup',
					method: 'share',
					href: 'https://safe.is/quiz-result-five'
				});
			}
		},
		seeResults: function () {
			this.$store.state.fadeinInterm = false;
			setTimeout(() => {
				this.$store.state.showInterm = false;
				this.$store.state.fadeinResults = true;
				this.$store.state.showResults = true;
			}, 300);
		},
		nextQuestion: function () {
			// transition questions out
			this.$store.state.fadeinInterm = false;
			setTimeout(() => {
				// enable result
				this.$store.state.activeQuestion += 1;
				this.$store.state.showInterm = false;
				// transition questions in
				this.$store.state.fadeoutQuestions = false;
			}, 310);
		},
		selectQuestion: function (index) {
			this.questions[this.$store.state.activeQuestion].answer = index;
		},
		displayInterm: function () {
			// transition questions out
			this.$store.state.fadeoutQuestions = true;
			setTimeout(() => {
				// enable result
				this.$store.state.showInterm = true;
				// transition result in
				this.$store.state.fadeinInterm = true;
			}, 310);
		},
		openQuiz: function () {
			this.showMenu = false;
			this.$modal.show('quiz-modal');
		},
		routeTo: function (destination) {
			this.showMenu = false;
			if (destination === '/' && this.$route.path === '/') {
				this.$SmoothScroll(document.getElementById('anchor'), 500, null, window, 'y');
			} else {
				this.$router.push(destination);
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav {
	pointer-events: none;
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	z-index: 300;
	.question-thing {
		pointer-events: none;
		opacity: 0;
		transform: scale(1.05);
		transition: .3s;
		position: absolute;
		top: 65px;
		left: 0px;
		&.active {
			opacity: 1;
			transform: scale(1);
			pointer-events: initial;
		}
		.backdrop {
			position: relative;
			width: 100vw;
			height: 100vh;
			background-color: rgba(204, 204, 204, 0.75);
			.modal-view {
				box-sizing: border-box;
				position: absolute;
				left: 50%;
				top: 48px;
				transform: translate3d(-50%, 0px, 0px);
				max-width: 600px;
				max-height: 800px;
				width: 90vw;
				// height: 90vh;
				// min-height: 668px;
				box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
				background-color: #ffffff;
				padding-left: 48px;
				padding-right: 48px;
				max-height: 82vh;
				overflow-y: scroll;
				-ms-overflow-style: none;  // IE 10+
				scrollbar-width: none;  // Firefox
				&::-webkit-scrollbar {
					display: none;  // Safari and Chrome
				}
				@media screen and (max-width: 1220px) {
					width: 100%;
					height: 100%;
					top: 0px;
					left: 0px;
					transform: translate3d(0px, 0px, 0px);
					max-width: 100%;
				}
				.results {
					width: 100%;
					height: 100%;
					opacity: 0;
					transition: .3s;
					padding-bottom: 48px;
					&.active {
						opacity: 1;
					}
					.main {
						padding-top: 48px;
						.buttons {
							margin-top: 64px;
							display: grid;
							grid-template-columns: auto auto;
							grid-column-gap: 16px;
							@media screen and (max-width: 1220px) {
								grid-template-columns: auto;
								grid-row-gap: 16px;
								.retake, .share {
									margin-left: auto;
									margin-right: auto;
								}
							}
							.retake {
								color: white;
								line-height: 48px;
								width: 244px;
								height: 48px;
								box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
								background-color: #00476e;
							}
							.share {
								margin-top: 14px;
								a {
									padding-left: 61.71px;
									padding-right: 61.71px;
									padding-top: 14.5px;
									padding-bottom: 14.5px;
									color: white;
									box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
									background-color: #00476e;
									@media screen and (max-width: 1220px) {
										margin-bottom: 48px;
									}
								}
							}
						}
						.para {
							text-align: left;
							margin-top: 48px;
							max-width: 502px;
							@media screen and (max-width: 1220px) {
								margin-left: auto;
								margin-right: auto;
							}
						}
						.scoreResult {
							margin-left: auto;
							margin-right: auto;
							width: 448px;
							height: 53px;
							font-family: Montserrat;
							font-size: 48px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.5;
							letter-spacing: 1.9px;
							color: #000000;
							@media screen and (max-width: 1220px) {
								font-size: 24px;
								width: auto;
							}
							.red {
								color: #e22730;
							}
							.yellow {
								color: #e6cb00;
							}
							.green {
								color: greenyellow;
							}
						}
					}
					.top {
						box-sizing: border-box;
						position: absolute;
						right: 0px;
						height: 114px;
						.close {
							top: 16px;
							right: 16px;
							position: absolute;
							width: 24px;
							height: 24px;
						}
					}
				}
				.interm {
					width: 100%;
					height: 100%;
					max-height: 70vh;
					overflow-y: scroll;
					-ms-overflow-style: none;  // IE 10+
					scrollbar-width: none;  // Firefox
					&::-webkit-scrollbar {
						display: none;  // Safari and Chrome
					}
					opacity: 0;
					transition: .3s;
					padding-bottom: 48px;
					&.active {
						opacity: 1;
					}
					.top {
						box-sizing: border-box;
						position: absolute;
						right: 0px;
						height: 114px;
						.close {
							top: 16px;
							right: 16px;
							position: absolute;
							width: 24px;
							height: 24px;
						}
					}
					.submit {
						margin-top: 48px;
						margin-left: auto;
						margin-right: auto;
						width: 214px;
						height: 48px;
						box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
						background-color: #00476e;
						font-size: 16px;
						font-weight: 500;
						font-style: normal;
						font-stretch: normal;
						line-height: 48px;
						letter-spacing: 1.2px;
						text-align: center;
						color: #ffffff;
					}
					.para {
						margin-left: auto;
						margin-right: auto;
						max-width: 360px;
						margin-top: 48px;
						letter-spacing: 1.75px;
						line-height: 34px;
					}
					.result-interm {
						padding-top: 86px;
						font-size: 18px;
						font-weight: bold;
						line-height: 1.5;
						letter-spacing: 2px;
						&.correct {
							color: #00486c;
						}
						&.wrong {
							color: #e62d35;
						}
					}
				}
				.question-selection {
					transition: .3s;
					&.fadeout {
						opacity: 0;
					}
					.bottom {
						position: relative;
						width: 503px;
						left: 50%;
						transform: translate3d(-50%, 0px, 0px);
						.submit {
							margin-left: auto;
							margin-right: auto;
							width: 114px;
							height: 48px;
							box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
							background-color: #00476e;
							font-size: 16px;
							font-weight: 500;
							font-style: normal;
							font-stretch: normal;
							line-height: 48px;
							letter-spacing: 1.2px;
							text-align: center;
							color: #ffffff;
						}
						.progress {
							position: relative;
							display: grid;
							grid-template-columns: repeat(10, 1fr);
							grid-column-gap: 11px;
							margin-top: 49px;
							left: 50%;
							max-width: 333px;
							transform: translate3d(-50%; 0px, 0px);
							margin-bottom: 48px;
							.dash {
								margin-left: auto;
								margin-right: auto;
								width: 23px;
								height: 3px;
								transition: .3s;
								background-color: #cccccc;
								&.active {
									background-color: #8ff7a7;
								}
							}
						}
					}
					.main {
						padding-top: 86px;
						position: relative;
						.options {
							margin-bottom: 51px;
							margin-top: 41px;
							position: relative;
							.item {
								position: relative;
								width: 100%;
								padding-top: 6px;
								padding-bottom: 6px;
								box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
								background-color: #ffffff;
								display: grid;
								grid-template-columns: auto auto;
								grid-column-gap: 16px;
								margin-bottom: 16px;
								transition: .3s;
								box-sizing: border-box;
								&.active {
									background-color: #00476e;
									.text {
										color: white;
									}
									.icon {
										filter: grayscale(100%) brightness(500%);
									}
								}
								&:hover {
									transform: scale(.98);
								}
								.text {
									position: relative;
									transition: .3s;
									padding-left: 24px;
									text-align: left;
									font-size: 16px;
									font-weight: normal;
									font-style: normal;
									font-stretch: normal;
									padding-top: 6px;
									padding-bottom: 6px;
									line-height: 1.5;
									letter-spacing: 1.3px;
									color: #000000;
								}
								.icon-box {
									display: flex;
									justify-self: flex-end;
									height: 100%;
									align-content: space-around;
									.icon {
										display: flex;
										align-self: center;
										transition: .3s;
										padding-right: 21px;
										position: relative;
										width: 16px;
										height: 16px;
									}
								}
							}
						}
						.title-picture {
							img {
								display: none;
							}
							&.usepicture {
								padding-right: 130px;
								display: grid;
								grid-template-columns: 110px auto;
								grid-column-gap: 51px;
								img {
									display: block;
									width: 100%;
								}
							}
							.title {
								text-align: left;
								max-width: 448px;
								font-size: 24px;
								font-weight: bold;
								font-style: normal;
								font-stretch: normal;
								line-height: 1.5;
								letter-spacing: 2px;
								color: #000000;
							}
						}
					}
					.top {
						box-sizing: border-box;
						position: absolute;
						right: 0px;
						height: 114px;
						.close {
							top: 16px;
							right: 16px;
							position: absolute;
							width: 24px;
							height: 24px;
						}
					}
				}
			}
		}
	}
	.alerts-menu {
		padding-bottom: 18px;
		padding-top: 36px;
		transition: .3s;
		transform: translate3d(0px, -100%, 0px);
		box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.2);
		opacity: 0;
		&.active {
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		}
		z-index: 299;
		display: block;
		background-color: #fafafa;
		position: absolute;
		top: 65px;
		width: 100%;
		max-height: calc(~'100vh - 165px');
		overflow-y: scroll;
		pointer-events: initial;
		.alert-items {
			.item {
				margin-bottom: 24px;
				.top {
					display: grid;
					grid-template-columns: 16px auto;
					grid-column-gap: 16px;
					margin-bottom: 4px;
					.title {
						font-weight: bold;
						text-align: left;
						line-height: 1.29;
						letter-spacing: 1.17px;
						@media screen and (max-width: 375px) {
							font-size: 14px;
						}
					}
					.color {
						width: 16px;
						height: 16px;
						&.red {
							background-color: #e22730;
						}
						&.yellow {
							background-color: #f9db49;
						}
						border-radius: 50%;
					}
				}
				.bottom {
					display: grid;
					grid-template-columns: 16px auto;
					grid-column-gap: 16px;
					.pad {
						opacity: 0;
						width: 16px;
						height: 16px;
					}
					.description {
						text-align: left;
						line-height: 1.5;
						letter-spacing: 1.2px;
						@media screen and (max-width: 375px) {
							font-size: 14px;
						}
						.bold {
							font-weight: bold;
						}
					}
				}
			}
		}
		@media screen and (min-width: 1240px) {
			left: 50%;
			transform: translate3d(-50%, -100%, 0px);
			&.active {
				transform: translate3d(-50.03%, 0px, 0px);
			}
		}
		@media screen and (max-width: 1239px) {
			margin-left: 0px;
			margin-right: 0px;
			max-height: calc(~'100vh - 65px');
			height: auto;
			overflow-y: scroll;
			display: block;
			&.active {
				pointer-events: initial;
			}
			.alert-items {
				position: relative;
				display: block;
				height: auto;
				padding-bottom: 96px;
				position: relative;
			}
		}
	}
	.inner {
		background-color: white;
		position: relative;
		z-index: 300;
		box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
		.alerts {
			pointer-events: initial;
			position: relative;
			padding-top: 20px;
			.bell-icon {
				width: 24px;
			}
			.alert {
				position: relative;
				top: -6px;
				color: black;
				margin-left: 8px;
				margin-right: 8px;
				@media screen and (max-width: 1220px) {
					font-size: 12px;
				}
			}
			.caret {
				position: relative;
				top: -8px;
				img {
					transition: .3s;
					&.spin {
						transform: rotate3d(1, 0, 0, 180deg);
					}
				}
			}
			.count {
				position: absolute;
				background-color: #E62D35;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				border: 1px solid white;
				transform: translate3d(-10px, 4px, 0px);
			}
		}
	}

	.menu {
		position: relative;
		left: 100%;
		transform: translate3d(50px, 0px, 0px);
		transition: .3s;
		transition-delay: .1s;
		pointer-events: none;
		&.active {
			pointer-events: initial;
			transform: translate3d(-100%, 0px, 0px);
		}
		padding-top: 32px;
		padding-bottom: 84px;
		width: 100%;
		height: calc(~'100vh - 64px');
		max-width: 420px;
		background-color: #00486C;
		.sm-icons {
			max-width: 60%;
			margin-left: auto;
			margin-right: auto;
			margin-top: 48px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 48px;
		}
		.menu-items {
			display: grid;
			grid-template-columns: 1fr;
			grid-row-gap: 24px;
			.item {
				position: relative;
				display: grid;
				grid-template-columns: 24px auto 1px;
				grid-column-gap: 24px;
				.description {
					text-align: left;
					color: white;
					font-size: 14px;
					letter-spacing: initial;
				}
				.caret {
					max-width: 12px;
					position: absolute;
					top: 50%;
					right: 0px;
					width: 12px;
					transform: rotate3d(0, 0, 1, -90deg) translate3d(4px, -50%, 0px);
				}
				.icon {
					position: relative;
					left: 0px;
					height: 20px;
				}
			}
		}
		.language {
			color: white;
			text-align: left;
			font-size: 14px;
			span {
				margin-left: 9px;
			}
			margin-bottom: 30px;
		}
	}
	.inner {
		background-color: white;
		height: 65px;
		.content {
			display: grid;
			grid-template-columns: auto auto auto;
		}
	}
	.logo {
		pointer-events: initial;
		position: relative;
		height: 100%;
		top: 8px;
		img {
			top: 50%;
			transform: translate3d(0px, -50%, 0px);
			width: 55px;
			position: absolute;
			left: 0px;
			@media screen and (min-width: 1325px) {
				left: 0px;
			}
		}
	}
	.burger {
		pointer-events: initial;
		position: relative;
		/* Close button for burger menu */
		.octa {
			position: absolute;
			right: 0px;
			top: 18px;
			pointer-events: none;
			.cross {
				position: absolute;
				top: 0px;
				left: 0px;
				.criss {
					position: absolute;
					top: 10px;
					left: 14px;
					transition: .3s;
					opacity: 0;
					transform: rotate3d(1, 0, 0, 90deg);
					height: 10px;
					width: 2px;
					background-color: #00486C;
				}
				.crass {
					position: absolute;
					transition: .3s;
					opacity: 0;
					transform: rotate3d(1, 0, 0, 90deg);
					top: 10px;
					left: 14px;
					height: 10px;
					width: 2px;
					background-color: #00486C;
				}
			}
			.wrapper {
				opacity: 0;
				transition: .3s;
				transform: rotate3d(1, 1, 0, 90deg);
				width: 30px;
				height: 30px;
			}
			&.active {
				.criss {
					opacity: 1;
					transition-delay: .4s;
					transform: rotate3d(0, 0, 1, 45deg);
				}
				.crass {
					opacity: 1;
					transition-delay: .4s;
					transform: rotate3d(0, 0, 1, -45deg);
				}
				.wrapper {
					opacity: 1;
					transition: .3s;
					transition-delay: .3s;
					transform: rotate3d(1, 0, 0, 0deg);
				}
				pointer-events: initial;
			}
			@media screen and (max-width: 1220px) {
				right: -2px;
				transform: scale(.85);
			}
		}
		/* Burger */
		.main-burger {
			position: absolute;
			top: 18px;
			right: 0px;
			width: 30px;
			display: grid;
			grid-template-columns: 1fr;
			grid-row-gap: 7px;
			.top, .bot {
				opacity: 0;
				width: 100%;
				background-color: #00486C;
				height: 5px;
				transition: .3s;
				transform: rotate3d(1, 0, 0, 90deg);
			}
			.mid {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-column-gap: 3px;
				.mid-one, .mid-two, .mid-three {
					opacity: 0;
					width: 100%;
					height: 5px;
					background-color: #FBDB59;
					transition: .3s;
					transition-delay: .1s;
					transform: rotate3d(1, 0, 0, 90deg);
				}
			}
			&.active {
				.top, .bot {
					opacity: 1;
					transition-delay: .3s;
					transform: rotate3d(0, 1, 1, 0deg);
				}
				.mid {
					.mid-one, .mid-two, .mid-three {
						opacity: 1;
						transition-delay: .5s;
						transform: rotate3d(0, 1, 1, 0deg);
					}
				}
			}
			@media screen and (max-width: 1220px) {
				right: 0px;
				top: 21px;
				width: 24px;
				grid-row-gap: 5px;
				.top, .bot {
					height: 4px;
				}
				.mid {
					.mid-one, .mid-two, .mid-three {
						height: 4px;
					}
				}
			}
		}
	}
	.cookies {
		position: fixed;
		left: 50%;
		transform: translate3d(-50%, 0px, 0px);
		bottom: 14px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
		margin-left: 14px;
		margin-right: 14px;
		padding-top: 24px;
		padding-bottom: 24px;
		box-sizing: border-box;
		width: 100%;
		max-width: 625px;
		background-color: white;
		text-align: left;
		padding-left: 14px;
		padding-right: 14px;
		line-height: 24px;
		transition: .3s;
		opacity: 0;
		pointer-events: none;
		.cookie-header {
			font-weight: bold;
			margin-bottom: 18px;
		}
		.text {
			margin-bottom: 18px;
		}
		.accept, .read-more {
			font-size: 14px;
			margin-right: 18px;
			display: inline;
			color: black;
			outline: 1px solid black;
			padding-left: 6px;
			padding-right: 6px;
			padding-top: 4px;
			padding-bottom: 4px;
		}
		&.active {
			opacity: 1;
			pointer-events: initial;
		}
		img {
			position: absolute;
			right: 14px;
			top: 14px;
			height: 24px;
			width: 24px;
			pointer-events: initial;
			cursor: pointer;
			transition: .3s;
			&:hover {
				transform: scale(1.05);
			}
		}
	}
}
</style>
