import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import store from './store.js';

Vue.use(Router);

export default new Router({
	mode: 'history',
	scrollBehavior () {
		return {
			x: 0,
			y: 0
		};
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/about',
			name: 'about',
			component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/drive-safely',
			name: 'safe',
			component: () => import(/* webpackChunkName: "safe-driving" */ './views/SafeDriving.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/driving-conditions',
			name: 'drivingConditions',
			component: () => import(/* webpackChunkName: "driving-conditions" */ './views/DrivingConditions.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/listen',
			name: 'listen',
			component: () => import(/* webpackChunkName: "listen" */ './views/Listen.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/interesting-places',
			name: 'interesting',
			component: () => import(/* webpackChunkName: "interesting-places" */ './views/InterestingThings.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/faq',
			name: 'faq',
			component: () => import(/* webpackChunkName: "faq" */ './views/FAQ.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/driving-in-iceland',
			name: 'drivingInIceland',
			component: () => import(/* webpackChunkName: "drivingInIceland" */ './views/DrivingInIceland.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/car-information',
			name: 'carInformation',
			component: () => import(/* webpackChunkName: "carInformation" */ './views/CarInformation.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/book-tours-and-activities',
			name: 'ToursAndActive',
			component: () => import(/* webpackChunkName: "carInformation" */ './views/ToursAndActive.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			},
			props: true
		},
		{
			path: '/privacy-policy',
			name: 'privacyPolicy',
			component: () => import(/* webpackChunkName: "privacyPolicy" */ './views/PrivacyPolicy.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/terms-and-conditions',
			name: 'termsAndConditions',
			component: () => import(/* webpackChunkName: "TermsAndConditions" */ './views/TermsAndConditions.vue'),
			beforeEnter: (to, from, next) => {
				store.state.keydex += 1;
				next();
			}
		},
		{
			path: '/quiz-result-one',
			name: 'QuizOne',
			component: () => import(/* webpackChunkName: "quizOne" */ './views/quiz/ResultOne.vue')
		},
		{
			path: '/quiz-result-two',
			name: 'QuizTwo',
			component: () => import(/* webpackChunkName: "quizTwo" */ './views/quiz/ResultTwo.vue')
		},
		{
			path: '/quiz-result-three',
			name: 'QuizThree',
			component: () => import(/* webpackChunkName: "quizThree" */ './views/quiz/ResultThree.vue')
		},
		{
			path: '/quiz-result-four',
			name: 'QuizFour',
			component: () => import(/* webpackChunkName: "quizFour" */ './views/quiz/ResultFour.vue')
		},
		{
			path: '/quiz-result-five',
			name: 'QuizFive',
			component: () => import(/* webpackChunkName: "quizFive" */ './views/quiz/ResultFive.vue')
		},
		{
			path: '*',
			name: 'NotFoundPage',
			component: () => import(/* webpackChunckName: "NotFoundPage" */ './views/NotFoundPage.vue')
		}
	]
});
