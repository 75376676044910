<template lang='pug'>
	#app
		Nav
		transition(name='slide-fade' appear)
			router-view
		Footer
</template>

<script>
import Nav from '@/components/Nav.vue';
export default {
	name: 'App',
	components: {
		Nav
	}
};

</script>

<style lang="less">
.v--modal-block-scroll {
	height: 100vh;
}
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#app {
	padding-top: 65px;
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
html, body {
	margin: 0px;
	position: relative;
	max-width: 100vw;
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

a, p {
	text-align: left;
	letter-spacing: 1.33px;
}

.more {
	padding-top: 16px;
	padding-bottom: 16px;
	float: left;
	color: #00476E;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.89px;
	img {
		transform: translate3d(4px, 2px, 0px);
	}
	&.desktop {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 24px;
		height: 48px;
		width: 260px;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
		border-left: 4px solid #AF7595;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 1.5px;
		line-height: 48px;
		img {
			transform: translate3d(100px, 8px, 0px);
		}
	}
}

h1 {
	text-align: left;
	font-size: 48px !important;
	font-weight: bold !important;
	letter-spacing: 1.92px !important;
	line-height: 72px !important;
	margin: 0px !important;
	@media screen and (max-width: 1220px) {
		font-size: 32px !important;
		font-weight: bold !important;
		font-style: normal !important;
		font-stretch: normal !important;
		line-height: 1.47 !important;
		letter-spacing: 1.3px !important;
		color: #000000 !important;
	}
}

.clickable {
	cursor: pointer;
}

h2 {
	text-align: left;
	letter-spacing: 2px;
}

h3 {
	text-align: left;
	letter-spacing: 2px;
}

h4 {
	text-align: left;
}

.fs {
	font-size: 18px !important;
	line-height: 34px !important;
	letter-spacing: 1.75px !important;
	@media screen and (max-width: 1220px) {
		font-size: 16px !important;
		line-height: 26px !important;
		letter-spacing: 1.33px !important;
	}
}

.gutter {
	margin-left: 23px;
	margin-right: 23px;
	@media screen and (min-width: 1240px) {
		margin-left: 48px;
		margin-right: 48px;
	}
}
.constrainer {
	@media screen and (min-width: 1221px) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1125px;
	}
	@media screen and (max-width: 1220px) {
		margin-left: 24px;
		margin-right: 24px;
	}
}

.mobile {
	@media screen and (min-width: 1221px) {
		display: none !important;
	}
}

.desktop {
	@media screen and (max-width: 1220px) {
		display: none !important;
	}
}

.desktop-mini {
	@media screen and (max-width: 768px) {
		display: none !important;
	}
}

.section-pad {
	margin-top: 192px;
	@media screen and (max-width: 1220px) {
		margin-top: 64px;
	}
}

* {
	// outline: 1px solid firebrick;
}

.space {
	min-height: 192px;
	.adspace {
		margin-top: 71px;
		margin-bottom: 236px;
		@media screen and (max-width: 1220px) {
			margin-top: 86px;
			margin-bottom: 86px;
		}
	}
}
</style>
