<template lang='pug'>
	.interesting-things.constrainer
		.content.desktop
			img(src='@/assets/placeholder-backdrop.png')
		.content.gutter.no-desktop
			.header
				img.mobile(src='@/assets/placeholder-backdrop.png')
				h2.title Traffic fines in Iceland.
			.dashes
				.dash(v-for='n in 5')
			p.para Get to know about fines and sanctions for violation of traffic rules before you start driving in Iceland.
			router-link.more.mobile(to='/interesting-things') Read more <span> <img src='@/assets/white-caret-2.png'></img> </span>
			router-link.more.desktop(to='/interesting-things') Show me more <span> <img src='@/assets/arrow-blue.svg'></img> </span>
</template>

<script>
export default {
	name: 'Nav',
	data: function () {
		return {
		};
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.interesting-things {
	// margin-top: 130px;
	@media screen and (max-width: 1239px) {
		margin-bottom: 150px;
	}
	.para {
		line-height: 24px;
	}
	.header {
		position: relative;
		margin-top: -80px;
		img {
			width: 100%;
			box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.15);
		}
		.title {
			position: absolute;
			bottom: -40px;
			font-size: 26px;
			width: 371px;
			padding-right: 10px;
			background-color: white;
			padding-top: 10px;
			padding-bottom: 10px;
			color: black;
		}
	}
	.dashes {
		margin-top: 50px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
	@media screen and (min-width: 1240px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 48px;
		margin-top: 60px;
		margin-bottom: 180px;
		.content {
			padding-top: 24px;
			.header {
				.title {
					position: relative;
					font-size: 48px;
					font-weight: bold;
					letter-spacing: 1.92px;
					line-height: 72px;
				}
			}
			.para {
				font-size: 18px;
				letter-spacing: 1.75px;
				line-height: 34px;
			}
			.more {
				margin-top: 20px;
				position: absolute;
				left: 50%;
				transform: translate3d(-50%, 0px, 0px);
				color: black;
				&.desktop {
					img {
						transform: translate3d(50px, 8px, 0px);
					}
				}
			}
			&.desktop {
				img {
					object-fit: cover;
					width: 100%;
					height: calc(~'100% + 116px');
				}
			}
		}
	}
}
</style>
