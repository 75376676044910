<template lang='pug'>
	.contact-form
		h2 Have a question?
		input(type='text' placeholder='Enter your full name' v-model='contactForm.name')
		input(type='text' placeholder='Enter your email address' v-model='contactForm.email')
		textarea(placeholder='What is on your mind?' v-model='contactForm.message')
		.submit.clickable(@click='sendForm' :class='{ disabled: !allowSend, success: success }') Send
</template>

<script>
import { setTimeout } from 'timers';
export default {
	name: 'Nav',
	data: function () {
		return {
			contactForm: {
				name: '',
				email: '',
				message: ''
			},
			success: false
		};
	},
	computed: {
		allowSend: function () {
			return this.contactForm.name.length && this.contactForm.email.length && this.contactForm.message.length;
		}
	},
	methods: {
		sendForm: function () {
			this.axios.post('https://postalservice.jokula.is', {
				company: 'safe',
				name: this.contactForm.name,
				phone: '',
				email: this.contactForm.email,
				message: this.contactForm.message
			}).then((result) => {
				this.contactForm.name = '';
				this.contactForm.email = '';
				this.contactForm.message = '';
				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 1000);
			});
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.contact-form {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	input, textarea {
		padding: 17px;
		margin-bottom: 24px;
		font-size: 15px;
		letter-spacing: 0.6px;
		line-height: 19px;
		transition: .2s;
		border: none;
		outline: 1px solid rgba(204, 204, 204, 1);
		font-family: Montserrat;
		&::placeholder {
			color: #CCCCCC;
		}
		&:focus {
			outline: 1px solid rgba(0, 72, 108, 1);
		}
	}
	textarea {
		font-family: Montserrat;
		height: 144px;
		resize: none;
	}
	.submit {
		height: 48px;
		width: 155px;
		background-color: #00476E;
		box-shadow: 2px 2px 3px 0 rgba(0,0,0,0.07);
		color: #FFFFFF;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 1.19px;
		line-height: 48px;
		text-align: center;
		margin-top: 28px;
		transition: .3s;
		&.disabled {
			pointer-events: none;
			background-color: gray;
		}
		&.success {
			background-color: green;
		}
	}
	@media screen and (min-width: 1240px) {
		h2 {
			height: 80px;
			width: 634px;
			font-size: 48px;
			letter-spacing: 1.92px;
			line-height: 72px;
		}
		input, textarea {
			width: 634px;
			margin-left: 130px;
		}
		.submit {
			margin-left: 130px;
		}
	}
}
</style>
