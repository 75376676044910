<template lang='pug'>
	.crumbs <span @click='goHome'>safe</span>{{ slug }}
</template>

<script>
export default {
	name: 'Nav',
	props: {
		slug: {
			type: String,
			default: '',
			required: true
		}
	},
	methods: {
		goHome: function () {
			this.$router.push('/');
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.crumbs {
	span {
		transition: .3s;
		cursor: pointer;
		&:hover {
			color: rgba(0, 0, 0, .5);
		}
	}
	color: #CCCCCC;
	font-weight: 500;
	font-size: 12px;
	text-align: left;
	letter-spacing: .89px;
	@media screen and (min-width: 1240px) {
		margin-top: 70px;
		margin-bottom: 61px;
		height: 24px;
		font-size: 16px;
		letter-spacing: 1.33px;
		line-height: 24px;
	}
}
</style>
