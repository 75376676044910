<template lang='pug'>
	.signs(:class='{ "use-margin": useMargin, "gutter": useMargin, "spun": doneIt }' @click='goTo')
		.overlay(:class='{ "spun": doneIt }')
		p Signs
		img.icon(src='@/assets/signs-icon-inverted.png')
</template>

<script>
export default {
	name: 'Signs',
	props: {
		useMargin: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			doneIt: false
		};
	},
	methods: {
		goTo: function () {
			this.doneIt = true;
			setTimeout(() => {
				this.$router.push('/signs');
			}, 300);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.signs {
	position: relative;
	transition: .3s;
	display: grid;
	grid-template-columns: 1.9fr .1fr;
	height: 48px;
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
	padding-left: 28px;
	padding-right: 12px;
	font-weight: bold;
	border-left: 4px solid #F9DB49;
	.overlay {
		transition: .3s;
		transition-timing-function: ease-in;
		width: 4px;
		left: -4px;
		z-index: 200;
		position: absolute;
		height: 100%;
		background-color: rgba(249, 219, 73, .5);
		&.spun {
			width: calc(~'100% + 4px');
		}
	}
	&.use-margin {
		margin-top: 70px;
	}
	img {
		position: relative;
		top: 50%;
		transform: translate3d(0px, -50%, 0px);
		max-height: 48px;
	}
}
</style>
