<template lang='pug'>
	a.take-test.gutter(:class='{ "use-margin": useMargin, "gutter": useMargin, "spun": doneIt }' @click='goTo' href='tel:112')
		.overlay(:class='{ "spun": doneIt }')
		.para 112
		.sub-para Emergency number
		img.icon(src='@/assets/emergency-icon.png')
</template>

<script>
export default {
	name: 'Nav',
	props: {
		useMargin: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			doneIt: false
		};
	},
	methods: {
		goTo: function () {
			this.doneIt = true;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.take-test {
	max-width: 370px;
	position: relative;
	margin-top: 45px;
	display: grid;
	grid-template-columns: 65px auto 40px;
	height: 48px;
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
	padding-left: 14px;
	padding-right: 12px;
	border-left: 4px solid #E22730;
	.para {
		letter-spacing: 3px;
		font-weight: bold;
		color: #E22730;
		line-height: 48px;
		font-size: 34px;
		text-align: left;
	}
	.sub-para {
		letter-spacing: 1.33px;
		font-weight: normal;
		color: black;
		font-size: 14px;
		line-height: 48px;
		text-align: left;
	}
	.overlay {
		transition: .3s;
		transition-timing-function: ease-in;
		width: 4px;
		left: -4px;
		z-index: 200;
		position: absolute;
		height: 100%;
		background-color: rgba(226, 39, 48, .5);
		&.spun {
			width: calc(~'100% + 4px');
		}
	}
	&.use-margin {
		margin-top: 70px;
	}
	img {
		position: relative;
		top: 50%;
		transform: translate3d(0px, -50%, 0px);
		max-height: 48px;
	}
	@media screen and (max-width: 355px) {
		grid-template-columns: 65px auto 25px;
		.sub-para {
			letter-spacing: 1.33px;
			font-weight: normal;
			color: black;
			font-size: 12px;
		}
	}
	@media screen and (min-width: 1240px) {
		width: 287px;
	}
}
</style>
