import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		audioLanguage: 'English',
		questionsActive: false,
		activeQuestion: 0,
		showInterm: false,
		showResults: false,
		fadeinResults: false,
		fadeoutQuestions: false,
		fadeinInterm: false,
		keydex: 0
	},
	mutations: {

	},
	actions: {

	}
});
