<template lang='pug'>
	.faq-section
		.emergency
			.inner
				.head 112
				.para Emergency call number
		.administration
			.inner
				.head 1777
				.para The Icelandic Road and Coastal Administration
		.section-one
			h1 Have a question?
			.dashes
				.dash(v-for='n in 5')
			p.para.fs Cannot find what you are looking for? Check out our frequently asked questions or send us a question.
			router-link.button.desktop(to='/faq')
				.text Check our FAQ
				img.arrow(src='@/assets/icons/arrow-blue.svg')
		.section-two
			router-link.item.clickable(to='/faq')
				.text.fs {{ question1 }}
				.icon-container
					img.icon(src='@/assets/icons/arrow-yellow.svg')
			router-link.item.clickable(to='/faq')
				.text.fs {{ question2 }}
				.icon-container
					img.icon(src='@/assets/icons/arrow-yellow.svg')
			router-link.item.clickable(to='/faq')
				.text.fs {{ question3 }}
				.icon-container
					img.icon(src='@/assets/icons/arrow-yellow.svg')
		router-link.mobile-link.mobile(to='/faq')
			.text Check our FAQ
			img.arrow(src='@/assets/icons/caret_blue.svg')
</template>

<script>
export default {
	name: 'FAQ',
	props: {
		question1: {
			type: String,
			default: 'How do I drive on a gravel road?'
		},
		question2: {
			type: String,
			default: 'What do I do when my car is sliding?'
		},
		question3: {
			type: String,
			default: 'What are the dangers of driving during winter?'
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.faq-section {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 48px;
	@media screen and (max-width: 1220px) {
		grid-template-columns: 1fr;
	}
	.emergency {
		margin-bottom: 64px;
		width: 100%;
		.inner {
			margin-left: auto;
			margin-right: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
			box-shadow: 1px 3px 3px 2px rgba(0, 0, 0, 0.07);
			max-width: 375px;
			height: 140px;
			width: 100%;
			border-top: 4px solid #E22730;
			.head {
				font-size: 64px;
				color: #E22730;
				font-weight: bold;
				font-family: Montserrat;
			}
			.para {
				font-family: Montserrat;
				font-size: 16px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	.administration {
		margin-bottom: 64px;
		width: 100%;
		.inner {
			margin-left: auto;
			margin-right: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
			max-width: 375px;
			height: 140px;
			width: 100%;
			box-shadow: 1px 3px 3px 2px rgba(0, 0, 0, 0.07);
			border-top: 4px solid #F9DB49;
			.head {
				font-size: 64px;
				color: #F9DB49;
				font-weight: bold;
				font-family: Montserrat;
			}
			.para {
				font-family: Montserrat;
				font-size: 16px;
				max-width: 200px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	.mobile-link {
		position: relative;
		margin-top: 48px;
		display: grid;
		grid-template-columns: auto auto;
		width: 160px;
		height: 18px;
		left: 100%;
		transform: translate3d(-100%, 0px, 0px);
		.arrow {
			position: relative;
			top: 2px;
			right: 0px;
			height: 14px;
			transform: rotate(-90deg);
		}
		.text {
			width: 143px;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.9px;
			color: #00476e;
		}
	}
	.section-two {
		margin-top: 34px;
		@media screen and (max-width: 1220px) {
			margin-top: 0px;
		}
		.item {
			margin-top: 48px;
			display: grid;
			grid-template-columns: auto 22px;
			background-color: #FAFAFA;
			transition: .3s;
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 24px;
			padding-right: 24px;
			.text {
				text-align: left;
				color: #4D4D4D;
				line-height: 34px;
			}
			.icon-container {
				display: flex;
				justify-self: flex-end;
				height: 100%;
				.icon {
					position: relative;
					display: flex;
					justify-self: center;
					transition: .3s;
				}
			}
			&:hover {
				.icon-container {
					.icon {
						transform: translate3d(5px, 0px, 0px);
					}
				}
				transform: scale(1.03);
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
			}
		}
	}
	.section-one {
		.button {
			display: grid;
			grid-template-columns: 80% 20%;
			height: 48px;
			width: 260px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			border-left: 4px solid #95f9e3;
			margin-top: 58px;
			transition: .3s;
			.text {
				color: black;
				height: 30px;
				width: 161px;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1.5px;
				line-height: 48px;
				padding-left: 32px;
			}
			.arrow {
				position: relative;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
				transition: .3s;
			}
			&:hover {
				transform: scale(1.03);
				border-left: 16px solid #95f9e3;
				width: 248px;
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
				.arrow {
					transform: translate3d(5px, -50%, 0px);
				}
			}
		}
		.faq-item {
			transforM: translate3d(-47px, 0px, 0px);
		}
		.dashes {
			margin-top: 28px;
			margin-bottom: 30px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
	}
	.para {
		max-width: 479px;
	}
}
</style>
