<template lang='pug'>
	.header
		.content.custom-constrainer
			img.header-image(src='@/assets/jpg/home.jpg')
			.custom-constrainer-two
				h1.title Safe and sound in Iceland.
				.dashes.desktop
					.dash(v-for='n in 5')
				.dashes.mobile
					.dash(v-for='n in 5')
				.para.fs Listen to the most important safety information in your preferred language to keep safe and sound while driving in Iceland.
				router-link.mobile-link.mobile(to='/drive-safely')
					.text Learn more
					img.arrow(src='@/assets/icons/caret_blue.svg')
</template>

<script>
export default {
	name: 'Header'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
	height: 628px;
	max-height: 628px;
	background-image: url('~@/assets/jpg/hero.jpg');
	background-size: cover;
	background-position: 50% 100%;
	.custom-constrainer-two {
		@media screen and (max-width: 1220px) {
			margin-left: 24px;
			margin-right: 24px;
		}
	}
	.custom-constrainer {
		@media screen and (min-width: 1221px) {
			margin-left: auto;
			margin-right: auto;
			max-width: 1125px;
		}
		@media screen and (max-width: 1220px) {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	.header-image {
		display: none;
	}
	@media screen and (max-width: 1220px) {
		background-image: none;
		height: initial;
		max-height: initial;
		.header-image {
			margin-top: 0px;
			display: block;
			width: 100%;
			max-height: 440px;
			object-fit: cover;
			object-position: bottom;
		}
	}
	.content {
		position: relative;
		top: 0px;
		height: 100%;
		left: 0px;
		text-align: left;
		.title {
			padding-top: 88px;
			position: relative;
			max-width: 304px;
			@media screen and (max-width: 768px) {
				padding-top: 40px;
				max-width: 284px;
			}
		}
		.para {
			padding-top: 84px;
			max-width: 447px;
			@media screen and (max-width: 1220px) {
				padding-top: 36px;
			}
		}
		.button {
			display: grid;
			grid-template-columns: 80% 20%;
			height: 48px;
			width: 260px;
			background-color: #FFFFFF;
			box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
			border-left: 4px solid #AF7595;
			margin-top: 58px;
			transition: .3s;
			.text {
				color: black;
				height: 30px;
				width: 161px;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 1.5px;
				line-height: 48px;
				padding-left: 32px;
			}
			.arrow {
				position: relative;
				top: 50%;
				transition: .3s;
				transform: translate3d(0px, -50%, 0px);
			}
			&:hover {
				transform: scale(1.03);
				border-left: 16px solid #AF7595;
				width: 248px;
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
				.arrow {
					transform: translate3d(5px, -50%, 0px);
				}
			}
		}
	}
	.dashes {
		margin-top: 30px;
		&.desktop {
			margin-top: 24px;
			margin-bottom: -44px;
		}
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
	.mobile-link {
		position: relative;
		margin-top: 48px;
		display: grid;
		grid-template-columns: auto auto;
		width: 160px;
		height: 18px;
		left: 100%;
		transform: translate3d(-100%, 0px, 0px);
		.arrow {
			position: relative;
			top: 2px;
			right: 0px;
			height: 14px;
			transform: rotate(-90deg);
		}
		.text {
			width: 96px;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.9px;
			color: #00476e;
		}
	}
}
</style>
