import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vmodal from 'vue-js-modal';
import VueCarousel from 'vue-carousel';
import Moment from 'vue-moment';
import Meta from 'vue-meta';
import vueSmoothScroll from 'vue-smoothscroll';

// Sections
import FAQSection from '@/components/sections/FAQ.vue';
import DrivingTest from '@/components/sections/DrivingTest.vue';
import CarInformation from '@/components/sections/CarInformation.vue';
import DrivingInIceland from '@/components/sections/DrivingInIceland.vue';
import DrivingConditions from '@/components/sections/DrivingConditions.vue';
import InterestingPlaces from '@/components/sections/InterestingPlaces.vue';
import ToursAndActive from '@/components/sections/ToursAndActive.vue';
import BookBanner from '@/components/sections/BookBanner.vue';

// Ads
// - Global
// import Adspace from '@/components/sections/adspaces/Adspace.vue';

// Bluecarrental Banner
import BlueCarRentalBanner from '@/components/BlueCarRentalBanner.vue';

// Component importing
import ListenExpanded from '@/components/ListenExpanded.vue';
import FinesSection from '@/components/FinesSection.vue';
import SignsSection from '@/components/SignsSection.vue';
import ListenFull from '@/components/ListenFull.vue';
import Emergency from '@/components/Emergency.vue';
import TakeTest from '@/components/TakeTest.vue';
import Contact from '@/components/Contact.vue';
import Header from '@/components/Header.vue';
import Crumbs from '@/components/Crumbs.vue';
import Footer from '@/components/Footer.vue';
import Listen from '@/components/Listen.vue';
import Signs from '@/components/Signs.vue';
import Fines from '@/components/Fines.vue';
import FAQ from '@/components/FAQ.vue';
import store from './store';

// Component registering
Vue.component('InterestingPlaces', InterestingPlaces);
Vue.component('DrivingConditions', DrivingConditions);
Vue.component('DrivingInIceland', DrivingInIceland);
Vue.component('ListenExpanded', ListenExpanded);
Vue.component('CarInformation', CarInformation);
Vue.component('ToursAndActive', ToursAndActive);
Vue.component('FinesSection', FinesSection);
Vue.component('SignsSection', SignsSection);
Vue.component('DrivingTest', DrivingTest);
Vue.component('ListenFull', ListenFull);
Vue.component('FAQSection', FAQSection);
Vue.component('BookBanner', BookBanner);
Vue.component('Emergency', Emergency);
Vue.component('TakeTest', TakeTest);
Vue.component('Contact', Contact);
Vue.component('Header', Header);
Vue.component('Crumbs', Crumbs);
Vue.component('Listen', Listen);
Vue.component('Footer', Footer);
Vue.component('Signs', Signs);
Vue.component('Fines', Fines);
Vue.component('FAQ', FAQ);

// Adspace
// - Global
// Vue.component('Adspace', Adspace);

// Bluecarrental Banner
Vue.component('BlueCarRentalBanner', BlueCarRentalBanner);

// bootstrapping
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCarousel);
Vue.use(vueSmoothScroll);
Vue.use(vmodal);
Vue.use(Moment);
Vue.use(Meta);

new Vue({
	router,
	store,
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
	render: (h) => h(App)
}).$mount('#app');
