<template lang='pug'>
.home#anchor
	.content
		Header
		.gutter.constrainer
			Listen
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			//- .section-pad
			//- 	BookBanner(
			//- 		:bannerNumber="1"
			//- 	)
			.section-pad
				DrivingConditions
			//- AD ONE
			//- .space-one.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 1' :id='642')
			//-.space-one.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 100' :id='668')
			.section-pad
				DrivingTest
			.section-pad
				DrivingInIceland
			//- AD TWO
			//- .space-two.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 2' :id='643')
			//-.space-two.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 200' :id='669')
			//.section-pad
			//	BookBanner(
			//		:bannerNumber="5"
			//	)
			.section-pad
				CarInformation
			.section-pad
				InterestingPlaces
			//- AD THREE
			//- .space-three.bottom.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 3' :id='658')
			//-.space-three.bottom.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 300' :id='672')
			//- .section-pad
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image2.jpg') }"
			//- 		text-align="left"
			//- 	)
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			.section-pad
				FAQSection
</template>

<script>
// @ is an alias to /src
export default {
	name: 'Home',
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	metaInfo: {
		title: 'Safe.is',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/home.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Read and listen to the most important safety information before you start driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Read and listen to the most important safety information before you start driving in Iceland.'
			}
		]
	}
};
</script>

<style lang='less' scoped>
#faq-emergency-front-page {
	@media screen and (min-width: 1240px) {
		display: none;
	}
}

#driving-test-front-age {
	@media screen and (max-width: 1239px) {
		display: none;
	}
}

.home {
	min-height: 100vh;
}

.space-one {
	min-height: 1px;

	&.main-mobile {
		display: none;
		margin-top: 48px;
		margin-bottom: -48px;
	}

	&.main-desktop {
		margin-top: 96px;
		margin-bottom: 96px;
	}

	@media screen and (max-width: 768px) {
		&.main-mobile {
			display: block;
		}

		&.main-desktop {
			// display: none;
		}
	}
}

.space-two {
	min-height: 1px;

	&.main-mobile {
		display: none;
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&.main-desktop {
		margin-top: 96px;
		margin-bottom: 96px;
	}

	@media screen and (max-width: 768px) {
		&.main-mobile {
			display: block;
		}

		&.main-desktop {
			// display: none;
		}
	}
}

.space-three {
	min-height: 1px;

	&.main-mobile {
		display: none;
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&.main-desktop {
		margin-top: 96px;
		margin-bottom: 96px;
	}

	@media screen and (max-width: 768px) {
		&.main-mobile {
			display: block;
		}

		&.main-desktop {
			// display: none;
		}
	}
}

.space-four {
	min-height: 1px;

	&.main-mobile {
		display: none;
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&.main-desktop {
		margin-top: 96px;
		margin-bottom: 96px;
	}

	@media screen and (max-width: 768px) {
		&.main-mobile {
			display: block;
		}

		&.main-desktop {
			// display: none;
		}
	}
}</style>
